<template>
  <div class="d-flex">
    <div class="container-lg">
      <div class="header-container">
        <span class="header-span">{{ businessName }}  All Folders</span>
      </div>
      <div
        class="folder-container"
      >
        <WfmListIcons
          :items-list="folderList"
          :get-value="getValue"
          :search-term="searchTerm"
        />
      </div>
    </div>
    <!-- <div>
      <WfmAlphabetSearch
        direction="Vertical"
        @handle-enter="handleEnter"
        @handle-leave="handleLeave"
      />
    </div> -->
  </div>
</template>

<script>
import { computed, ref} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import getFolders from '../../src/composables/getFolders';
// import { toast } from 'vue3-toastify';
import WfmListIcons from '../common/wfm-list-icons.vue';

// import WfmAlphabetSearch from '../common/Wfm-AlphabetSearch.vue'

export default {
  name: 'HomePage',
  components: {
    WfmListIcons,
    // WfmAlphabetSearch
  },
  setup() {
    // Variables
    const router = useRouter();
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const searchTerm = ref('#')
    const { getFoldersList} = getFolders();

    // Computed
    const folderList = computed(() => getFoldersList(bSettings));
    const businessName = computed(() => {
      const bName = bSettings?.swhandle || null;
      if (bName === null) {
        return bName;
      }
      return bName.toUpperCase();
    })

    // Methods
    const getValue = async(folder) => {
      try {
        // store.dispatch("commonModule/formDataSetter", {});
        if (folder.uiFlags.grid_view) {
          //when grid view flag is on we show attendance grid
          folder = bSettings.output.data.records[0].business.containers.folders.folders.attendance_edits;
        }
        if (folder.name === 'duty_charts') {
          router.push({ name: 'dutyCharts' });
        }
        else if (folder.name === 'allocations') {
          router.push({ name: 'Allocation' });
        }
        else if (folder?.uiFlags?.list_view) {
          //when list_view is on we show list view
          router.push({ name: 'tabs', params: { name: folder.name } });
        } else {
          router.push({ name: 'Attendance' });
        }
      } catch (err) {
        // toast.error(err);
        console.log(err);
      }
    };

    function handleSearch(searchKey) {
      searchTerm.value = searchKey
    }
    function handleEnter(searchKey) {
      handleSearch(searchKey)
    }
    function handleLeave(searchKey) {
      handleSearch(searchKey)
    }

    return {
      folderList,
      getValue,
      value: '',
      businessName,
      searchTerm,
      handleEnter,
      handleLeave
    };
  },
};
</script>
<style>
.home-search-input{
  width: 400px !important;
  height: 40px;

}

.home-search-div{
  border:none !important;
  border-bottom: 1px solid var(--main-color) !important;
  box-shadow: none !important;
  width: 400px !important;
}

</style>
<style scoped>
.home-view {
  display: inline-block;
  height: 95vh;
  width: 100%;
  overflow-y: scroll;
  align-items: center;
}

.header-container {
  height: 4rem;
  padding: 1rem;
  margin-top: 1.2rem;
}

.folder-container {
  display: flex;
  margin: auto 1rem;
  margin-top: 1rem;
  overflow-y: scroll;
  height: 80vh;
}
.folder-container:-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.folder-container {
  scrollbar-width: none; /* Firefox 64 and later */
}

.header-span {
  color: var(--main-color);
  font-size: 1.3rem;
  font-weight: 700;
  text-transform: capitalize;
}
</style>
