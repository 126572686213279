function handleDocp(curData, curField, curBaseData, indexes, curBaseDataIdx) {
  curData[curField] = [];
  curBaseData[curField].forEach((curDocpData) => {
    const docpOp = curDocpData.op;
    const docpKey = curDocpData.key;
    curData[curField].push(curDocpData.data.id);
    updateIndexes(indexes, `${curBaseData.id}__${curDocpData.data.id}`, curBaseDataIdx, docpOp, docpKey)
  })
}

function handleDetail(baseFolderConfig, curData, curField, curBaseData, indexes, curBaseDataIdx, nextIdx, txnBag, mainFolderPatches, params) {
  const detailFolderConfig = baseFolderConfig.fields[curField];
  curData[curField] = [];
  curBaseData[curField].forEach((curDetailObj) => {
    const finalDetailData = {};
    const curDetailData = curDetailObj.data;
    const detailFields = Object.keys(curDetailData);
    updateIndexes(indexes, curDetailData.id, curBaseDataIdx, curDetailObj.op, curDetailObj.key)
    for (let k = 0; k < detailFields.length; k++) {
      if (detailFolderConfig.fields[detailFields[k]].type === 'docpicker') {
        handleMainFolder(curDetailData, detailFolderConfig, detailFields[k], nextIdx, indexes, txnBag, mainFolderPatches, params)
        handleDocp(finalDetailData, detailFields[k], curDetailData, indexes, curBaseDataIdx)
      }
      else {
        finalDetailData[detailFields[k]] = curDetailData[detailFields[k]];
      }
    }
    curData[curField].push(finalDetailData);
  })
}

function handleMainFolder(curBaseData, baseFolderConfig, curField, nextDataIdx, indexes, txnBag, mainFolderPatches, params) {
  const source = baseFolderConfig.fields[curField].source;
  const curPath = `${source.container}.${source.foldertype}.${source.folder}`;
  const createWithRefrence = source.create_with_reference;
  if (createWithRefrence === true) {
    const dataArr = curBaseData[curField];
    const finalDataArr = []
    const curfolder = source.folder;
    const curBaseDataIdx = nextDataIdx + (mainFolderPatches[curPath] != null ? mainFolderPatches[curPath].curIdx : 0);
    const newBaseFolderConfig = params[source.container]['containers'][source.foldertype]['folders'][curfolder];
    getPatchForPath(dataArr, curPath,  curBaseDataIdx, indexes, txnBag, newBaseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params)
    if (mainFolderPatches[curPath] != null) {
      mainFolderPatches[curPath].curIdx++
    }
  }
}
function getPatchForPath(dataArr, curPath, curBaseDataIdx, indexes, txnBag, baseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params) {
  const patchDataArr = [];
  const prevIdx = curBaseDataIdx;
  if (!mainFolderPatches.orderOfPaths.includes(curPath)) {
    mainFolderPatches.orderOfPaths.push(curPath);
    indexes.pathIndexes.push(curBaseDataIdx);
  }
  /*Update indexes*/

  for (let i = 0; i < dataArr.length; i++) {
    const curData = {};
    const curBaseData = dataArr[i].data;
    const curBaseOp = dataArr[i].op;
    const curBaseKey = dataArr[i].key;
    curBaseDataIdx = curBaseDataIdx + 1; //For Data
    updateIndexes(indexes, curBaseData.id, curBaseDataIdx, curBaseOp, curBaseKey)
    if (curPath === txnBag.baseFolderPath) {
      updateFatrowPatch(finalDataArr, curfolder, curBaseData, baseFolderConfig.sql.pkey_colname)
    }
    const basefields = Object.keys(curBaseData);
    for (let j = 0; j < basefields.length; j++) {
      const curField = basefields[j];
      let nextIdx = null;
      if (baseFolderConfig.fields[curField].type === 'docpicker') {
        if (mainFolderPatches[curPath] != null) {
          nextIdx =  prevIdx + dataArr.length + 1 + mainFolderPatches[curPath].curIdx;
          mainFolderPatches[curPath].curIdx = mainFolderPatches[curPath].curIdx + 1;
        }
        else {
          nextIdx = prevIdx + dataArr.length + 1;
        }

        handleMainFolder(curBaseData, baseFolderConfig, curField, nextIdx, indexes, txnBag, mainFolderPatches, params)
        handleDocp(curData, curField, curBaseData, indexes, curBaseDataIdx)
      }
      else if (baseFolderConfig.fields[curField].type === 'detail') {
        handleDetail(baseFolderConfig, curData, curField, curBaseData, indexes, curBaseDataIdx, nextIdx, txnBag, mainFolderPatches, params)
      }
      else {
        curData[curField] = curBaseData[curField];
      }
    }
    patchDataArr.push(curData)
  }
  if (mainFolderPatches[curPath] != null) {
    mainFolderPatches[curPath].data.push(...patchDataArr)
  }
  else {
    mainFolderPatches[curPath] = { curIdx: 0, data:patchDataArr };
  }
}

function updateIndexes(indexes, id, idx, op, key) {
  if (op === 'U' && key == null) {
    throw new Error('Please provide key with update operation');
  }
  indexes[id] = op === 'U' ? `${idx}__${op}__${key}` : `${idx}__${op}`;
}

function updateFatrowPatch(finalDataArr, basefolder, curBaseData, pkey) {
  try {
    const fatrowData = {}
    if (curBaseData.id == null) {
      throw Error('Please provide baseFolder id');
    }
    fatrowData[`${basefolder}_${pkey}`] = curBaseData.id;
    finalDataArr.push(fatrowData);
  }
  catch (err) {
    throw err
  }
}

function genPatch(params, txnBag) {
  try {
    const dataParams = txnBag.params;
    if (Object.keys(dataParams).length > 1) {
      throw Error('Can only apply data of one folder at a time');
    }
    let retval = [{}];
    if (Object.keys(dataParams).length > 0) {
      const finalDataArr = [{ _path:'BASEDATA' }]
      const indexes = {};
      const mainFolderPatches = { orderOfPaths:[] };
      let curBaseDataIdx = 0;
      const curPath = txnBag.baseFolderPath;
      const dataArr =  dataParams[curPath]
      const curPathArr = curPath.split('.');
      const container = curPathArr[0];
      const foldertype = curPathArr[1];
      const curfolder = curPathArr[2];
      const baseFolderConfig = params[container]['containers'][foldertype]['folders'][curfolder];
      if (curPath === txnBag.baseFolderPath) {
        curBaseDataIdx = curBaseDataIdx + dataArr.length + 1;
        indexes.dataStart = 1;
        indexes.dataEnd =  dataArr.length + 1;
        indexes.pathIndexes = [] //[curBaseDataIdx];
      }
      getPatchForPath(dataArr, curPath, curBaseDataIdx, indexes, txnBag, baseFolderConfig, finalDataArr, curfolder, mainFolderPatches, params)
      mainFolderPatches.orderOfPaths.forEach((path) => {
        finalDataArr.push({ _path: path });
        finalDataArr.push(...mainFolderPatches[path].data);
      })
      finalDataArr.push(indexes)
      retval = finalDataArr;
    }
    return retval;
  }
  catch (err) {
    throw err
  }
}

function genPatchArr(params, txnArr) {
  try {
    const result = []
    txnArr.forEach((txnBag) => {
      if (txnBag.params != null) {
        txnBag.params = genPatch(params, txnBag)
      }
      result.push(txnBag)
    });
    return result;
  }
  catch (err) {
    throw err
  }
}

function convertDocp(docpId, source) {
  const foldertype = source.foldertype;
  const container = source.container;
  const folder = source.folder;
  return docpId.map((x) => {
    let retval;
    if (x.id != null) {
      retval = { op: 'C', data: { id: x.id } }
      if (x._OP != null && x._OP === 'U') {
        retval.op = 'U'
        retval.key = x.id
      }
      else if (x._OP != null && x._OP === 'D') {
        retval.op = 'D'
        retval.key = x.id
      }
    }
    else if (x.code != null) {
      retval = { op: 'C', data: { id: `getId("${container}.${foldertype}.${folder}.${x.code}")` } }
      if (x._OP != null && x._OP === 'U') {
        retval.op = 'U'
        retval.key = `getId("${container}.${foldertype}.${folder}.${x.code}")`
      }
      else if (x._OP != null && x._OP === 'D') {
        retval.op = 'D'
        retval.key = `getId("${container}.${foldertype}.${folder}.${x.code}")`
      }
    }

    return retval;
  });
}

function convertDocpExpected(docpId) {
  return docpId.map((x) => {
    let retval;
    if (x.code != null) {
      retval = { code : x.code };
    }
    else if (x.id != null) {
      retval = { id: x.id };
    }
    return retval
  });
}

function convertDetail(value, field) {
  return value.map((val) => {
    const obj = { op: 'C', data : {} }
    if (val._OP != null && val._OP === 'U') {
      obj.op = 'U'
      obj.key = val.id
    }
    else if (val._OP != null && val._OP === 'D') {
      obj.op = 'D'
      obj.key = val.id
    }

    const fld = field.fields
    Object.keys(fld).forEach((x) => {
      if (val[x] != null) {

        if (fld[x]['type'] === 'docpicker') {
          if (convertDocp(val[x], fld[x]['source'])[0] != null) {
            obj.data[x] = convertDocp(val[x], fld[x]['source']);
          }
        }
        else if (fld[x]['type'] === 'loctext') {
          obj.data[x] = { eng : val[x] };
        }
        else {
          obj.data[x] = val[x];
        }
      }
    });
    return obj;
  });
}

function convertDetailExpected(value, field) {
  return value.map((val) => {
    const obj = {}
    const fld = field.fields
    Object.keys(fld).forEach((x) => {
      if (val[x] != null) {
        if (fld[x]['type'] === 'docpicker') {
          obj[x] = convertDocpExpected(val[x]);
        }
        else if (fld[x]['type'] === 'loctext') {
          obj[x] = { eng : val[x] };
        }
        else {
          obj[x] = val[x];
        }
      }
    });
    return obj;
  });
}

function getData(data, container, foldertype, folder, swhandle, bsettings, type) {
  const catalog = bsettings;
  const params = { ...catalog }
  params.swhandle = swhandle;
  params.caller = 'fldr'
  const fields = params[container]['containers'][foldertype]['folders'][folder]['fields'];
  const finalObj = {};
  Object.keys(fields).forEach((x) => {

    if (data[x] != null) {
      if (fields[x]['type'] === 'docpicker') {
        if (fields[x]['source']['create_with_reference']) {
          if (data[x][0]['_OP'] != null && data[x][0]['_OP'] === 'U') {
            finalObj[x] = [{ op: 'U', key : data[x][0]['id'], data: getData(data[x][0], fields[x]['source']['container'], fields[x]['source']['foldertype'], fields[x]['source']['folder'], swhandle, bsettings, null) }]
          }
          else if (data[x][0]['_OP'] != null && data[x][0]['_OP'] === 'D') {
            finalObj[x] = [{ op: 'D', key : data[x][0]['id'], data: getData(data[x][0], fields[x]['source']['container'], fields[x]['source']['foldertype'], fields[x]['source']['folder'], swhandle, bsettings, null) }]
          }
          else {
            finalObj[x] = [{ op: 'C', data: getData(data[x][0], fields[x]['source']['container'], fields[x]['source']['foldertype'], fields[x]['source']['folder'], swhandle, bsettings, null) }];
          }
        }
        else {
          if (type == 'expected') {
            finalObj[x] = convertDocpExpected(data[x]);
          }
          else {
            if (convertDocp(data[x], fields[x]['source'])[0] != null) {
              finalObj[x] = convertDocp(data[x], fields[x]['source']);
            }
          }
        }
      }
      else if (fields[x]['type'] === 'detail') {
        finalObj[x] = type === 'expected' ? convertDetailExpected(data[x], fields[x]) : convertDetail(data[x], fields[x]);
      }
      else if (fields[x]['type'] === 'loctext') {
        finalObj[x] = { eng : data[x] };
      }
      else {
        finalObj[x] = data[x];
      }
    }
  });
  return finalObj;
}

function genTxnBag(data, foldertype, folder, container, swhandle, txnName, caller, bsettings) {
  const baseFolderPath = `${container}.${foldertype}.${folder}`;
  const retval = {
    baseFolderPath: baseFolderPath,
    swhandle: swhandle,
    client_id: 'test',
    testType: 'normal',
    template: data.err === 1 ? 'err_body.tmpl' : 'body.tmpl',
    name: txnName,
    expected_behaviour_msg: data.msg + ' Caller : ' + caller,
    params: {
    }
  }
  const patchParams = getData(data, container, foldertype, folder, swhandle, bsettings, null)
  if (data._OP != null && data._OP === 'U') {
    retval.params[baseFolderPath] = [{ 'op' : 'U', key: data.id,  data : patchParams }]
  }
  else if (data._OP != null && data._OP === 'D') {
    retval.params[baseFolderPath] = [{ 'op' : 'D', key: data.id,  data : patchParams }]
  }
  else {
    retval.params[baseFolderPath] = [{ op: 'C', data: patchParams }]
  }
  retval.expected = getData(data, container, foldertype, folder, swhandle, bsettings, 'expected');
  return retval.params[baseFolderPath];
}

function makeTxnFromEditList(store, folderData, folderType, folder, container, swhandle, caller) {
  try {
    const bsettingsStoreData = store.getters['bSettings'];
    const bsettings = bsettingsStoreData.output.data.records[0];
    bsettings.swhandle = swhandle;
    const retVal = {};
    if (Array.isArray(folderData)) {
      retVal.data = genPatchArr(bsettings, folderData);
      retVal.cleanup = null;
    }
    else {
      const patch = folderData.data.map((y) => {
        const x = genTxnBag(y, folderType, folder, container, bsettings.swhandle, folderData.txn_name, caller, bsettings)
        return x
      }).reduce((acc, val) => acc.concat(val), []);
      const baseFolderPath = `${container}.${folderType}.${folder}`;
      const txnBag = {
        baseFolderPath: baseFolderPath,
        swhandle: swhandle,
        client_id: 'test',
        testType: 'normal',
        template: 'body.tmpl',
        name: folderData.txn_name,
        expected_behaviour_msg: 'create record from upload by relman' + ' Caller : ' + caller,
        params: {
        }
      }
      txnBag.params[baseFolderPath] = patch;
      retVal.data = genPatchArr(bsettings, [txnBag]);
    }
    return retVal;
  }
  catch (err) {
    throw err.stack
  }
}

export default makeTxnFromEditList
