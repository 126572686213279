<template>
  <div
    class="d-flex justify-content-between p-2 nav-header"
    :class="{ 'scrolled': scrolled }"
  >
    <div class="d-flex">
      <!-- burger toggle button -->
      <div
        ref="toggleBtn"
        data-test-id="sidebar_toggle"
        @click="toggleSidebar"
      >
        <v-tooltip
          activator="parent"
          location="right"
        >
          {{ !sidebarShow ? "show sidebar" : "hide sidebar" }}
        </v-tooltip>
        <v-icon
          v-if="!sidebarShow"
          name="bi-list"
          scale="2"
          :fill="`var(--main-color)`"
        />
        <v-icon
          v-else
          name="bi-x"
          scale="2"
          :fill="`var(--main-color)`"
        />
      </div>
      <!-- saralweb logo -->
      <img
        class="logo"
        data-test-id="saralweb_logo"
        height="35"
        :src="logo"
        @click="returnHome"
      />
    </div>
    <!-- user image and name -->
    <div class="d-flex">
      <!-- <WfmThemeSwitch /> -->
      <div class="bg-success mx-2 px-2 py-1 rounded font-weight-bold">
        {{ businessName }}
      </div>
      <div
        ref="personIcon"
        class="person-icon d-flex"
        data-test-id="person-icon"
        @click="toggleDiv"
      >
        <span class="my-auto me-2 profile-name">
          {{ userName }}
        </span>
        <div
          v-if="imageCheck"
          class="profile-picture"
          :style="{ 'background-image': `url(${filePathToServeFrom})`}"
        />
        <div
          v-else
        >
          <v-icon
            name="bi-person-circle"
            :fill="`var(--main-color)`"
            scale="2"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- profile menu dropdown -->
  <div
    v-show="showProfileDropdown"
    ref="profileInfo"
    class="nav-dropdown me-2"
  >
    <ProfileMenu @close-menu="showProfileDropdown=false" />
  </div>
  <WfmSidebar
    v-if="isLoggedIn && sidebarShow"
    ref="sidebar"
    @hide-sidebar="hideSidebar"
  />
</template>

<script>
import { useStore } from 'vuex'
import logo from '@/assets/images/logo.png'
import { ref, computed, onMounted, onUnmounted} from 'vue'
import { useRouter } from 'vue-router'
//import WfmLanguageSwitch from "../navbar/wfm-language-switch.vue"
import WfmSidebar from '../sidebar/wfm-sidebar.vue'
import getUtility from '../../../composables/getUtility.js'
//import WfmThemeSwitch from "../navbar/wfm-themeSwitch.vue"
import ProfileMenu from './profile-menu.vue'

export default {
  name: 'WfmNavbar',
  components: {
    //WfmLanguageSwitch,
    WfmSidebar,
    ProfileMenu
  },
  props: {
    actions: {
      type: Object,
      default: null
    }
  },
  setup() {
    //variables
    const store = useStore()
    const router = useRouter()
    const showProfileDropdown = ref(false)
    const profileInfo = ref()
    const personIcon = ref()
    const toggleBtn = ref()
    const sidebar = ref()
    const sidebarShow = ref(false)
    const scrolled = ref(false)

    //computed
    const imageCheck = computed(() => {
      const photo = localStorage.getItem('profilePic')
      if (photo !== 'null' && photo) {
        return true
      }
      else {
        return false
      }
    })

    const businessName = computed(() => {
      const bSettings = store.getters['bSettings'];
      return bSettings?.env?.business_name || bSettings?.swhandle
    })

    const isLoggedIn = computed(() => {
      return store.getters['userModule/isLoggedIn']
    })
    const userName = computed(() => {
      const nameToShow = localStorage.getItem('employeeName') ? localStorage.getItem('employeeName') : (store.getters['userModule/username'])
      return nameToShow
    })

    const baseUrl = ref(`https://${window.location.hostname}:8190/file_uploads`)
    const filePathToServeFrom = computed(() => {
      const filePath = localStorage.getItem('profilePic')
      let url = null
      if (filePath) {
        const index = filePath?.indexOf('/file_uploads');
        const subPathToUse = filePath?.substring(index + '/file_uploads'.length);
        url = baseUrl.value + subPathToUse
      }
      return url
    })
    //Composable
    const { registerClickOutside } = getUtility()
    //allow outside click for sidebar and profile info dropdown.
    registerClickOutside(profileInfo, () => { showProfileDropdown.value = false }, [personIcon])
    registerClickOutside(sidebar, () => { sidebarShow.value = false }, [toggleBtn])

    //Methods
    function toggleDiv() {
      showProfileDropdown.value = !showProfileDropdown.value
    }

    function toggleSidebar() {
      sidebarShow.value = !sidebarShow.value
    }
    function returnHome() {
      router.push('/')
    }
    function hideSidebar() {
      sidebarShow.value = false
    }

    const handleScroll = () => {
      scrolled.value = window.scrollY > 0;
    };
    // Listen to scroll events on mount
    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    // Remove scroll event listener on component unmount
    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      toggleSidebar,
      sidebarShow,
      logo,
      returnHome,
      toggleDiv,
      userName,
      imageCheck,
      showProfileDropdown,
      isLoggedIn,
      hideSidebar,
      profileInfo, //ref
      personIcon, //ref
      toggleBtn, //ref
      sidebar, //ref
      filePathToServeFrom,
      scrolled,
      businessName
    }
  }
}
</script>

<style scoped>
.profile-info {
  color: var(--main-color);
  font-size: 1rem;
  text-transform: capitalize;
}

.profile-picture {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border:1px solid var(--main-color);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.profile-name {
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: 600;
}

.d-flex {
  align-items: center;
}

.logo {
  margin-top: 2px;
  cursor: pointer;
  height: 3vh;
}

.sidebar-toggle-btn {
  background-color: var(--main-color) !important;
}

.nav-header {
  cursor: pointer;
  background-color: var(--nav-header-color);
  border-bottom: 1px solid rgba(100, 100, 111, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 5vh;
}

.nav-dropdown {
  cursor: pointer;
  width: fit-content;
  right: 0;
  text-align: left;
  margin-left: auto;
  background-color: var(--main-background-color);
  border: 1px solid white;
  position: fixed;
  padding: 20px;
  font-size: 1.1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
  border-top: 0px;
  z-index: 5;
}

.navbar {
  width: 100%;
  background: white;
  height: auto;
  -moz-box-shadow: -3px 0 50px 0 #555;
  -webkit-box-shadow: -3px 0 50px 0 #555;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
}

.options {
  border-bottom: 1px solid var(--main-color);
  border-radius: 10px;
  color: var(--main-color);
  border-left: 0px;
  border-right: 0px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
  font-size: 1rem;
}

.options:hover {
  color: #e67e22;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid var(--main-color);
  padding: 6px 0;
  border-bottom: 1px solid var(--main-color);
  border-radius: 10px;
  border-left: 0px;
  border-right: 0px;
  padding: 10px;
  margin-top: 3px;
  width: 100%;
}
.scrolled{
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12),0 2px 4px -1px rgba(0,0,0,.2);
}
.user {
  color: white;
}
</style>
