import store from '../../store/index';
import getProjections from './getProjections';
import getFolders from './getFolders';
import getFatRow from './fatRowGenerator';
import postData from './postData.js';
const usePartnersId = () => {
  async function getPartnersList() {
    //getting partners list if not in the store
    //to be used to assign swhandle to row Data
    const {projectionForFolder} = getProjections()
    const { getFolderByName} = getFolders();
    const { fatRowGenerator } = getFatRow()
    const bSettings = store.getters['bSettings'];
    const txnParams = {refRows:true}
    const partnersFolder = getFolderByName('partners', bSettings)
    const { listData } = postData()
    txnParams.projections = projectionForFolder('shared_partners_list')
    const ld = await listData(partnersFolder, txnParams)
    const partnersList = fatRowGenerator(ld)

    await store.dispatch('commonModule/partnersListSetter', partnersList);
    return partnersList

  }
  function getCurrentSelectedPartnersId() {
    const plist = store.getters['commonModule/partnersListGetter'];
    const swHandle = store.getters['userModule/selectedPartner']
    const currentPartner = plist.filter((each) => each.code == swHandle)
    return currentPartner?.[0]?.id
  }
  function getPePartnersId() {
    const plist = store.getters['commonModule/partnersListGetter'];
    const peToUse = plist.filter((each) => each.partner_groups?.[0]?.code == 'pe')
    return peToUse?.[0]?.id
  }
  return {getPePartnersId, getCurrentSelectedPartnersId, getPartnersList}
}

export default usePartnersId
