<template>
  <div style="height:10px">
    <WfmButton
      v-for="(txn, index) in availableTxns"
      :key="index"
      button-class="update-btn"
      @button-click="handleClick(txn)"
    >
      {{ txn.label.eng.split(' ')[0] }}
    </WfmButton>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import WfmButton from '../../common/wfm-button.vue';
import getFolders from '../../composables/getFolders';
import getProj from '../../composables/getProjections';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
import { sendMessage } from '../../services/websocket';
import getFatRow from '../../composables/fatRowGenerator.js';
export default {
  name: 'WfmDetailRowActions',
  components: {
    WfmButton,
  },
  props: {
  },
  setup(props) {
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const store = useStore();
    const bSettings = ref(store.getters['bSettings']);
    const folderList = getAllFoldersList(bSettings.value);
    const folder = getCurrentFolder('invoices', folderList);
    const sessionId = store.getters['sessionIdGetter'];
    const statusMap = { upload: 'uploaded', approve: 'approved',  reject: 'rejected' };
    const { fatRowGenerator } = getFatRow();
    const { projectionForFolder } = getProj();

    const baseRowNode = computed(() => {
      //const baseRowNode = props.params.baseGridParams.baseGridApi.getRowNode(baseRow.value.id); //this does not work
      let baseRowNode = null;
      props.params.baseGridParams.baseGridApi.forEachNode((node) => {
        if (baseRowNode == null && node.data.id == props.params.baseGridParams.data.id) {
          baseRowNode = node;
        }
      });
      return baseRowNode;
    });

    const availableTxns = computed(() => {
      const detailRow = props.params.data;
      const baseRow = baseRowNode.value.data;
      const currDocCode = detailRow.doc_name[0].code;
      const txns = JSON.parse(JSON.stringify(Object.keys(folder.txns).filter((txnKey) => txnKey.indexOf(currDocCode) > -1).map((txnKey) => folder.txns[txnKey])));
      return txns.filter((txn) => {
        const res = jsEvaluate(
          txn.permissions.rowPermissions,
          {
            userEnv: bSettings.value.env,
            partnerEnv: { groups: bSettings.value.env.partner_groups || [] },
            baseRow: baseRow,
            detailRowId: detailRow.id
          }
        );
        return res;
      });
    });

    function getPatch(newStatusId, attachment, statusCode) {
      const detailRow = props.params.data;
      const detailRowId = detailRow.id;
      const baseRowId = baseRowNode.value.data.id;
      const oldStatusId = props.params.data.status[0].id;
      const ans = [
        {
          _path: 'BASEDATA'
        },
        {
          invoices_id: baseRowId
        },
        {
          _path: 'business.mviews.invoices'
        },
        {
          id: baseRowId,
          approval_documents: [
            {
              id: detailRowId,
              invoices_id: baseRowId,
              status: [newStatusId],
            }
          ]
        },
        {
          dataStart: 1,
          dataEnd: 2,
          pathIndexes: [
            2
          ],
          [baseRowId] : '3__U__' + baseRowId,
          [detailRowId]: '3__U__' + detailRowId,
          [detailRowId + '__' + newStatusId]: '3__U__' + oldStatusId
        }
      ];

      if (attachment.length > 0) {
        ans[3].approval_documents[0].attachment = attachment;
        ans[4][detailRowId + '__' + attachment[0]] = '3__C';
      }
      else if (statusCode == 'rejected') {
        ans[3].approval_documents[0].attachment = [detailRow.attachment[0].id];
        ans[4][detailRowId + '__' + detailRow.attachment[0].id] = '3__D';
      }
      return ans;
    }

    function jsEvaluate(expr, scope) {
      try {
        const fnBody = `
          try {
            return (() => { ${expr} })();
          } catch(err) {
            throw new Error('error in expr evaluation ' + err.message);
          }
        `;
        const result = new Function('scope', fnBody)(scope);
        return result;
      } catch (err) {
        console.log(err);
      }
    }

    async function handleClick(txnBag) {
      try {
        const txnToRun = props.params.baseGridParams.getTxnToRun();
        const detailRowId = props.params.data.id;
        const editedDetailRow = txnToRun.params[3].approval_documents?.find((ad) => ad.id == detailRowId);
        const docAttachment = editedDetailRow != null ? editedDetailRow.attachment : [];
        const newStatusCode = statusMap[Object.keys(statusMap).filter((k) => txnBag.name.indexOf(k) > -1)[0]];
        if (newStatusCode == 'uploaded' && (docAttachment == null || docAttachment.length == 0)) {
          toast.error('Please select file.', {
            position: 'top-center',
            autoClose: 1000
          });
          return;
        }
        const invoice_docs_statuses = bSettings.value.output.data.records[0].masterData.invoice_docs_statuses;
        const statusObj = JSON.parse(JSON.stringify(invoice_docs_statuses.find((ids) => ids.partners_id == baseRowNode.value?.data.partners_id && ids.code == newStatusCode)));
        txnBag.params = getPatch(statusObj.id, docAttachment, newStatusCode);
        txnBag.projections = projectionForFolder('invoices');
        txnBag.session_key = sessionId;
        txnBag.target_swhandle = txnToRun.target_swhandle;
        const res = await sendMessage(txnBag);
        if (res.output.type === 'error') {
          toast.error(`An error occurred: ${res.output.message}`, {
            position: 'top-center',
            autoClose: 10000
          });
          console.log(`getting error while executing txn ${txnBag.name}`, res.output.message, res);
        }
        else {
          res.output.data.records = res.output.data.records.map((r) => JSON.parse(r));
          const fatBaseRow = fatRowGenerator(res)[0];

          //updating base row data in base grid
          baseRowNode.value.setData(fatBaseRow);
          //props.params.baseGridParams.refreshTabData(fatBaseRow);

          //updating detail row data in detail grid
          props.params.api.setRowData(fatBaseRow.approval_documents);
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    return {
      handleClick,
      availableTxns
    };
  }
};
</script>

<style scoped>
/* Your component styles go here */
</style>
