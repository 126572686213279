<template>
  <div class="main">
    <div class="ag-grid-container">
      <div class="controls">
        <!-- filters -->
        <div class="filter-container">
          <!-- Week Selector -->
          <div class="week-selector">
            <WfmDateSwitcher
              :is-week-picker="true"
              @update-month-year="updateMonthYear"
            />
          </div>

          <div> Employee </div>
          <v-select
            v-model="selectedEmp"
            class="vs"
            :options="empOptions"
            placeholder="Select Employee"
            label="name"
            @input="searchEmp"
            @update:model-value="applyFilter('emp')"
          />

          <div> Department </div>
          <v-select
            v-model="selectedDep"
            class="vs"
            :options="depOptions"
            placeholder="Select Department"
            label="name"
            @update:model-value="applyFilter('dep')"
          />

          <div> Subsection </div>
          <v-select
            v-model="selectedSubs"
            class="vs"
            :options="subsOptions"
            placeholder="Select Subsection"
            label="name"
            @update:model-value="applyFilter('subs')"
          />
        </div>

        <div class="buttons">
          <!-- save button -->
          <button
            v-if="gridReady"
            class="save-button"
            @click="saveData()"
          >
            Save
          </button>
          <!-- Add Row Button -->
          <button
            v-if="gridReady"
            class="add-row-button"
            @click="addRow"
          >
            +
          </button>
        </div>
      </div>

      <!-- AG-Grid Table -->
      <div class="ag-grid-wrapper">
        <ag-grid-vue
          class="ag-theme-alpine"
          style="width: 100%; height: 100%;"
          :column-defs="columnDefs"
          :row-data="rowData"
          :pinned-bottom-row-data="pinnedRowData"
          :default-col-def="defaultColDef"
          :row-class-rules="rowClassRules"
          :get-row-node-id="getRowNodeId"
          :get-row-height="getRowHeight"
          :get-row-style="getRowStyle"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
    <div class="summary">
      <div class="wocm">
        Monthly Weekly Off Count
      </div>
      <div
        v-for="x in summary"
        :key="x.id"
        class="emp-list"
      >
        {{ x.name }} ({{ x.code }}) : {{ x.count }}
      </div>
    </div>
  </div>
</template>

<script>
// Importing AG-Grid Vue component
import { AgGridVue } from 'ag-grid-vue3';
import { ref } from 'vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import customEditor from './customCellEditor_4.vue';
import customRenderer from './customCellRenderer.vue';
import prog_customEditor from './prog_customCellEditor_4.vue';
import prog_customRenderer from './prog_customCellRenderer.vue';
import { useStore } from 'vuex';
// import makeFilter from '../composables/makeFilter';
import postData from '../composables/postData';
import getFatRow from '../composables/fatRowGenerator.js';
import getFolders from '../composables/getFolders';
import { sendMessage } from '../services/websocket';
import processFormData from '../composables/processFormData';
import makeTxn from '../composables/makeTxn';
import attendanceGridUtils from '../composables/attendanceGridUtils';
import WfmDateSwitcher from '../common/wfm-dateSwitcher.vue';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'vue3-toastify';

export default {
  components: {
    // Registering AG-Grid component
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    customEditor,
    customRenderer,
    /* eslint-enable */
    WfmDateSwitcher
  },
  setup() {
    const store = useStore();
    const bSettings = ref(store.getters['bSettings']);
    const summary = ref([]);
    const empOptions = ref([]);
    const depOptions = ref(JSON.parse(JSON.stringify(bSettings?.value?.output?.data?.records?.[0]?.folderData?.departments !== undefined ? bSettings?.value?.output?.data?.records?.[0]?.folderData?.departments : [])));
    const subsOptions = ref(JSON.parse(JSON.stringify(bSettings?.value?.output?.data?.records?.[0]?.folderData?.subsections !== undefined ? bSettings?.value?.output?.data?.records?.[0]?.folderData?.subsections : [])));
    const selectedEmp = ref(null);
    const selectedDep = ref(null);
    const selectedSubs = ref(null);

    const { getAllFoldersList, getCurrentFolder } = getFolders();
    // const baseRows = store.state.dutyCharts;
    const gridApi = ref(null); // Declare gridApi
    const gridColumnApi = ref(null); // Declare gridColumnApi
    const selectedRowIndex = ref(null);

    const rowData = ref([]);
    const gridReady = ref(false);

    //week selector initial setup
    const currentDateTime = new Date();
    const current = new Date(currentDateTime);
    const dayOfWeek = current.getDay();
    const startOfWeek = new Date(current);
    startOfWeek.setDate(current.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    const timeRange = ref({
      weekRange: {
        startOfWeek: startOfWeek.getDate(),
        startMonth: startOfWeek.getMonth(),
        startYear: startOfWeek.getFullYear(),
        endOfWeek: endOfWeek.getDate(),
        endMonth: endOfWeek.getMonth(),
        endYear: endOfWeek.getFullYear()
      },
      day: null,
      year: currentDateTime.getFullYear(),
      month: currentDateTime.getMonth() + 1
    });

    const pinnedRowData = [{ id: 'weekly_off_row', program: [{ name: 'Weekly Off', code: '9999999999998' }] },
      { id: 'leaves_row', program: [{ name: 'Leaves',     code: '9999999999999' }] }];

    async function updateMonthYear(newMonthYear) {
      timeRange.value = newMonthYear;
      await getSummary();
      if (gridReady.value == true) {
        updateColumnDefs();
        filterUnscheduledRows();
        setTimeout(() => {
          if (gridApi.value != null) {
            gridApi.value.resetRowHeights();
          }
        }, 0);
        setTimeout(() => {
          if (gridApi.value != null) {
            const oldRows = store.state.dutyChartPinnedBottomRows;
            gridApi.value.setPinnedBottomRowData(JSON.parse(JSON.stringify(oldRows)));
          }
        }, 0);
      }
    }

    function convertToISTDate(dateString) {
      // Define an object for month name mapping
      const months = {
        jan: 0, feb: 1, mar: 2, apr: 3, may: 4, jun: 5,
        jul: 6, aug: 7, sep: 8, oct: 9, nov: 10, dec: 11
      };
      // Split the string by underscores
      // eslint-disable-next-line
      const [_, day, month, year] = dateString.toLowerCase().split('_');

      // Convert day and year to integers
      const dayOfMonth = parseInt(day, 10);
      const monthIndex = months[month];
      const yearNumber = parseInt(year, 10);

      // Create a Date object in UTC
      const istDate  = new Date(yearNumber, monthIndex, dayOfMonth);
      return istDate;
    }

    function filterUnscheduledRows() {
      const dates = getDateRange();
      const oldRows = store.state.dutyCharts;
      const filteredRows = oldRows.filter((old) => {
        if (old.new_row == null && old.program != null && old.program[0].scheduled == false) {
          const keys = Object.keys(old);
          if (keys.findIndex((k) => old[k].employees != null && old[k].employees.length > 0 && dates.indexOf(k) > -1) > -1) {
            return true;
          }
          else {
            return false;
          }
        }
        else if (old.program != null && old.program[0].deactivation_date != null && dates.filter((dts) => convertToISTDate(dts).getTime() < convertToISTDate(convertUTCToIST(old.program[0].deactivation_date)).getTime()).length == 0) {
          return false;
        }
        else {
          return true;
        }
      });
      rowData.value = JSON.parse(JSON.stringify(filteredRows));
    }

    function getDateRange() {
      const { formatDays } = attendanceGridUtils();
      const day = timeRange.value.day;
      const month = timeRange.value.month;
      const year = timeRange.value.year;
      const weekRange = timeRange.value.weekRange;
      const weekDays = formatDays(day, month, year, weekRange);
      return weekDays.map((wd) => `${wd.formattedDay}_${wd.month}_${wd.year}`.split(' ').join('_').toLowerCase());
    }

    function getWeekDaysColumns() {
      const { formatDays } = attendanceGridUtils();
      const day = timeRange.value.day;
      const month = timeRange.value.month;
      const year = timeRange.value.year;
      const weekRange = timeRange.value.weekRange;
      const weekDays = formatDays(day, month, year, weekRange);
      const weekDaysColumns = weekDays.map((wd, i) => ({
        headerName: `${wd.formattedDay} ${wd.month}`,
        field: `${wd.formattedDay}_${wd.month}_${wd.year}`.split(' ').join('_').toLowerCase(),
        cellEditor: customEditor,
        cellRenderer: customRenderer,
        editable: (params) => {
          if (bSettings.value.env.code == 'admin' || (bSettings.value.env.isDCO != null && bSettings.value.env.isDCO == true)) {
            if (params.node.rowPinned && params.data.id == 'leaves_row') {
              return false;
            }
            else if (params.data.program != null && params.data.program[0].deactivation_date != null && convertToISTDate(params.colDef.field).getTime() > convertToISTDate(convertUTCToIST(params.data.program[0].deactivation_date)).getTime()) {
              return false;
            }
            else {
              return true;
            }
          }
          else {
            return false;
          }
        },
        wrapText: true
      }));
      return weekDaysColumns;
    }

    // Column definitions
    const columnDefs = ref([
      {
        headerName: 'Programs',
        field: 'program',
        editable: (params) => {
          if (bSettings.value.env.code == 'admin' || (bSettings.value.env.isDCO != null && bSettings.value.env.isDCO == true)) {
            if (params.node.rowPinned) {
              return false;
            }
            return true;
          }
          else {
            return false;
          }
        },
        cellEditor: prog_customEditor,
        cellRenderer: prog_customRenderer,
        valueFormatter: (params) => {
          return params.value;
        }
      },
      ...getWeekDaysColumns()
    ]);

    const defaultColDef = ref({
      resizable: true,
      sortable: true,
      flex: 1,
      wrapText: true
    });

    const updateColumnDefs = () => {
      columnDefs.value = [
        {
          headerName: 'Programs',
          field: 'program',
          editable: (params) => {
            if (bSettings.value.env.code == 'admin' || (bSettings.value.env.isDCO != null && bSettings.value.env.isDCO == true)) {
              if (params.node.rowPinned) {
                return false;
              }
              return true;
            }
            else {
              return false;
            }
          },
          cellEditor: prog_customEditor,
          cellRenderer: prog_customRenderer,
          valueFormatter: (params) => {
            return params.value;
          }
        },
        ...getWeekDaysColumns()
      ];
    };

    const rowClassRules =  ref({
      // Apply the class 'highlighted-row' to the selected row
      'highlighted-row': (params) => {
        if (!params.node.rowPinned) {
          params.node.setSelected(params.node.rowIndex === selectedRowIndex.value);
        }
      },
    });

    async function getDocpickerList(filterParams) {
      try {
        if (Object.keys(filterParams).length !== 0) {
          const folderList = getAllFoldersList(bSettings.value);
          const currentDocpFolder = getCurrentFolder('employees', folderList);
          const { listData } = postData();
          const txnParams = { refRows: true };
          txnParams.filters = filterParams;
          txnParams.projections = {
            'employees': ['id', 'code', 'persons_id', 'designation', 'departments'],
            'employees.departments':['id'],
            'employees.designation':['id'],
            'employees.persons_id':['id', 'first_name', 'last_name', 'middle_name']
          }
          const list = await listData(currentDocpFolder, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          }
          else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              const fatRows = fatRowGenerator(list);
              empOptions.value = fatRows.map((item) => ({ id: item.id, name: item['persons_id'][0]['first_name']['eng'], code: item.code }));
            }
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    async function searchEmp(event) {
      try {
        const searchTerm = event.target.value;
        if (searchTerm.length >= 2) {
          const filterParams = { filterInput: [], joinop: 'and' };
          if (bSettings.value.env.code !== 'admin') {
            filterParams.filterInput.push({
              operator: '=',
              path: 'employees.subsections.duty_chart_officer.id',
              value: bSettings.value.env.id,
            });
          }
          await getDocpickerList(filterParams);
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    function makeDutyChartFormData(cellData, tasks) {
      const formData = {
        date: {
          path: 'duty_charts.date',
          value: cellData.date,
          folderId: null,
          fieldType: 'timeStamp'
        },
        tasks_id: {
          path: 'duty_charts.tasks_id',
          value: [
            {
              id: tasks[0].id,
              isMultipleDocp:false,
              docpType: true,
              detailType: false,
              docpNotSet: true
            },
          ],
          folderId: null,
          mandatory: 'true',
          type: 'docpicker',
          fieldType: 'docpicker',
          src: null
        },
        employees_id: {
          path: 'duty_charts.employees_id',
          value: cellData.employees.map((emp) => ({
            id: emp.id,
            toAdd: true,
            toRemove: false,
            isMultipleDocp: true,
            docpType: true,
            detailType: false
          })),
          folderId: null,
          mandatory: 'true',
          type: 'docpicker',
          fieldType: 'docpicker',
          src: null
        }
      }
      return formData;
    }

    function makeDutyChartFormDataForUpdate(cellData, tasks) {
      const formData = {
        employees_id: {
          path: 'duty_charts.employees_id',
          value: cellData.employees.filter((e) =>  e.toAdd == true || e.toRemove == true).map((emp) => ({
            id: emp.id,
            toAdd: emp.toAdd || false,
            toRemove: emp.toRemove || false,
            isMultipleDocp: true,
            docpType: true,
            detailType: false
          })),
          folderId: cellData.duty_chart_id,
          mandatory: 'true',
          type: 'docpicker',
          fieldType: 'docpicker',
          src: cellData.duty_chart_id
        }
      }
      return formData;
    }

    async function saveDutyChartsData() {
      try {
        const folderList = getAllFoldersList(bSettings.value);
        const currentFolder = getCurrentFolder('duty_charts', folderList);
        const dataToSave = store.state.dutyCharts;
        const sessionId = store.getters['sessionIdGetter'];
        for (let i = 0; i < dataToSave.length; i++) {
          const cellDataKeys = Object.keys(dataToSave[i]);
          for (let j = 0; j < cellDataKeys.length; j++) {
            if (['id', 'program'].indexOf(cellDataKeys[j]) < 0 && dataToSave[i][cellDataKeys[j]].employees?.length > 0 && dataToSave[i][cellDataKeys[j]].changed != null && dataToSave[i][cellDataKeys[j]].changed) {
              //transform the raw data into form data
              let formData = {};
              let txn = null;
              if (dataToSave[i][cellDataKeys[j]].op == 'create') {
                txn = currentFolder.txns.txn_duty_charts_create;
                formData = makeDutyChartFormData(dataToSave[i][cellDataKeys[j]], dataToSave[i].program);
              }
              else if (dataToSave[i][cellDataKeys[j]].op == 'update') {
                txn = currentFolder.txns.txn_duty_charts_update;
                formData = makeDutyChartFormDataForUpdate(dataToSave[i][cellDataKeys[j]], dataToSave[i].program);
              }
              else {
                return [];
              }
              const { getGroupedFormData } = processFormData();
              const {
                normalFieldList,
                docpList,
                detailList,
                detailKeyFieldName,
              } = getGroupedFormData(formData, currentFolder, undefined);

              const params = {
                currentTaskName: dataToSave[i][cellDataKeys[j]].op,
                normalFieldList: normalFieldList,
                docpList: docpList,
                detailList: detailList,
                folderDetails: currentFolder,
                detailKeyName: detailKeyFieldName,
                txnType: undefined,
                currentFolderId: dataToSave[i][cellDataKeys[j]].duty_chart_id,
                txn: txn,
              };
              const { generateTxn } = makeTxn(params);

              //generate final txnBag
              const txnToRun = generateTxn();
              txnToRun.session_key = sessionId;
              const res = await sendMessage(txnToRun);
              if (res.output.type === 'error') {
                toast.error(`An error occurred: ${res.output.message}`, {
                  position: 'top-center',
                  autoClose: 10000
                });
                console.log(res.output.message);
                dataToSave[i][cellDataKeys[j]].employees.forEach((e) => {
                  e.toAdd = null;
                  e.toRemove = null;
                });
              }
              else if (res.output?.data?.records?.length > 0) {
                if (dataToSave[i][cellDataKeys[j]].op == 'create') {
                  const resParsed = JSON.parse(res.output.data.records[1]);
                  dataToSave[i][cellDataKeys[j]].duty_chart_id = resParsed.duty_charts_id;
                }
                dataToSave[i][cellDataKeys[j]].changed = false;
                dataToSave[i][cellDataKeys[j]].employees = dataToSave[i][cellDataKeys[j]].employees.filter((e) => e.toRemove == null);
                dataToSave[i][cellDataKeys[j]].employees.forEach((e) => {
                  e.toAdd = null;
                });
                store.commit('writeDutyChartsData', dataToSave);
              }
            }
          }
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    function makeLeavesFormData(data, op) {
      let formData = {};
      if (op == 'create') {
        formData = {
          employees_id: {
            path: 'leaves.employees_id',
            value: [{
              id: data.employeeId,
              isMultipleDocp: false,
              docpType: true,
              docpNotSet: true,
              detailType: false
            }],
            folderId: null,
            mandatory: 'true',
            type: 'docpicker',
            fieldType: 'docpicker',
            src: null
          },
          leave_days: [{
            id: uuidv4(),
            newRowAdded: true,
            leave_period: data.leavePeriod,
            leave_minutes: 480,
            leave_status: [{
              id: data.leaveStatusId,
              docpNotSet: true,
              docpType: true,
              detailType: false,
              isMultipleDocp: false
            }],
            leave_types_id: [
              {
                id: data.leaveTypeId,
                docpNotSet: true,
                docpType: true,
                detailType: false,
                isMultipleDocp: false
              }],
          }
          ],
        }
        formData.leave_days['pathofDetail'] = 'leaves.leave_days';
        formData.leave_days['primaryKeyField'] = 'leaves_id';
        formData.leave_days['visible'] =  true;
      }
      else if (op == 'delete') {
        formData = {
          id: {
            path: 'leaves.id',
            value: data.leave_id,
            folderId: data.leave_id,
            fieldType: 'uuid'
          },
        }
      }
      return formData;
    }

    async function saveLeavesData() {
      try {
        const folderList = getAllFoldersList(bSettings.value);
        const currentFolder = getCurrentFolder('leaves', folderList);
        const dataToSave = store.state.dutyChartPinnedBottomRows;
        const sessionId = store.getters['sessionIdGetter'];
        for (let i = 0; i < dataToSave.length; i++) {
          const cellDataKeys = Object.keys(dataToSave[i]);
          for (let j = 0; j < cellDataKeys.length; j++) {
            if (['id', 'program'].indexOf(cellDataKeys[j]) < 0) {
              for (let k = 0; k < dataToSave[i][cellDataKeys[j]].employees.length; k++) {
                if (dataToSave[i][cellDataKeys[j]].employees[k].changed != null && dataToSave[i][cellDataKeys[j]].employees[k].changed) {
                  //transform the raw data into form data
                  const data = {};
                  if (dataToSave[i][cellDataKeys[j]].employees[k].toRemove == true) {
                    const leave_id = dataToSave[i][cellDataKeys[j]].employees[k].leave_id;
                    const leave_day_id = dataToSave[i][cellDataKeys[j]].employees[k].leave_day_id;
                    const txn = currentFolder.txns.txn_leaves_delete;
                    txn.params = [
                      {
                        _path: 'BASEDATA'
                      },
                      {
                        leaves_id: leave_id
                      },
                      {
                        _path: 'business.folders.leaves'
                      },
                      {
                        id: leave_id,
                        leave_days: [{
                          id: leave_day_id,
                          leaves_id: leave_id
                        }]
                      },
                      {
                        dataStart: 1,
                        dataEnd: 2,
                        pathIndexes: [2]
                      }
                    ];
                    txn.params[txn.params.length - 1][leave_id] = '3__U__' + leave_id;
                    txn.params[txn.params.length - 1][leave_day_id] = '3__D';
                    txn.session_key = sessionId;
                    const res = await sendMessage(txn);
                    if (res.output.type === 'error') {
                      toast.error(`An error occurred: ${res.output.message}`, {
                        position: 'top-center',
                        autoClose: 10000
                      });
                      console.log(res.output.message);
                    }
                    else {
                      const svIdx = summary.value.findIndex((sv) => sv.code == dataToSave[i][cellDataKeys[j]].employees[k].code);
                      if (svIdx > -1) {
                        if (summary.value[svIdx].count > 1) {
                          summary.value[svIdx].count = summary.value[svIdx].count - 1;
                          summary.value = summary.value.sort((a, b) => b.count - a.count);
                        }
                        else {
                          summary.value = summary.value.filter((sv) => sv.code != dataToSave[i][cellDataKeys[j]].employees[k].code);
                        }
                      }
                    }
                  }
                  else {
                    const txn = currentFolder.txns.txn_leaves_create;
                    data.employeeId = dataToSave[i][cellDataKeys[j]].employees[k].id;
                    data.leaveTypeId = store.state.bSettings.output.data.records[0].folderData.leaveTypes.find((lt) => lt.code == 'WO').id;
                    data.leaveStatusId = store.state.bSettings.output.data.records[0].masterData.leave_statuses.find((lt) => lt.code == 'created').id;
                    const startDate = dataToSave[i][cellDataKeys[j]].date;
                    const endDate = new Date(startDate);
                    endDate.setDate(endDate.getDate() + 1);
                    data.leavePeriod = '["' + startDate + '", "' + endDate.toISOString() + '")';
                    const formData = makeLeavesFormData(data, 'create');
                    const { getGroupedFormData } = processFormData();
                    const {
                      normalFieldList,
                      docpList,
                      detailList,
                      detailKeyFieldName,
                    } = getGroupedFormData(formData, currentFolder, undefined);

                    const params = {
                      currentTaskName: 'create',
                      normalFieldList: normalFieldList,
                      docpList: docpList,
                      detailList: detailList,
                      folderDetails: currentFolder,
                      detailKeyName: detailKeyFieldName,
                      txnType: undefined,
                      currentFolderId: undefined,
                      txn: txn,
                    };
                    const { generateTxn } = makeTxn(params);

                    //generate final txnBag
                    const txnToRun = generateTxn();
                    txnToRun.session_key = sessionId;
                    const res = await sendMessage(txnToRun);
                    if (res.output.type === 'error') {
                      toast.error(`An error occurred: ${res.output.message}`, {
                        position: 'top-center',
                        autoClose: 10000
                      });
                      console.log(res.output.message);
                      dataToSave[i][cellDataKeys[j]].employees[k].invalid = true;
                    }
                    else {
                      if (res.output?.data?.records?.length > 0) {
                        const resParsed = JSON.parse(res.output.data.records[3]);
                        dataToSave[i][cellDataKeys[j]].employees[k].leave_id = resParsed.id;
                        dataToSave[i][cellDataKeys[j]].employees[k].leave_day_id = resParsed.leave_days[0].id;

                        const svIdx = summary.value.findIndex((sv) => sv.code == dataToSave[i][cellDataKeys[j]].employees[k].code);
                        if (svIdx > -1) {
                          summary.value[svIdx].count = summary.value[svIdx].count + 1;
                          summary.value = summary.value.sort((a, b) => b.count - a.count);
                        }
                        else {
                          summary.value.push({ id: uuidv4(), name: dataToSave[i][cellDataKeys[j]].employees[k].name, code: dataToSave[i][cellDataKeys[j]].employees[k].code, count: 1 });
                        }
                      }
                    }
                  }
                  dataToSave[i][cellDataKeys[j]].employees[k].changed = false;
                }
              }
              dataToSave[i][cellDataKeys[j]].employees = dataToSave[i][cellDataKeys[j]].employees.filter((e) => e.toRemove == null && e.invalid == null);
              store.commit('writeDCPinnedBtmRowsData', dataToSave);
            }
          }
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    async function saveData() {
      try {
        await saveDutyChartsData();
        await saveLeavesData();
      }
      catch (err) {
        console.log(err);
      }
    }

    // Add a new row
    const addRow = () => {
      // Ensure each new row has a unique `id`
      const newRow = {
        id: uuidv4(), // Unique ID for each row
        new_row: true
      };

      selectedRowIndex.value = 0; // Update the selected row index to the new row
      // Update the rowData reactively
      gridApi.value.applyTransaction({ add: [ newRow ] });
      const oldRows = store.state.dutyCharts;
      const newSet = [newRow, ...oldRows];
      store.commit('writeDutyChartsData', newSet);
      filterUnscheduledRows();
      //rowData.value = JSON.parse(JSON.stringify(newSet));
      setTimeout(() => {
        // Set focus to the first cell of the first row
        gridApi.value.setFocusedCell(0, 'program');
        gridApi.value.startEditingCell({ rowIndex: 0, colKey: 'program' });
      }, 0);
    };

    function convertUTCToIST(dateString) {
      // Parse the input string as a Date object in UTC
      const utcDate = new Date(dateString);
      const minutesToAdd = 330;
      utcDate.setTime(utcDate.getTime() + minutesToAdd * 60 * 1000);

      // Extract individual components
      const dayOfWeek = utcDate.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'Asia/Kolkata' }).toLowerCase();
      const day = utcDate.toLocaleDateString('en-US', { day: 'numeric', timeZone: 'Asia/Kolkata' });
      const month = utcDate.toLocaleDateString('en-US', { month: 'short', timeZone: 'Asia/Kolkata' }).toLowerCase();
      const year = utcDate.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'Asia/Kolkata' });

      // Construct the desired format
      return `${dayOfWeek}_${day}_${month}_${year}`;
    }

    async function getTasksData() {
      try {
        let ret = [];
        const folderList = getAllFoldersList(bSettings.value);
        const currentDocpFolder = getCurrentFolder('tasks', folderList);
        const { listData } = postData();
        const txnParams = { refRows: false };
        txnParams.filters = {
          'filterInput': [
            {
              'path': 'tasks.deleted',
              'operator': '=',
              'value': 'false'
            }
          ],
          'joinop': 'and'
        }

        if (bSettings.value.env.code != 'admin') {
          txnParams.filters.filterInput.push({
            'path': 'tasks.subsections_id.id',
            'operator': '=',
            'value': bSettings.value.env.subsections.id
          });
        }

        if (selectedDep.value != null) {
          txnParams.filters.filterInput.push({
            'path': 'tasks.departments_id.id',
            'operator': '=',
            'value': selectedDep.value.id
          });
        }
        if (selectedSubs.value != null) {
          txnParams.filters.filterInput.push({
            'path': 'tasks.subsections_id.id',
            'operator': '=',
            'value': selectedSubs.value.id
          });
        }
        txnParams.projections = {
          'tasks': ['id', 'name', 'code', 'time_slot', 'scheduled'],
        }

        const list = await listData(currentDocpFolder, txnParams);
        if (list.output.type === 'error') {
          console.log(list.output.message);
        }
        else {
          if (list.output?.data?.records?.length > 0) {
            const { fatRowGenerator } = getFatRow();
            const fatRows = fatRowGenerator(list);
            ret = fatRows.map((item, idx) => ({ id: uuidv4(), program: [item] }));
          }
        }
        return ret;
      }
      catch (err) {
        console.log(err)
      }
    }

    async function getDutyChartsData() {
      try {
        const oldRows = store.state.dutyCharts;
        const folderList = getAllFoldersList(bSettings.value);
        const { listData } = postData();
        const currentDocpFolder1 = getCurrentFolder('duty_charts', folderList);
        const txnParams1 = { refRows: true };
        txnParams1.projections = {
          'duty_charts': ['id', 'date', 'employees_id', 'tasks_id'],
          'duty_charts.employees_id': ['id', 'code', 'persons_id', 'subsections'],
          'duty_charts.employees_id.persons_id': ['id', 'first_name'],
          'duty_charts.employees_id.subsections': ['id', 'code'],
          'duty_charts.tasks_id': ['id', 'name', 'code']
        }
        txnParams1.filters = {
          'filterInput': [],
          'joinop': 'and'
        }

        if (bSettings?.value?.env?.code != 'admin' && (bSettings?.value?.env?.isDCO == null || bSettings?.value?.env?.isDCO == false)) {
          txnParams1.filters.filterInput.push({
            'path': 'duty_charts.employees_id.id',
            'operator': '=',
            'value': bSettings?.value?.env?.id
          });
        }
        else if (bSettings?.value?.env?.isDCO != null && bSettings?.value?.env?.isDCO == true) {
          txnParams1.filters.filterInput.push({
            'path': 'duty_charts.employees_id.subsections.id',
            'operator': '=',
            'value': bSettings?.value?.env?.subsections?.id
          });
        }

        if (selectedEmp.value != null) {
          txnParams1.filters.filterInput.push({
            'path': 'duty_charts.employees_id.id',
            'operator': '=',
            'value': selectedEmp.value.id
          });
        }

        const list1 = await listData(currentDocpFolder1, txnParams1);
        if (list1.output.type === 'error') {
          console.log(list1.output.message);
        }
        else {
          if (list1.output?.data?.records?.length > 0) {
            const { fatRowGenerator } = getFatRow();
            const fatRows = fatRowGenerator(list1);
            fatRows.forEach((fr) => {
              const fIndex = oldRows.findIndex((or) => or.program != null && or.program[0].code == fr.tasks_id[0].code);
              if (fIndex > -1) {
                //i had to re-filter on employee docp because list api does not support this kind of filter on multi docp yet
                if (selectedEmp.value != null) {
                  fr.employees_id = fr.employees_id.filter((emp) => emp.id == selectedEmp.value.id);
                }

                if (bSettings.value.env.code != 'admin' && (bSettings.value.env.isDCO == null || bSettings.value.env.isDCO == false)) {
                  fr.employees_id = fr.employees_id.filter((emp) => emp.id == bSettings.value.env.id);
                }
                else if (bSettings.value.env.isDCO != null && bSettings.value.env.isDCO == true) { fr.employees_id = fr.employees_id.filter((emp) => emp.subsections != null && emp.subsections.length > 0 && emp.subsections[0].id == bSettings.value.env.subsections.id);
                }
                const dateStr = convertUTCToIST(fr.date);
                oldRows[fIndex][dateStr] = { duty_chart_id: fr.id, date: fr.date, employees: fr.employees_id.map((emp) => ({ id: emp.id, code: emp.code, name: emp.persons_id[0].first_name.eng }))};
              }
            });

            if (gridApi.value != null) {
              setTimeout(() => {
                gridApi.value.resetRowHeights();
              }, 0);
            }
          }
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    function convertToISO8601(dateString) {
      return dateString.replace(' ', 'T') + '.000Z';
    }

    async function getLeavesData(params) {
      try {
        const oldRows = store.state.dutyChartPinnedBottomRows;
        const folderList = getAllFoldersList(bSettings.value);
        const { listData } = postData();
        const currentDocpFolder1 = getCurrentFolder('leaves', folderList);
        const txnParams1 = { refRows: true };
        txnParams1.projections =  {
          'leaves': ['id', 'employees_id', 'leave_days'],
          'leaves.employees_id': ['id', 'code', 'persons_id'],
          'leaves.employees_id.persons_id': ['id', 'first_name'],
          'leaves.leave_days':['id', 'leave_types_id', 'leave_period', 'leave_status' ],
          'leaves.leave_days.leave_types_id':[ 'id', 'code', 'name'],
          'leaves.leave_days.leave_status':[ 'id', 'code', 'name' ]
        },
        txnParams1.filters = {
          'filterInput': [
            {
              'path': 'leaves.deleted',
              'operator': '=',
              'value': 'false'
            },
          ],
          'joinop': 'and'
        }

        if (bSettings?.value?.env?.code != 'admin' && (bSettings?.value?.env?.isDCO == null || bSettings?.value?.env?.isDCO == false)) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.id',
            'operator': '=',
            'value': bSettings?.value?.env?.id
          });
        }
        else if (bSettings?.value?.env?.isDCO != null && bSettings?.value?.env?.isDCO == true) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.subsections.id',
            'operator': '=',
            'value': bSettings?.value?.env?.subsections?.id
          });
        }

        const list1 = await listData(currentDocpFolder1, txnParams1);
        if (list1.output.type === 'error') {
          console.log(list1.output.message);
        }
        else {
          if (list1.output?.data?.records?.length > 0) {
            const { fatRowGenerator } = getFatRow();
            const fatRows = fatRowGenerator(list1);
            fatRows.forEach((fr) => {
              if (fr.leave_days[0].leave_types_id[0].code == 'WO') {
                const inputDateStr = JSON.parse(fr.leave_days[0].leave_period.replace(')', ']'))[0];
                const dateStr = convertUTCToIST(inputDateStr);
                if (oldRows[0][dateStr] != null && oldRows[0][dateStr].employees != null) {
                  oldRows[0][dateStr].employees.push(fr.employees_id.map((emp) => ({ leave_id: fr.id, id: emp.id, code: emp.code, name: emp.persons_id[0].first_name.eng, leave_status: fr.leave_days[0].leave_status[0].code, leave_day_id: fr.leave_days[0].id }))[0]);
                }
                else {
                  oldRows[0][dateStr] = { date: convertToISO8601(inputDateStr), employees: fr.employees_id.map((emp) => ({ leave_id: fr.id, id: emp.id, code: emp.code, name: emp.persons_id[0].first_name.eng, leave_status: fr.leave_days[0].leave_status[0].code, leave_day_id: fr.leave_days[0].id  }))};
                }
              }
              else {
                const inputDateStr = JSON.parse(fr.leave_days[0].leave_period.replace(')', ']'))[0];
                const dateStr = convertUTCToIST(inputDateStr);
                if (oldRows[1][dateStr] != null && oldRows[1][dateStr].employees != null) {
                  oldRows[1][dateStr].employees.push(fr.employees_id.map((emp) => ({ leave_id: fr.id, id: emp.id, code: emp.code, name: emp.persons_id[0].first_name.eng, leave_code: fr.leave_days[0].leave_types_id[0].code, leave_status: fr.leave_days[0].leave_status[0].code, leave_day_id: fr.leave_days[0].id  }))[0]);
                }
                else {
                  oldRows[1][dateStr] = { date: convertToISO8601(inputDateStr), employees: fr.employees_id.map((emp) => ({ leave_id: fr.id, id: emp.id, code: emp.code, name: emp.persons_id[0].first_name.eng, leave_code: fr.leave_days[0].leave_types_id[0].code, leave_status: fr.leave_days[0].leave_status[0].code, leave_day_id: fr.leave_days[0].id  }))};
                }
              }
            });
            setTimeout(() => {
              params.api.setPinnedBottomRowData(JSON.parse(JSON.stringify(oldRows)));
            }, 0);
          }
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    async function getSummary() {
      try {
        const { createTimeRange } = attendanceGridUtils();
        const dateRange = createTimeRange({ day: 1, month: timeRange.value.month + 1, year: timeRange.value.year });
        const folderList = getAllFoldersList(bSettings.value);
        const { listData } = postData();
        const currentDocpFolder1 = getCurrentFolder('leaves', folderList);
        const txnParams1 = { refRows: true };
        txnParams1.projections = {
          'leaves': [ 'id', 'employees_id', 'leave_days'],
          'leaves.employees_id': ['id', 'code', 'persons_id'],
          'leaves.employees_id.persons_id': ['id', 'first_name'],
          'leaves.leave_days':['id', 'leaves_id', 'leave_types_id', 'leave_period', 'leave_minutes', 'leave_status' ],
          'leaves.leave_days.leave_types_id':[ 'id', 'code', 'name'],
          'leaves.leave_days.leave_status':[ 'id', 'code', 'name', 'creation_date']
        }

        txnParams1.filters = {
          'filterInput': [
            {
              'path': 'leaves.deleted',
              'operator': '=',
              'value': 'false'
            },
            {
              path: 'leaves.leave_days.leave_period',
              operator: 'between',
              value: [dateRange[0], dateRange[1]],
            },
            {
              'path': 'leaves.leave_days.leave_types_id.code',
              'operator': '=',
              'value': 'WO'
            },
          ],
          'joinop': 'and'
        }

        if (bSettings?.value?.env?.code != 'admin' && (bSettings?.value?.env?.isDCO == null || bSettings?.value?.env?.isDCO == false)) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.id',
            'operator': '=',
            'value': bSettings?.value?.env?.id
          });
        }
        else if (bSettings?.value?.env?.isDCO != null && bSettings?.value?.env?.isDCO == true) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.subsections.id',
            'operator': '=',
            'value': bSettings?.value?.env?.subsections?.id
          });
        }

        if (selectedEmp.value != null) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.id',
            'operator': '=',
            'value': selectedEmp.value.id
          });
        }
        if (selectedDep.value != null) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.departments.id',
            'operator': '=',
            'value': selectedDep.value.id
          });
        }
        if (selectedSubs.value != null) {
          txnParams1.filters.filterInput.push({
            'path': 'leaves.employees_id.subsections.id',
            'operator': '=',
            'value': selectedSubs.value.id
          });
        }

        const list1 = await listData(currentDocpFolder1, txnParams1);
        if (list1.output.type === 'error') {
          console.log(list1.output.message);
        }
        else {
          if (list1.output?.data?.records?.length > 0) {
            const { fatRowGenerator } = getFatRow();
            const fatRows = fatRowGenerator(list1);
            summary.value = fatRows.reduce((retVal, item) => {
              const key = item['employees_id'][0]['code'];
              const idx = retVal.findIndex((r) => r.code == key);
              if (idx > -1) {
                retVal[idx].count = retVal[idx].count + 1;
              }
              else {
                retVal.push({ id: item.id, name: item['employees_id'][0]['persons_id'][0]['first_name']['eng'], code: key, count: 1 });
              }
              return retVal;
            }, []).sort((a, b) => b.count - a.count);
          }
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    async function applyFilter(inp) {
      try {
        const data = await getTasksData();
        if (inp == 'emp') {
          store.commit('writeDutyChartsData', data);
          rowData.value = JSON.parse(JSON.stringify(data));
          await getDutyChartsData();
        }
        else {
          const subsData = bSettings.value.output.data.records[0].folderData.subsections;
          if (selectedDep.value != null) {
            subsOptions.value = JSON.parse(JSON.stringify(subsData.filter((ss) => ss.department_id == selectedDep.value.id)));
          }
          else {
            subsOptions.value = JSON.parse(JSON.stringify(subsData));
          }
          const oldRows = store.state.dutyCharts;
          const filteredRows = oldRows.filter((or) => or.new_row == null && data.findIndex((d) => or.program[0].scheduled == true && d.program[0].id == or.program[0].id) > -1);
          rowData.value = JSON.parse(JSON.stringify(filteredRows));
        }
      }
      catch (err) {
        console.log(err);
      }
    }

    // Grid Ready Event
    const onGridReady = async(params) => {
      try {
        gridApi.value = params.api; // Initialize gridApi
        gridColumnApi.value = params.columnApi; // Initialize gridColumnApi
        store.commit('writeDCPinnedBtmRowsData', JSON.parse(JSON.stringify(pinnedRowData)));
        const data = await getTasksData();
        store.commit('writeDutyChartsData', data);
        rowData.value = JSON.parse(JSON.stringify(data));
        await getDutyChartsData();
        filterUnscheduledRows();
        await getSummary();
        await getLeavesData(params);
        gridReady.value = true;
      }
      catch (err) {
        console.log(err);
      }
    };

    function getRowStyle(params) {
      // Apply background color to pinned rows
      if (params.node.rowPinned) {
        return { background: '#fff7d6' };
      }
      return null; // Default row style
    }

    // Return unique row id for each row
    const getRowNodeId = (data) => data.id;

    const getRowHeight = (params) => {
      let allData = [];
      if (params.node.rowPinned == 'bottom') {
        allData = store.state.dutyChartPinnedBottomRows;
      }
      else {
        allData = store.state.dutyCharts;
      }
      const rowData = allData.find((ad) => ad.id == params.data.id) || {};
      const dates = getDateRange();
      const maxHeight = Object.keys(rowData).reduce((height, k) => {
        let contentLength = 0;
        if (params.node.rowPinned != 'bottom' && k == 'program' && rowData[k] != null && rowData[k].length > 0) {
          contentLength = (rowData[k][0].name.length / 30) + 0.5; //30 is the max possible number of character in a line
        }
        else if (['id', 'program'].indexOf(k) < 0 && rowData[k] != null && rowData[k].date != null && dates.indexOf(convertUTCToIST(rowData[k].date)) > -1  && rowData[k].employees != null) {
          contentLength = rowData[k].employees.filter((emp) => emp.toRemove == null).length - 1;
        }

        if (params.node.rowPinned == 'bottom' && params.data.id == 'leaves_row') {
          return Math.max(height, 40 + (contentLength * 40));
        }
        else {
          return Math.max(height, 70 + (contentLength * 40));
        }
      }, 70);
      return params.node.rowPinned == 'bottom' ? Math.min(300, maxHeight) : maxHeight;
    };

    return {
      columnDefs,
      rowData,
      defaultColDef,
      addRow,
      onGridReady,
      getRowNodeId, // Added for row uniqueness
      gridApi, // Make sure to return gridApi and gridColumnApi
      gridColumnApi,
      getRowHeight,
      rowClassRules,
      updateMonthYear,
      gridReady,
      saveData,
      pinnedRowData,
      empOptions,
      depOptions,
      subsOptions,
      applyFilter,
      selectedEmp,
      selectedDep,
      selectedSubs,
      getRowStyle,
      summary,
      searchEmp,
    };
  }
};
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  display: flex;
}

/* Wrapper for the grid and controls */
.ag-grid-container {
  width: 80vw;
  height: 100%;
  margin-left: 5px;
}

.summary {
  width: 20vw;
  height: 100%;
  overflow: scroll;
  margin-left: 10px;
}

.wocm{
  margin-top: 23px;
  width: 100%;
  height: 35px;
  color: black;
  font-weight: bold;
}

.emp-list {
  width: 100%;
  height: 30px;
  border: 1px solid #000;
  border-radius: 8px;
}

/* Wrapper to ensure grid and controls are positioned correctly */
.ag-grid-wrapper {
  width: 100%;
  height: 88%; /* Adjust height to account for controls */
  border: 1px solid #000; /* Add a black border around the grid */
  border-radius: 8px; /* Optional: Adds rounded corners to the grid */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for better visual effect */
  padding: 10px; /* Optional: Adds some padding between the grid and the border */
}

/* Week Selector and Add Row Button */
.controls {
  width:100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px;
}

.filter-container {
  width:65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #292929;
}

.vs{
  width: 200px;
}
/* Style the v-select component */
.v-select {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
}

/* Style for dropdown when open */
.v-select.open {
  border-color: #5a67d8;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Style the dropdown items */
.v-select-list {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Style for each option in the dropdown */
.v-select-list li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

/* Hover effect for options */
.v-select-list li:hover {
  background-color: #5a67d8;
  color: white;
}

/* Style selected option */
.v-select-selected-item {
  background-color: #5a67d8;
  color: white;
  border-radius: 8px;
  padding: 8px 12px;
  display: inline-block;
}

/* Style the placeholder */
.v-select-placeholder {
  color: #9a9a9a;
}

/* Focus state */
.v-select:focus {
  outline: none;
  border-color: #5a67d8;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Custom dropdown arrow */
.v-select::after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #9a9a9a;
}

.week-selector {
  display: flex;
  align-items: center;
}

.save-button {
  background-color: green;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  height: 33px;
  width: 70px;
  cursor: pointer;
}

.add-row-button {
  background-color: blue;
  color: white;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  line-height: 20px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}

.add-row-button:hover {
  cursor: pointer;
  background-color: #45a049;
}
</style>
