<template>
  <WfmToolbar
    :data-test-id="'form_' + folder.name"
    custom-class="action-class"
  >
    <template #left-utility>
      <WfmActionsList
        :actions="actionsToPerform.length>0?actionsToPerform:null"
        :data-test-id="'form_' + folder.name"
        @action-click="performActionModal"
      />
    </template>
  </WfmToolbar>
  <div class="payslip-container">
    <div class="p-4">
      <WfmLoader v-if="showLoader" />
      <div v-html="htmlTemplate?.html" />
    </div>
  </div>
</template>


<script>
import { computed, ref, onMounted, onActivated} from 'vue';
import WfmToolbar from '../../common/wfm-toolbar.vue';
// import getUtility from '../../composables/getUtility'
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import { sendMessage } from '../../services/websocket'
import { useStore } from 'vuex';
import WfmLoader from '../../common/loader/wfm-loader.vue'
import { toast } from 'vue3-toastify';
import foldersUtility from '../../composables/foldersUtility';
import makeFilter from '../../composables/makeFilter';
import postData from '../../composables/postData'
import getFatRow from '../../composables/fatRowGenerator'
import makeTxnForBulk from '../../composables/makeTxnForBulk'
import actionsListInFolder from '../../composables/actionsListInFolder';
import { usePaths } from '../../composables/useFilePath';
export default {
  name: 'HtmlTemplate',
  components: {
    WfmToolbar,
    WfmActionsList,
    WfmLoader
  },
  props: {
    folder: {
      type: Object,
      default: null,
    },
    data:{
      type:Object,
      default:null
    }
  },
  emits:['close-payslips'],
  setup(props, context) {
    const showLoader = ref(false)
    const {getIcon} = useIcons()
    const store = useStore()
    const sessionId = store.getters['sessionIdGetter'];
    const actionsToShow = computed(() => {
      const listOfTxn = props?.folder?.txns;
      const {actionsToShowInList} = actionsListInFolder(listOfTxn)
      const retVal = props?.readOnly ? [] : actionsToShowInList()
      return retVal
    })

    const actionsList = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      for (var i = 0; i < actionsToShow.value.length; i++) {
        const index = i;
        const iconsName = actionsToShow?.value?.[i]?.label?.eng?.split(' ')[0];
        const recordView = actionsToShow.value[i].form.record_view
        const obj = {
          name:actionsToShow?.value?.[i]?.label?.eng,
          icon:getIcon(iconsName)?.name,
          fill:getIcon(iconsName)?.fill,
          action:() => {
            runTxn(actionsToShow.value[index])
          }
        }
        if (recordView && iconsName != 'Delete') {
          retVal.actions.push(obj)

        }
      }
      const downloadAction =
        {
          name:'Download',
          icon:getIcon('Download')?.name,
          fill:getIcon('Download')?.fill,
          action:() => {
            download()
          }
        }
      retVal.actions.push(downloadAction)
      return retVal
    })
    async function runTxn(txn) {
      const txnNameArray = txn.name.split('_')
      await approvePayslip(txn, txnNameArray[txnNameArray.length - 1])

    }
    const actionsToPerform = computed(() => {
      const retVal = []
      if (actionsList.value.actions.length > 0) {
        retVal.push(actionsList.value)
      }
      return retVal
    })
    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings']
      return bSettingString
    })
    function performActionModal(action) {
      console.log('handle action in the modal')
      action()
    }
    const htmlTemplate = ref(null)
    async function getTemplate() {
      try {
        showLoader.value = true
        let txnToRun = null
        if (props.folder.name == 'payslips') {
          txnToRun = props.folder.txns['txn_payslips_get_payslip']
        }
        else if (props.folder.name == 'invoices') {
          //get invoice txn will come here
          txnToRun = props.folder.txns['txn_invoices_get_invoice']
        }
        const params = {'uconst': props.data.uconst}
        txnToRun.params = params
        const res = await sendMessage(txnToRun)
        showLoader.value = false
        if (res != null && res?.output?.type == 'success') {
          htmlTemplate.value = res.output
        }
      } catch (error) {
        console.log(error)
      }

    }

    const notificationId = ref()

    //should do same for invoices if txn available
    async function approvePayslip(txn, bulkTxnName) {
      const selectedRow = props.data;
      //notification start
      notificationId.value = toast.loading('working on it...', {
        position: toast.POSITION.TOP_CENTER,
      });

      const dataToChange = { path: '', value: [] };
      let docpSelected = [];
      const txnName = bulkTxnName;
      let propertyName = null;
      let docpSource = null;

      const { changeWorkingFolder } = foldersUtility();
      let docpSourceFolder = null;

      propertyName = 'status';
      docpSource = props.folder.fields[propertyName]?.source;
      docpSourceFolder = changeWorkingFolder(docpSource, bSettings.value?.output.data.records[0]);

      // Getting the docpId
      const { generateFilterParams } = makeFilter(txnName, docpSourceFolder, null, null, true);
      const filterParams = generateFilterParams();
      const txnParams = { filters: filterParams, getRowCount: true, refRows: true };

      const { listData } = postData();
      const docpList = await listData(docpSourceFolder, txnParams);
      if (docpList.output.type === 'error') {
        console.log(docpList.output.message);
      } else {
        if (docpList.output.data.records.length > 0) {
          const { fatRowGenerator } = getFatRow();
          docpSelected = fatRowGenerator(docpList);
        }
      }
      //creating formData
      dataToChange.path = `${props.folder.name}.status`;

      if (selectedRow[propertyName]) {
        dataToChange.value.push({
          oldDocpId: selectedRow[propertyName][0].id || selectedRow[propertyName][0],
          newId: docpSelected[0].id,
          txnOp: 'U',
          mainFolderId: selectedRow.id,
          propertyName: propertyName,
          params: selectedRow,
        });
      } else {
        dataToChange.value.push({
          oldDocpId: null,
          newId: docpSelected[0].id,
          txnOp: 'C',
          mainFolderId: selectedRow.id,
          propertyName: propertyName,
          params: selectedRow,
        });
      }

      const formData = dataToChange;

      // Generate the txnToRun
      const { generateTxn } = makeTxnForBulk(formData, props.folder, txn, txnName);
      const txnToRun = generateTxn();
      txnToRun.session_key = sessionId;

      const txnRetVal = await sendMessage(txnToRun);
      await handleResult(txnRetVal);
    }


    //handle the retVal
    async function handleResult(data) {
      console.log('dd', data)
      if (data.output.type === 'success') {
        toast.update(notificationId.value, {
          render: 'Done',
          autoClose: 1000,
          closeButton: true,
          type: 'success',
          isLoading: false
        })
      }
      if (data.output.type === 'error') {
        toast.update(notificationId.value, {
          render: data.output.message,
          type: 'error',
          isLoading: false,
          autoClose:3000,
          closeButton: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }

    function closeModal() {
      try {
        //emit to close
        context.emit('close-payslips')
      } catch (error) {
        console.log(error);
      }
    }
    function download() {

      const fileName = htmlTemplate.value.fileName
      const start = fileName.indexOf('payslips') !== -1 ? fileName.indexOf('payslips') : fileName.indexOf('invoices');
      const result = fileName.substring(start);
      const {fileServePath} = usePaths()
      const url = `${fileServePath}/${result}`
      window.open(url, '_blank')

    }
    onActivated(() => {
      if (htmlTemplate.value == null) {
        getTemplate()

      }

    })
    onMounted(() => {
      getTemplate()
    })
    return {
      actionsToPerform,
      performActionModal,
      htmlTemplate,
      closeModal,
      getTemplate,
      showLoader


    };
  },
};
</script>

<style>
.action-class{
  background-color: var(--toolbar-color);
  padding-top:30px;
  padding-bottom:30px;
  margin-top: 0px;
  padding-left:10px;
  color:white;
}
.payslip-container{
  width: 100%;
  height: 80vh;
  background-color: white;
}

</style>
