//composable to keep projections for folders in one place
const getProjections = () => {
  const projectionsList = {
    'tasks': {
      'tasks': ['id', 'name', 'code', 'time_slot', 'scheduled', 'departments_id', 'subsections_id'],
      'tasks.departments_id': ['id', 'name', 'code'],
      'tasks.subsections_id': ['id', 'name', 'code'],
    },
    'payslips':{
      'payslips': ['uconst', 'pay_period', 'employees_id', 'aggregate_pay_details', 'payroll_month', 'working_days', 'itemized_pay_details', 'status', 'partners_id'],
      'payslips.employees_id': ['persons_id', 'departments', 'designation', 'subsections', 'code'],
      'payslips.status':['code', 'id', 'name'],
      'payslips.employees_id.departments':['id'],
      'payslips.employees_id.designation':['id'],
      'payslips.employees_id.subsections':['id', 'code', 'name'],
      'payslips.employees_id.persons_id':['first_name', 'uan', 'pan', 'esic_number', 'last_name', 'middle_name'],
      'payslips.aggregate_pay_details':['amount', 'payhead_id', 'payhead_types', 'payable_days', 'overtime_hrs'],
      'payslips.aggregate_pay_details.payhead_id':['code'],
      'payslips.aggregate_pay_details.payhead_types':['code'],
      'payslips.itemized_pay_details':['sites_id'],
      'payslips.itemized_pay_details.sites_id':['code', 'name']
    },
    'leaves': {
      'leaves': ['id', 'creation_date', 'employees_id', 'leave_days', 'notes'],
      'leaves.employees_id': ['id', 'code',  'persons_id'],
      'leaves.employees_id.persons_id': ['id',  'last_name', 'first_name', 'middle_name'],
      'leaves.leave_days':['id', 'leaves_id', 'leave_types_id', 'leave_period', 'leave_minutes', 'leave_status', 'half_day_duration',  'documents'],
      'leaves.leave_days.leave_types_id':[ 'id', 'code', 'name'],
      'leaves.leave_days.documents':['id', 'filepath'],
      'leaves.leave_days.half_day_duration':['id', 'code', 'name'],
      'leaves.leave_days.leave_status':[ 'id', 'code', 'name',  'creation_date']
    },
    'products':{
      'products':['code', 'creation_date', 'hsn_code', 'id', 'last_modified', 'name', 'price', 'sac_code', 'service_grades', 'unit_of_measurements', 'subtypes', 'status', 'types', 'taxes', 'partners_id'],
      'products.service_grades':['id', 'code'],
      'products.taxes':['id', 'code'],
      'products.unit_of_measurements':['id', 'code'],
      'products.status':['id', 'code'],
      'products.types':['id', 'code'],
      'products.subtypes':['id', 'code']
    },
    'attendance':{
      'attendance': ['uconst',  'creation_date', 'last_modified', 'attendance_date', 'working_minutes', 'overtime_minutes', 'employees_id', 'allocations_id',
        'sites_id', 'shifts_id', 'att_type', 'designation', 'attendance_status', 'observed_in_tmstmp', 'observed_out_tmstmp', 'last_processed_by', 'exception', 'leave_type', 'difference'],
      'attendance.last_processed_by': ['id', 'code'],
      'attendance.allocations_id': ['id'],
      'attendance.attendance_status': ['id', 'code', 'name',   'last_modified'],
      'attendance.shifts_id': ['id', 'name', 'shift_code', 'shift_time', 'effective_to', 'creation_date', 'last_modified'],
      'attendance.designation': ['id', 'code', 'name', 'price',  'creation_date', 'last_modified'],
      'attendance.att_type': ['id', 'code', 'name',  'last_modified'],
      'attendance.leave_type': ['id', 'code', 'name',   'last_modified'],
      'attendance.sites_id': ['id', 'code', 'city', 'name', 'email', 'gstin', 'address',  'pincode', 'swhandle', 'site_code', 'last_modified'],
      'attendance.employees_id': ['id', 'code',  'persons_id', 'working_hours', 'working_days', 'departments', 'subsections', 'employee_type', 'joining_date'],
      'attendance.employees_id.employee_type': ['id', 'code', 'name'],
      'attendance.employees_id.departments': ['id', 'code', 'name'],
      'attendance.employees_id.subsections': ['id', 'code', 'name'],
      'attendance.employees_id.persons_id': ['id',  'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
      'attendance.employees_id.persons_id.gender': ['id', 'code', 'name',   'last_modified'],
      'attendance.exception': ['id', 'type', 'working_minutes', 'overtime_minutes', 'shift_code', 'description', 'observed_in_tmstmp', 'observed_out_tmstmp', 'att_type', 'edit_timestamp'],
      'attendance.exception.att_type': ['id', 'code', 'name',  'last_modified'],
    },
    'allocations': {
      'allocations':['id', 'allocations_period', 'contracts_id', 'employees_id', 'products_id', 'sites_id', 'last_modified', 'created_by'],
      'allocations.contracts_id': ['id', 'attendance_based_items', 'contract_type', 'job_based_items', 'date_of_previous_month', 'name'],
      'allocations.employees_id': ['id', 'code',  'persons_id', 'departments', 'designation', 'duty_chart_officer', 'approving_officer', 'reporting_officer'],
      'allocations.employees_id.designation': ['id', 'code', 'name', 'price',  'creation_date', 'last_modified'],
      'allocations.employees_id.persons_id': ['id',  'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
      'allocations.employees_id.persons_id.gender': ['id', 'code', 'name', 'source',  'last_modified'],
      'allocations.employees_id.duty_chart_officer': ['id'],
      'allocations.employees_id.approving_officer': ['id'],
      'allocations.employees_id.reporting_officer': ['id'],
      'allocations.sites_id': ['id', 'code', 'city', 'name', 'email', 'gstin', 'address',  'pincode', 'swhandle', 'site_code', 'last_modified'],
      'allocations.products_id': ['id', 'code', 'name', 'price',  'creation_date', 'last_modified'],
      'allocations.allocations_period': ['allocations_id', 'allocations_range',  'id', 'last_modified', 'shifts_id', 'allocation_type', 'departments_id'],
      'allocations.allocations_period.shifts_id': ['id', 'name', 'shift_code', 'shift_time', 'effective_to', 'creation_date', 'last_modified'],
      'allocations.allocations_period.allocation_type': ['id', 'name', 'code', 'shift_time', 'creation_date', 'last_modified'],
      'allocations.employees_id.departments': ['id', 'code', 'name',  'creation_date', 'last_modified'],
      'allocations.allocations_period.departments_id': ['id', 'code', 'name',  'creation_date', 'last_modified'],
      'allocations.created_by': ['id', 'code',  'persons_id'],
      'allocations.created_by.persons_id': ['id',  'last_name', 'first_name', 'middle_name'],
      'allocations.contracts_id.job_based_items': ['id'],
      'allocations.contracts_id.contract_type': ['id', 'code', 'name'],
      'allocations.contracts_id.attendance_based_items': ['id']
    },
    'payslips_generate':{
      'payslips': ['id', 'uconst', 'employees_id'],
      'payslips.employees_id': ['id', 'code', 'employee_type', 'persons_id'],
      'payslips.employees_id.employee_type': ['id', 'code', 'name'],
      'payslips.employees_id.persons_id': ['first_name', 'middle_name', 'last_name'],
    },
    'shared_partners_list':{
      'partners':['id', 'code', 'name', 'sw_handle', 'partner_type', 'partner_groups'],
      'partners.partner_type':['id', 'code', 'name', 'partners_id'],
      'partners.partner_groups':['id', 'code', 'name', 'partners_id']
    },
    'invoices':{
      'invoices': [
        'bank_ifsc_code',
        'bank_account_number',
        'bank_name',
        'business_name',
        'customer_name',
        'business_gstin',
        'customer_gstin',
        'business_address',
        'customer_address',
        'business_pincode',
        'customer_pincode',
        'business_state',
        'customer_state',
        'business_city',
        'customer_city',
        'id',
        'uconst',
        'contracts_id',
        'invoice_no',
        'custom_invoice_numbers',
        'creation_date',
        'invoice_month',
        'narration',
        'invoice_period',
        'att_based_items',
        'c_billing_state',
        'total_gst_amount',
        'total_cgst_amount',
        'total_igst_amount',
        'total_sgst_amount',
        'total_payable_amount',
        'total_taxable_amount',
        'total_chargeable_amount',
        'total_service_charge_amount',
        'business_site_id',
        'partners',
        'partners_id',
        'status',
        'approval_documents'
      ],
      'invoices.att_based_items': [
        'id',
        'invoices_id',
        'hra',
        'basic_da',
        'employer_benefits',
        'employer_pf_amount',
        'employer_lwf',
        'regular_days',
        'taxable_amount',
        'overtime_amount',
        'overtime_hours',
        'other_allowances',
        'chargeable_amount',
        'overtime',
        'service_charge_amount',
        'sites_id',
        'designation_id'
      ],
      'invoices.att_based_items.designation_id': [
        'id',
        'name',
        'code',
        'partners_id',
        'price',
        'hsn_code',
        'sac_code'
      ],
      'invoices.att_based_items.sites_id': [
        'id',
        'code',
        'name',
        'partners_id',
        'address',
        'gstin',
        'city',
        'states',
        'pincode'
      ],
      'invoices.att_based_items.sites_id.states': [
        'id',
        'code',
        'name',
        'partners_id'
      ],
      'invoices.business_site_id': [
        'id',
        'code',
        'name',
        'partners_id',
        'address',
        'gstin',
        'city',
        'states',
        'pincode'
      ],
      'invoices.business_site_id.states': [
        'id',
        'code',
        'name',
        'partners_id'
      ],
      'invoices.contracts_id': [
        'id',
        'partners_id',
        'code',
        'name',
        'contract_period',
        'pf_deduct_invoice'
      ],
      'invoices.partners': [
        'id',
        'code',
        'name'
      ],
      'invoices.status': [
        'id',
        'partners_id',
        'code',
        'name'
      ],
      'invoices.approval_documents': [
        'id',
        'invoices_id',
        'status',
        'doc_name',
        'attachment',
        'rej_attachment',
        'txn_state'
      ],
      'invoices.approval_documents.status': [
        'id',
        'code',
        'name',
        'partners_id'
      ],
      'invoices.approval_documents.doc_name': [
        'id',
        'code',
        'name',
        'partners_id'
      ],
      'invoices.approval_documents.attachment': [
        'id',
        'partners_id',
        'filepath',
        'original_filename',
        'md5sum',
        'creation_date',
        's3_backup_url'
      ],
      'invoices.approval_documents.rej_attachment': [
        'id',
        'partners_id',
        'filepath',
        'original_filename',
        'md5sum',
        'creation_date',
        's3_backup_url'
      ],
      'invoices.approval_documents.txn_state': [
        'id',
        'records'
      ],
      'invoices.approval_documents.txn_state.records': [
        'id',
        'txn_state_id',
        'txn_name'
      ]
    },
    'invoice_in_attendance':{
      'invoices':['id', 'invoice_period']
    },
    'payslips_in_attendance':{
      'payslips':['id', 'pay_period']
    }

    //list the folder with thier projections
  }
  function projectionForFolder(folderName) {
    return projectionsList[folderName] ? projectionsList[folderName] : {}
  }

  return { projectionForFolder };
};

export default getProjections;
