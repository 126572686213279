const commonModule = {
  namespaced: true,
  state: {
    leaveQuota: [],
    currentRowData:{},
    partnersList:localStorage.getItem('partnersList') || [],
  },
  getters: {
    leavesQuotaGetters: function(state) {
      return state.detailGridApi
    },
    currentRowDataGetter: function(state) {
      return state.currentRowData
    },
    partnersListGetter:function(state) {
      if (state.partnersList != null && state.partnersList?.length > 0) {
        return JSON.parse(state.partnersList)
      }
      else {
        return []
      }
    },
  },
  mutations: {
    leavesQuotaMutations: function(state, value) {
      state.detailGridApi = value;
    },
    currentRowDataMutations: function(state, value) {
      state.currentRowData = value;
    },
    partnersListMutation:function(state, value) {
      localStorage.setItem('partnersList', JSON.stringify(value))
      state.partnersList = JSON.stringify(value)
    }
  },
  actions: {
    currentRowDataSetter(context, value) {
      context.commit('currentRowDataMutations', value)
    },
    async partnersListSetter(context, value) {
      await context.commit('partnersListMutation', value)
      const userSelectedPartner = context.rootGetters.bSettings.env.user_selected_partner
      if (userSelectedPartner == undefined) {
        const selectedPartner = value.filter((each) => each.code == context.rootGetters.bSettings.env.swhandle)
        context.rootState.bSettings.env.user_selected_partner = selectedPartner
      }

    }

  }
}

export default commonModule;
