<template>
  <div class="custom-header">
    <span>{{ label }}</span>
    <div v-if="isRegisterView">
      <EmployeeFilter
        :current-folder="currentViewFolder"
        :register-view="isRegisterView"
        :filters-data="filtersData"
        :filtered-emp="filteredAttendanceSummary"
        :selected-emp="selectedEmployee"
        :on-grid-ready="onGridReady"
        :update-filter-values="updateFilterValues"
        @update-selected-employee="updateSelectedEmployee"
      />
    </div>
    <div :class="isRegisterView ? 'right-position' : 'left-position'">
      <WfmDateSwitcher
        :is-week-picker="isWeekPicker"
        :grid-data-source="filtersData"
        @update-month-year="updateMonthYear"
      />
    </div>
    <div class="business-filter">
      <WfmBusinessFilter
        :folder="currentViewFolder"
        @apply-businesses-filter="updateFilterValues"
      />
    </div>
    <div
      class="actions"
    >
      <label v-if="gridView === 'attendance'">
        <input
          type="checkbox"
          :checked="isRegisterView"
          @change="showRegisterView($event)"
        >
        <span
          style="font-size: 12px"
        >Show Register View</span>
      </label>
      <label v-if="gridView === 'attendance' && !isRegisterView">
        <input
          type="checkbox"
          @change="showInOut($event)"
        >
        <span
          style="font-size: 12px"
        >Show in/out time</span>
      </label>
      <div class="action-container">
        <div
          v-if="!isRegisterView && allowRowRemove"
          class="action-button"
          @click="removeEmptyRows"
        >
          <button>
            Remove row
          </button>
        </div>
        <div
          class="action-button"
          @click="clearSelections"
        >
          <button>
            Deselect all
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'allocations'"
          class="action-button"
          @click="deleteAllocations"
        >
          <button>
            Delete Cell
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
          @click="approveAttendanceCells"
        >
          <button>
            Approve
          </button>
        </div>
        <div
          v-if="isApprovalManager && gridView === 'attendance'"
          class="action-button"
          @click="rejectAttendanceCells"
        >
          <button>
            Reject
          </button>
        </div>
        <div
          v-if="!isAdmin"
          class="action-save"
          @click="saveChanges"
        >
          <button>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="isRegisterView"
    :style="{float: 'right', width: '25%', height: '200px', 'margin-top': '4vh'}"
  >
    <RegisterSummary
      :current-folder="currentViewFolder"
      :filtered-emp="filteredAttendanceSummary"
      :on-grid-ready="onGridReady"
      :selected-emp="selectedEmployee"
      :register-view="isRegisterView"
      :show-pending-only="showPendingOnly"
      :update-filter-values="updateFilterValues"
      @toggle-pending-work="togglePendingWork"
      @update-search-query="updateSearchQuery"
      @update-selected-employee="updateSelectedEmployee"
    />
  </div>
  <div
    v-else
  >
    <FilterComponent
      :current-folder="currentViewFolder"
      :filters-data="filtersData"
      :on-grid-ready="onGridReady"
      :update-filter-values="updateFilterValues"
      :clear-selection="clearSelection"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import WfmDateSwitcher from '../../../common/wfm-dateSwitcher.vue';
import FilterComponent from './FilterComponent.vue';
import RegisterSummary from './RegisterSummary.vue';
import EmployeeFilter from './EmployeeFilter.vue';
import { useStore } from 'vuex';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import allocationGridUtils from '../../../composables/allocationGridUtils';
import { toast } from 'vue3-toastify';
import WfmBusinessFilter from '../../../common/wfm-business-filter.vue'
export default {
  components: { WfmDateSwitcher, FilterComponent, RegisterSummary, EmployeeFilter, WfmBusinessFilter},
  props: {
    currentFolder: {
      type: Object,
      default: () => {}
    },
    isDutyChart: {
      type: Boolean,
      default: () => false
    },
    registerView: {
      type: Boolean,
      default: () => false
    },
    filtersData: {
      type: Object,
      default: () => {}
    },
    selectedCells: {
      type: Object,
      default: () => {}
    },
    onGridReady: {
      type: Function,
      default: () => {}
    },
    showInOutToggle: {
      type: Function,
      default: () => {}
    },
    updateFilterValues: {
      type: Function,
      default: () => {}
    },
    clearSelection: {
      type: Function,
      default: () => {}
    },
  },
  emits: ['update-month-year', 'show-register-view'],
  setup(props, context) {
    const store = useStore();
    const bSettings = store.getters['bSettings'];
    const gridApi = computed(() => {
      return store.getters['agGridModule/attendanceGridApi']
    })
    const label = ref(props?.currentFolder?.label?.eng);
    const isWeekPicker = ref(props.isDutyChart);
    const isRegisterView = computed(() => {
      return props.registerView;
    }
    );
    const clearSelections = ref(props.clearSelection);
    const currentViewFolder = ref(props.currentFolder)
    const gridView = ref(currentViewFolder?.value?.name);
    const isApprovalManager = ref(false);
    const isReportingManager = ref(true);
    const isAdmin = ref(false);
    const showFilter = ref(true);
    const currentEmpCode = bSettings?.env?.code;
    if (bSettings?.env?.code === 'admin') {
      isApprovalManager.value = true;
    } else if (bSettings?.env?.isAO) {
      isApprovalManager.value = true;
    } else if (bSettings?.env?.isRO) {
      isApprovalManager.value = true;
    } else if (bSettings?.env?.isHR) {
      isApprovalManager.value = true;
    }
    if (currentEmpCode === 'admin') {
      isApprovalManager.value = true;
    }


    const { save, approveAttendance, rejectAttendance, deficitMinutesToHours } = attendanceGridUtils();
    const { saveAllocation, deleteAllocation } = allocationGridUtils();

    async function saveChanges() {
      if (gridView.value === 'attendance') {
        await save(store, isRegisterView.value, gridApi.value);
      } else {
        await saveAllocation(store);
      }
    }

    async function deleteAllocations() {
      const cellsSelected = props.selectedCells;
      const selectedCells = await deleteAllocation(store, cellsSelected, gridApi.value);
      props.clearSelection(selectedCells);
    }

    function showInOut(event) {
      const isChecked = event.target.checked;
      props.showInOutToggle(isChecked);
    }

    const removeEmptyRows = async() => {
      props.onGridReady();
    }

    const approveAttendanceCells = async() => {
      let isDeficit = false;
      let deficitMinutes = 0;
      let isSP = false;
      const cellsSelected = props.selectedCells;
      for (let i = 0; i < cellsSelected.length; i++) {
        const key = Object.keys(cellsSelected[i]);
        for (const obj in cellsSelected[i][key[0]]) {
          if (
            cellsSelected[i][key[0]][obj]?.deficit_minutes < 0
          ) {
            isDeficit = true;
            deficitMinutes += cellsSelected[i][key[0]][obj]?.deficit_minutes;
          }
          if (cellsSelected[i][key[0]][obj]?.att_type?.[0]?.code === 'SP') {
            isSP = true;
          }
        }
      }
      if (isSP) {
        toast.warning('Single Punch cannot be approved', {
          position: toast.POSITION.TOP_CENTER,
        });
        props.clearSelection([]);
        return false;
      }
      if (isDeficit) {
        const element = document.getElementsByClassName('alert-window');
        element[0].style.display = 'block';
        const deficitElement = document.getElementById('deficit-hours');
        deficitElement.textContent = `Total deficit hours: ${deficitMinutesToHours(deficitMinutes, true)}`;
      } else {
        const selectedCells = await approveAttendance(store, gridApi.value, cellsSelected);
        props.clearSelection(selectedCells);
      }
    }

    const rejectAttendanceCells = async() => {
      const cellsSelected = props.selectedCells;
      const selectedCells = await rejectAttendance(store, gridApi.value, cellsSelected);
      props.clearSelection(selectedCells);
    }

    async function updateMonthYear(monthYear) {
      context.emit('update-month-year', monthYear)
    }

    function showRegisterView(event) {
      context.emit('show-register-view', event.target.checked)
    }

    //Register view

    const attendanceRows = computed(() => {
      return store.getters['agGridModule/attendanceGridRows'];
    });


    const searchQuery = ref('');
    const selectedEmployee = ref();
    const showPendingOnly = ref(true);

    function updateSearchQuery(query) {
      searchQuery.value = query;
    }

    function updateSelectedEmployee(employee) {
      selectedEmployee.value = employee
    }
    function togglePendingWork(value) {
      showPendingOnly.value = value;
    }

    const attendanceSummary = computed(() => {
      const attStatus = [];
      for (let i = 0; i < attendanceRows.value?.attStatus?.length; i++) {
        let missedPunch = attendanceRows.value.length;
        for (let j = 0; j < attendanceRows.value.attStatus[i]?.data?.info?.length; j++) {
          const keys = Object.keys(attendanceRows.value.attStatus[i]?.data?.info[j]);
          if (attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[keys[0]] !== 0) {
          }
          missedPunch = missedPunch - attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[keys[0]];
        }
        for (let j = 0; j < attendanceRows.value.attStatus[i]?.data?.info?.length; j++) {
          const keys = Object.keys(attendanceRows.value.attStatus[i]?.data?.info[j]);
          if (keys[0] === 'unapproved') {
            missedPunch += attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[keys[0]];
            attStatus.push({
              empCode: attendanceRows.value.attStatus[i]?.data?.emp_code,
              empType: attendanceRows.value.attStatus[i]?.data?.employee_type?.eng,
              name: attendanceRows.value.attStatus[i]?.data?.first_name?.eng + ' ' +  (attendanceRows.value.attStatus[i]?.data?.last_name?.eng || ''),
              unapproved: attendanceRows.value.attStatus[i]?.data?.info?.[j]?.[keys[0]] !== 0 ? missedPunch : '-',
              id: attendanceRows.value.attStatus[i]?.data.emp_id
            })
          }
        }
      }
      return attStatus;
    })

    const filteredAttendanceSummary = computed(() => {
      const search = searchQuery.value.toLowerCase();
      return attendanceSummary.value.filter((status) => {
        const name = status.name.toLowerCase();
        const empCode = status.empCode.toLowerCase();

        // Filter by search query and unapproved status based on the "Show Pending Work" button
        const matchesSearch = name.includes(search) || empCode.includes(search);
        const matchesUnapprovedFilter = showPendingOnly.value ? status.unapproved > 0 : true;
        return matchesSearch && matchesUnapprovedFilter;
      });
    });

    const allowRowRemove = computed(() => {
      let retval = true;
      const bSettings = store.getters['bSettings'];
      const env = bSettings.env;
      const selectedSWHandle = store.getters['userModule/selectedPartner'];
      const loggedInSWHandle = env.swhandle

      if (loggedInSWHandle != selectedSWHandle) {
        retval = false;
      }
      return retval;
    })

    return {
      label,
      gridView,
      isWeekPicker,
      isRegisterView,
      showRegisterView,
      currentViewFolder,
      updateMonthYear,
      isAdmin,
      showInOut,
      isApprovalManager,
      isReportingManager,
      showFilter,
      saveChanges,
      removeEmptyRows,
      approveAttendanceCells,
      rejectAttendanceCells,
      clearSelections,
      deleteAllocations,
      filteredAttendanceSummary,
      togglePendingWork,
      showPendingOnly,
      selectedEmployee,
      updateSearchQuery,
      updateSelectedEmployee,
      allowRowRemove
    };
  }
};
</script>

<style>
.business-filter .vs--single .vs__selected{
margin: 0px;
}
.custom-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.right-position {
  position: absolute !important;
  left: 80vw;
  top: 11vh;
  width: 300px;
}

.custom-header div {
  cursor: pointer;
  position: relative;
}

.vs__selected{
  margin:0px;
}
.custom-header span {
  font-size: 20px;
  color: #292929;
  margin: 10px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 10px;
}

.filter-icon {
  cursor: pointer;
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.filter-icon:hover {
  background-color: #83ACC8;
}

.action-container {
  display: flex;
  align-items: center;
  color: #292929;
}

.action-button {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
  text-wrap: nowrap;
  cursor: pointer;
}

.action-button:hover {
  background-color: #83ACC8;
}

.action-save {
  background-color: #aebbcc;
  padding: 5px;
  border-radius: 5px;
  margin: 2px;
}

.action-save:hover {
  background-color: #83ACC8;
}

</style>
