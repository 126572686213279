import foldersUtility from './foldersUtility';
import getFieldOptions from './getFieldOptions';
let retVal = {};
const getFormFieldOptions = (formObject, currentFolder, bSetting) => {
  let finalDetailList = {}
  if (formObject != null && currentFolder != null) {
    finalDetailList = newFieldData()
  }
  function newFieldData() {
    const baseFolder = currentFolder
    //loop thru the object
    //find the detail of same table
    //maintain a list of fields that needs to be displayed
    //return the list of details present
    let detailsList = {}
    for (var i = 0; i < formObject.length; i++) {
      if (formObject[i].type === 'group') {
        for (var j = 0; j < formObject[i].fields.length; j++) {
          detailsList = extractDetails(formObject[i].fields[j], baseFolder)
        }
      } else {
        detailsList = extractDetails(formObject[i], baseFolder)
      }

    }
    return detailsList
  }
  function extractDetails(eachField, baseFolder) {
    //extracting the expr for details
    const mandatory_expr = eachField.mandatory_expr
    const visible_expr = eachField.visible_expr
    const readonly_expr = eachField.readonly_expr
    const valid_expr = eachField.valid_expr
    const default_value = eachField.default_value
    const file_upload = eachField.file_upload
    const placeHolder = eachField.placeHolder
    const format_expr = eachField.format_expr
    const filters = eachField.filters
    const pathArray = eachField.path?.split('.')
    if (pathArray != null) {
      for (var i = 0; i < pathArray.length; i++) {
        if (baseFolder && baseFolder.name === pathArray[i]) {
          //same folder
        } else {
          if (baseFolder && baseFolder.fields[pathArray[i]]?.type === 'docpicker' && i + 1 !== pathArray.length) {
            const docpSource = baseFolder.fields[pathArray[i]].source
            const {changeWorkingFolder} = foldersUtility()
            baseFolder = changeWorkingFolder(docpSource, bSetting)
          }
          else if (baseFolder && baseFolder.fields[pathArray[i]]?.type === 'detail') {
            const currentField = baseFolder.fields[pathArray[i]];
            const nextFieldKey = pathArray[i + 1];
            const nextField = currentField.fields?.[nextFieldKey];
            if (nextField || nextFieldKey === 'deleted') {
              if (nextFieldKey === 'deleted') {
                //most forms dont have delete field in detail forms.
                //in cases where delete field is defined in the form, with exprs.
                //as delete is not in the folder fields, creating fieldOptions
                const { createFieldOption } = getFieldOptions();
                currentField.fields[nextFieldKey] = createFieldOption(baseFolder, 'deleted');
              }
              const fieldExpressions = {
                mandatory_expr,
                visible_expr,
                readonly_expr,
                valid_expr,
                default_value,
                format_expr,
                file_upload,
                placeHolder,
                filters,
              };

              Object.assign(currentField.fields[nextFieldKey], fieldExpressions);
            }


            if (pathArray[i + 1]) {
              if (!Array.isArray(finalDetailList[pathArray[i]])) {
                // If it's not an array, initialize it as an empty array
                finalDetailList[pathArray[i]] = []
              }
              //add the details to proper object first}
              finalDetailList[pathArray[i]] = [
                ...finalDetailList[pathArray[i]],
                pathArray[i + 1]
              ]
            }
          }
        }
      }
    }

    return finalDetailList
  }
  function fieldsData(params) {
    const {
      path,
      currentFolder,
      bSettings,
      mandatory_expr = false,
      is_multiple = null,
      format_expr = null,
      filters = null,
      visible_expr = true,
      readonly_expr = false,
      default_value = null,
      valid_expr,
      file_upload = null,
      placeHolder = null,
      showFull = null,
      normal_control = false,
      is_detail = false,
      fieldOnlyView = false,
      clearable = true
    } = params;
    /*

    WORKING..
    first get the path and know the number of elements in it
    traverse thru each element
    check if its docPicker..
    if its docPiker and its not end of the path,  change the folder and continue traverse
    if its docPicker and its end of path. return the field
    if its details type check if its in precalculated detailList
    if its found return the field by adding the columns to show
    if its not continue traverse
    */
    //get the path array
    //exception for reports because the paths dont point to no folders.
    // if (path === "leave_month" || path === "leave_year") {
    //   retVal = {
    //     name: path,
    //     label: {
    //       eng: path === "leave_month" ? "Leave Month" : "Leave Year"
    //     },
    //     type: "timestamp",
    //     include_in_filters: true,
    //     foldername: "reports",
    //     path: path
    //   };
    // }
    if (currentFolder != null) {
      const pathArray = path.split('.');
      let updatedFolder = currentFolder;
      for (var i = 0; i < pathArray.length; i++) {
        //if its docp and we are not at the end of the path change folder and call fields data with new path
        if (updatedFolder && updatedFolder.fields[pathArray[i]]?.type === 'docpicker' && i + 1 != pathArray.length && i !== 0) {
          const docpSource = updatedFolder.fields[pathArray[i]].source;
          const {changeWorkingFolder} = foldersUtility()
          const newFolder = changeWorkingFolder(docpSource, bSettings);
          const newPathArray = pathArray.splice(i);
          const newPath = newPathArray.join('.');
          const newParams = {
            path: newPath,
            currentFolder: newFolder,
            bSettings:bSettings,
            mandatory_expr:mandatory_expr,
            is_multiple:is_multiple,
            format_expr:format_expr,
            filters:filters,
            visible_expr:visible_expr,
            readonly_expr:readonly_expr,
            default_value:default_value,
            valid_expr:valid_expr,
            file_upload:file_upload,
            placeHolder:placeHolder,
            showFull:showFull,
            normal_control : false,
            is_detail : false,
            fieldOnlyView:false,
            clearable:clearable


          };

          //call the function again with new path
          return fieldsData(newParams);
        }
        //if detail type found
        if (updatedFolder && updatedFolder.fields[pathArray[i]]?.type === 'detail' && !normal_control) {

          //check if the detailList has the same table which needs to be in the form and add the columns to show.
          if (finalDetailList?.hasOwnProperty(pathArray[i])) {
            const toReturn = {
              retval: updatedFolder.fields[pathArray[i]],
              toshow: finalDetailList?.[pathArray[i]]
            };
            delete finalDetailList[pathArray[i]];
            const detailPath = path.split('.');
            detailPath.pop();
            const newPath = detailPath.join('.');
            toReturn.retval.path = newPath;
            toReturn.retval.toShow = toReturn.toshow;
            retVal = toReturn.retval;
            return retVal;
          } else {
            //if not present check if the path next to to detail is the last one. if not change the folder to show the end field
            if (i + 1 != pathArray.length - 1) {
              updatedFolder = updatedFolder.fields[pathArray[i]];
            }
            //all the other columns of the detail fields will come here..for them just returning null to not create multiple detail table
            //of the same name for each column
            return null;
          }
        }
        if (normal_control && updatedFolder && updatedFolder.fields[pathArray[i]]?.type === 'detail') {
          const retVal = updatedFolder.fields[pathArray[i]].fields[pathArray[i + 1]];

          if (retVal) {
            const updatedProperties = {
              mandatory_expr,
              readonly_expr,
              visible_expr,
              default_value,
              format_expr,
              filters,
              valid_expr,
              file_upload,
              placeHolder,
              showFull,
              is_detail,
              fieldOnlyView,
              clearable,
              path,
            };
            if (is_multiple != null) {
              updatedProperties.is_multiple = is_multiple;
            }
            Object.assign(retVal, updatedProperties);
          }

          return retVal;
        }
        if (i + 1 === pathArray.length) {
          retVal = updatedFolder?.fields[pathArray[i]];

          if (retVal) {
            const updatedProperties = {
              mandatory_expr,
              readonly_expr,
              visible_expr,
              default_value,
              format_expr,
              filters,
              valid_expr,
              file_upload,
              placeHolder,
              showFull,
              fieldOnlyView,
              clearable,
              path,
            };
            if (is_multiple != null) {
              updatedProperties.is_multiple = is_multiple;
            }
            Object.assign(retVal, updatedProperties);
          }

          return retVal;
        }

      }
    }

    return retVal;
  }

  return { fieldsData, newFieldData };
};

export default getFormFieldOptions;
