import { toast } from 'vue3-toastify';
import attendanceGridUtils from './attendanceGridUtils';
import getProjections from './getProjections';
import postData from './postData';
import getFatRow from './fatRowGenerator';


const dataProcessor = (data) => {

  async function attendanceList(filterModel, params, monthYear, currentFolder, filterValues, registerView) {
    try {
      const { createTimeRange } = attendanceGridUtils();
      const dateRange = createTimeRange(monthYear)
      const { projectionForFolder } = getProjections();
      const projections = projectionForFolder('attendance');
      const txnParams = {
        refRows: true,
        page: {
          limitBag: {
            path: 'attendance.employees_id.id',
            limit: registerView ? 1 : 100,
            offset: params.startRow,
            orderBy: 'code',
          },
          getRowCountBag: {
            path: 'attendance.employees_id.id',
            value: true,
          },
          orderByBag: {
            path: 'attendance.employees_id.persons_id.first_name',
            value: 'asc'
          }
        },
        filters: {
          filterInput: [
            {
              path: 'attendance.attendance_date',
              operator: 'between',
              value: [dateRange[0], dateRange[1]],
            },
          ],
          joinop: 'and',
        },
        projections: projections,
      };

      if (filterValues.contract !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.allocations_id.contracts_id.id',
          value: [filterValues.contract.id],
        });
      }

      if (filterValues.designation !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.designation.id',
          value: [filterValues.designation.id],
        });
      }

      if (filterValues.department !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.employees_id.departments.id',
          value: [filterValues.department.id],
        });
      }

      if (filterValues.site !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.sites_id.id',
          value: [filterValues.site.id],
        });
      }

      if (filterValues.shift !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.shifts_id.id',
          value: [filterValues.shift.id],
        });
      }

      if (filterValues.attendanceType !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.att_type.id',
          value: [filterValues.attendanceType.id],
        });
      }

      if (filterValues.partners_id !== null) {
        txnParams.filters.filterInput.push({
          operator: '=',
          path: 'attendance.partners_id',
          value: [filterValues.partners_id],
        });
      }

      if (filterValues.reportingManger !== null) {
        txnParams.filters.filterInput.push({
          operator: '=',
          path: 'attendance.employees_id.reporting_officer.id',
          value: [filterValues.reportingManger.id],
        });
      }

      if (registerView && filterValues.employee !== null && filterValues.employee.id !== undefined && filterValues.employee.id !== null) {
        txnParams.filters.filterInput.push({
          operator: 'in',
          path: 'attendance.employees_id.id',
          value: [filterValues.employee.id],
        });
      }

      if (Object.keys(filterModel).length !== 0) {
        const filterInput = [];
        const empFilter = filterModel.employees.filter.split(' ');
        for (let i = 0; i < empFilter.length; i++) {
          filterInput.push({
            operator: 'ilike',
            path: 'attendance.employees_id.persons_id.first_name',
            value: '%' + empFilter[i] + '%',
          });
          filterInput.push({
            operator: 'ilike',
            path: 'attendance.employees_id.persons_id.middle_name',
            value: '%' + empFilter[i] + '%',
          });
          filterInput.push({
            operator: 'ilike',
            path: 'attendance.employees_id.persons_id.last_name',
            value: '%' + empFilter[i] + '%',
          });
          filterInput.push({
            operator: 'ilike',
            path: 'attendance.employees_id.code',
            value: '%' + empFilter[i] + '%',
          });
        }
        txnParams.filters.filterInput.push({filterInput, joinop: 'or'});
      }
      console.time('test5');
      const { listData } = postData();
      const list = await listData(currentFolder, txnParams);
      const summary = list.output.data.records[0].summary;

      list.output.data.records = list.output.data.records[0].attendance;
      return { attendanceList : list, attendanceSummary: summary };
    } catch (e) {
      console.log(e)
    }
  }

  async function dataCreation(data, sortModel, attendanceSummary, registerView, timeRange, gridDataSource, employee, empSortField, empSortOrder) {
    try {
      console.time('test1');
      const { fatRowGenerator } = getFatRow();
      const finalData = fatRowGenerator(data);
      let attData = [];
      if (registerView) {
        const currentMonthDays = new Date(timeRange.year, timeRange.month, 0).getDate();
        let color = '';
        for (let i = 0; i < currentMonthDays; i++) {
          const date = new Date(timeRange.year, timeRange.month - 1, i + 1);
          const weekday = date.toLocaleString('en-US', { weekday: 'short' });
          if (weekday === 'Mon') {
            if (color === '') {
              color = '#80808024';
            } else {
              color = '';
            }
          }
          const columnsData = {
            day: i + 1,
            attendance_date:`${i + 1}/${timeRange.month}/${timeRange.year}`,
            dates: `${i + 1} ${weekday}`,
            sites_id: null,
            observed_in_tmstmp: null,
            observed_out_tmstmp: null,
            working_minutes: null,
            att_type: null,
            remarks: null,
            deficit_minutes: null,
            attendance_status: null,
            isMissedPunch: true,
            backgroundColor: color,
          }
          attData.push(columnsData);
        }
        for (let i = 0; i < finalData.length; i++) {
          const currentDate = getDate(finalData[i].attendance_date)
          attData[currentDate - 1]['sites_id'] = finalData[i].sites_id;
          attData[currentDate - 1]['observed_in_tmstmp'] = finalData[i].observed_in_tmstmp;
          attData[currentDate - 1]['observed_out_tmstmp'] = finalData[i].observed_out_tmstmp;
          attData[currentDate - 1]['working_minutes'] = finalData[i].working_minutes;
          attData[currentDate - 1]['att_type'] = finalData[i].att_type;
          attData[currentDate - 1]['deficit_minutes'] = finalData[i].deficit_minutes;
          attData[currentDate - 1]['attendance_status'] = finalData[i].attendance_status;
          attData[currentDate - 1]['isMissedPunch'] = false;
          if (finalData[i]?.['exception'] !== null) {
            attData[currentDate - 1]['remarks'] = finalData[i]?.['exception']?.[finalData[i]['exception'].length - 1]?.['description'];
          }
          attData[currentDate - 1]['attendanceData'] = finalData[i];
        }
        if (finalData.length > 0) {
          attData['employees'] = finalData[0]['employees_id'][0];
        }
        attData['gridDataSource'] = gridDataSource;
        attData['currEmployee'] = employee;
        attData['attStatus'] = attendanceSummary?.attStatus;
      } else {
        attData = finalData.reduce((att, dataForm) => {
          const employeeId = dataForm?.employees_id?.[0]?.id;
          const existingData = att.find((item) => item?.employees?.[0].id === employeeId);
          if (existingData) {
            const retVal = getFormattedDateField(dataForm.attendance_date);
            existingData[retVal] = dataGenerator(dataForm)[retVal];
          } else {
            const newData = dataGenerator(dataForm);
            newData.employees = dataForm.employees_id;
            newData.employees.dailyCount = attendanceSummary.dailyCount;
            att.push(newData);
          }
          return att;
        }, []);
      }
      console.log('ATTDATA', attData);
      if (registerView) {
        return attData;
      }
      const { quickSort } = attendanceGridUtils();
      const sortedRow = quickSort(attData, empSortField, empSortOrder);
      console.timeEnd('test1');
      if (sortModel.length !== 0 && sortModel[0].sort !== null) {
        if (sortModel[0].sort === 'desc') {
          sortedRow.reverse();
        }
      }
      return sortedRow;
    }
    catch (e) {
      console.log('error is log')
      toast.error(e)
    }
  }

  function dataGenerator(dataForm) {
    const retVal = getFormattedDateField(dataForm.attendance_date);
    return {
      [retVal]: {
        id: dataForm.id,
        attendance_date: dataForm.attendance_date,
        sites_id: dataForm.sites_id,
        shifts_id: dataForm.shifts_id,
        att_type: dataForm.att_type,
        designation: dataForm.designation,
        working_minutes: dataForm.working_minutes,
        overtime_minutes: dataForm.overtime_minutes,
        exception: dataForm.exception,
        allocations_id: dataForm.allocations_id,
        attendance_status: dataForm.attendance_status,
        in_time: dataForm.observed_in_tmstmp,
        out_time: dataForm.observed_out_tmstmp,
        last_processed_by: dataForm.last_processed_by,
        leave_type: dataForm.leave_type,
        deficit_minutes: dataForm.deficit_minutes
      }
    };
  }

  function getFormattedDateField(date) {
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + 5);
    formattedDate.setMinutes(formattedDate.getMinutes() + 30);
    const day = formattedDate.getDate();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[formattedDate.getMonth()];
    const year = formattedDate.getFullYear();
    return `${day}${month}${year}`;
  }

  function getDate(date) {
    const formattedDate = new Date(date);
    formattedDate.setHours(formattedDate.getHours() + 5);
    formattedDate.setMinutes(formattedDate.getMinutes() + 30);
    const day = formattedDate.getDate();
    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // const month = months[formattedDate.getMonth()];
    // const year = formattedDate.getFullYear();
    return day;
  }
  return { attendanceList, dataCreation, getFormattedDateField };
};

export default dataProcessor;
