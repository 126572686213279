const userModule = {
  namespaced: true,
  state: {
    isLoggedIn : localStorage.getItem('isLoggedIn'),
    username: localStorage.getItem('username'),
    swHandle: localStorage.getItem('swHandle'),
    selectedPartner:localStorage.getItem('swHandle')
  },
  getters: {
    isLoggedIn: function(state) {
      if (state.isLoggedIn === 'true') {
        return true;
      }
      else {
        return false;
      }
    },
    username: function(state) {
      return state.username
    },
    swHandle: function(state) {
      return state.swHandle
    },
    selectedPartner: function(state) {
      return state.selectedPartner
    }
  },
  mutations: {
    isLoggedInMutation(state, value) {
      localStorage.setItem('isLoggedIn', value);
    },
    usernameMutation(state, value) {
      localStorage.setItem('username', value)
      state.username = value
    },
    swHandleMutation(state, value) {
      localStorage.setItem('swHandle', value)
      state.swHandle = value
    },
    selectedPartnerMutation(state, value) {
      state.selectedPartner = value
    }
  },
  actions: {
    swHandleSetter(context, value) {
      context.commit('swHandleMutation', value)
    },
    usernameSetter(context, value) {
      context.commit('usernameMutation', value)

    },
    isLoggedInSetter(context, value) {
      context.commit('isLoggedInMutation', value);
    },
    async selectedPartnerSetter(context, value) {
      await context.commit('selectedPartnerMutation', value)
      //setting the selected partner in bSettings env
      const parnertsList = context.rootGetters['commonModule/partnersListGetter']
      const selectedPartner = parnertsList.filter((each) => each.code == value)
      context.rootState.bSettings.env.user_selected_partner = selectedPartner;

    },
  }
}

export default userModule;
