<template>
  <div class="businesses-filter">
    <WfmControl
      :field-options="fieldOptions"
      :data="defaultData"
      :current-folder="folder"
    />
  </div>
</template>

<script>
import { computed, provide, ref, watch, onMounted} from 'vue'
import WfmControl from './form-controls/wfm-control.vue';
import { useStore } from 'vuex'
import { keys } from '../provideKeys';
import usePartnersId from '../composables/usePartnersId';
export default {
  name: 'WfmBusinessFilter',
  components:{
    WfmControl
  },
  props:{
    folder:{
      type:Object,
      default:null
    }
  },
  emits:['apply-businesses-filter'],
  setup(props, context) {
    const formData = ref({})
    const store = useStore()
    const {getCurrentSelectedPartnersId} = usePartnersId()

    provide(keys.formData, formData)
    const swhandle = computed(() => {
      return store.getters['userModule/swHandle']
    })
    const defaultData = computed(() => {
      return {
        'partners_id':[{'code':store.getters['userModule/selectedPartner']}]
      }
    })
    const fieldOptions = computed(() => {
      //self defined fields to show partners list
      return {
        type:'docpicker',
        name:'partners_id',
        label:{eng:'Business Filter'},
        options:getPartnersList(),
        default_value:swhandle.value,
        fieldOnlyView:'true',
        placeHolder:'filter on business',
        userDefined:true,
        isEditNotAllowed:false
      }
    })
    function getPartnersList() {
      //partners list as stored in vuex
      return store.getters['commonModule/partnersListGetter']
    }
    watch(formData.value, (newValue) => {
      try {
        applyFilter(newValue)
      } catch (error) {
        console.log(error)
      }
    });
    function applyFilter(data) {
      const filterDataToSend = []
      if (data != null) {
        for (var item in data) {
          filterDataToSend.push({
            key:item,
            operator:'=',
            value:data[item]?.value?.[0]?.id || getCurrentSelectedPartnersId()
          })

        }
      }
      store.dispatch('userModule/selectedPartnerSetter', data['partners_id']?.value?.[0]?.code);

      context.emit('apply-businesses-filter', filterDataToSend)

    }
    onMounted(() => {
      const defaultFilter = [{
        key:'partners_id',
        operator:'=',
        value:getCurrentSelectedPartnersId()
      }]
      context.emit('apply-businesses-filter', defaultFilter)


    })
    return {
      fieldOptions,
      defaultData
    }
  }
}


</script>

<style>
.businesses-filter {
  width: 200px;
  background-color: none;
}

.businesses-filter input{
    color:black !important;
}

.businesses-filter .vs__dropdown-toggle {
  border: none;
  height: 30px !important;
  padding-bottom:2px;
  /* color:black !important; */
}

.businesses-filter .vs__open-indicator{
    fill:black !important;
}

.businesses-filter .v-select {
  border: none;
  color:black !important;

}


</style>
