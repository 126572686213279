<template>
  <div
    class="d-flex"
    :data-test-id="dataTestId"
  >
    <div
      v-for="(item,index) in dateFields"
      :key="index"
      class="ms-1 me-1"
    >
      <WfmControl
        class="control-class"
        :field-options="item"
        :current-folder="folder"
      />
    </div>
  </div>
</template>

<script>
import { computed, provide, ref, watch} from 'vue';
import { useStore } from 'vuex'
import getFormInfo from '../composables/getFormInfo';
import getFieldOptions from '../composables/getFieldOptions';
import WfmControl from './form-controls/wfm-control.vue';
import { keys } from '../provideKeys';
import getConverters from '../composables/getConverters';
export default {
  name: 'WfmListDateFilters',
  components:{
    WfmControl
  },
  props: {
    folder:{
      type:Object,
      default:null
    }
  },
  emits: ['apply-filters'],
  setup(props, { emit }) {
    const store = useStore()
    const bSettings = store.getters['bSettings'];
    const { fieldsData } = getFieldOptions()
    const { getFormObject, getCurrentTxn } = getFormInfo()
    const formData = ref({})
    provide(keys.formData, formData)
    provide(keys.currentTask, 'create')
    const listFormObject = computed(() => {
      //get the current txn object depending on the txntype
      //get the form object that is needed
      const currentTxn = getCurrentTxn('list', props.folder)
      const formName = currentTxn?.form?.form_name ? currentTxn.form?.form_name : currentTxn?.form
      return getFormObject(formName, props.folder)
    })
    watch(formData.value, (newValue) => {
      //for now auto apply when only one filter is needed
      try {
        applyFilter(newValue)
      } catch (error) {
        console.log(error)
      }


    });
    const dataTestId = computed(() => {
      return `${props.folder.name}/date_filter`
    })
    const dateFields = computed(() => {
      const cloneOfFolder = JSON.parse(JSON.stringify(props.folder));
      const retVal = listFormObject.value?.map((each) => {
        return fieldsData(each.path, cloneOfFolder, bSettings.output.data.records[0], each)
      })
      return retVal?.filter((each) => {
        if (each) {
          each.fieldOnlyView = true
          return ['timestamp', 'tsrange', 'shift_range'].includes(each.type)

        }
      })
    })

    function applyFilter(data) {
      const filterDataToSend = []
      for (const key in data) {
        const fieldType = data[key].fieldType
        const fieldValue = data[key].value
        let filterValue = null
        let operator = ''
        if (fieldValue != null) {
          if (fieldType === 'tsrange') {
          //if the date field is tsrange prepare value to use
            const { SQLTsRangeToArray } = getConverters();
            filterValue = SQLTsRangeToArray(fieldValue, 'UTC', true)
            operator = 'between'
          }
          else if (fieldType === 'timestamp') {
          //if the date field is timestamp prepare value to use

            //current date entered and next date
            const dateEntered = new Date(fieldValue);
            const nextDay = new Date(fieldValue);
            //setting the hours to 0
            dateEntered.setDate(dateEntered.getDate() - 1);

            dateEntered.setUTCHours(0, 0, 0, 0)
            nextDay.setDate(nextDay.getDate() + 2);
            nextDay.setUTCHours(0, 0, 0, 0);

            const utcEnteredDate = new Date(dateEntered.toISOString())
            const utcNextDate = new Date(nextDay.toISOString())
            //date shifting for utc timezone (-5 hours and 30 minutes)
            utcEnteredDate.setHours(utcEnteredDate.getHours() - 5);
            utcEnteredDate.setMinutes(utcEnteredDate.getMinutes() - 30);

            utcNextDate.setHours(utcEnteredDate.getHours() - 5);
            utcNextDate.setMinutes(utcEnteredDate.getMinutes() - 30);

            filterValue = [utcEnteredDate, utcNextDate]
            operator = 'between'

          }
          else if (fieldType === 'shift_range') {
            filterValue = fieldValue
            operator = '='
          }
          else {
            filterValue = null
          }

          filterDataToSend.push({key:key, value:filterValue, operator:operator})

        }
        emit('apply-filters', filterDataToSend)

      }

    }
    //Hooks

    return {
      dateFields,
      applyFilter,
      dataTestId
    };
  },
};
</script>

<style>
.control-class{
  width: 200px !important;
}

</style>
