<template>
  <div>
    <div
      class="dropdown-editor"
      :style="{padding: visibleExpr ? '5px' : '0', justifyContent: visibleExpr ? 'space-evenly' : 'space-between', height: visibleExpr ? '150px' : 'auto'}"
      @click="handler"
    >
      <div class="form_docpicker">
        <v-select
          ref="inputText1"
          v-model="selected_value"
          append-to-body
          :calculate-position="manualPosition"
          style="text-overflow: ellipsis; width: 200px"
          label="name"
          :options="dataSource[editKey]"
          clear-search-on-select
          :map-keydown="createKeydownHandler(0)"
          @search:blur="closed"
        >
          <template #option="option">
            <div
              @mouseover="setHovered(option)"
            >
              <span
                :class="{'selected_option':selected_value && (selected_value === option.name),}"
              >
                {{ option.name }}
              </span>
            </div>
          </template>
        </v-select>
      </div>
      <div
        v-if="visibleExpr"
        class="form_docpicker"
      >
        <v-select
          ref="inputText2"
          v-model="selected_leave_type"
          style="text-overflow: ellipsis; width: 200px"
          label="name"
          append-to-body
          :calculate-position="manualPosition"
          :options="dataSource.leave_types"
          clear-search-on-select
          :map-keydown="createKeydownHandler(1)"
          :selectable="isSelectable"
          @search:blur="closed"
        >
          <template #option="option">
            <div
              @mouseover="setHovered(option)"
            >
              <span
                :class="{'selected_option':selected_leave_type && (selected_leave_type === option.name)}"
              >
                {{ option.name }}
              </span>
            </div>
          </template>
        </v-select>
      </div>
      <div
        v-if="visibleExpr"
        class="form_docpicker"
      >
        <label
          for="date"
          :style="{'padding': '2px', 'font-size': '15px'}"
        > In lieu of</label>
        <input
          id="input1"
          ref="input1"
          v-model="date"
          :disabled="readOnly"
          type="date"
          class="my-simple-editor form-control form-control-sm"
          tabindex="1"
          @keydown="(event) => handleKeydown(2, event)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, nextTick, onUnmounted} from 'vue';
import { useStore } from 'vuex';
import { toast } from 'vue3-toastify';
import getConverters from '../../../../composables/getConverters';
export default {
  name: 'DropdownCellEditor',
  setup(props) {
    const store = useStore();
    const value = ref('value' in props.params ? JSON.parse(JSON.stringify(props.params.value)) : undefined);
    const inputText1 = ref(null);
    const inputText2 = ref(null);
    const input1 = ref(null);
    const editKey = props.params?.key;
    let isHovered = null;

    const date = ref(null);

    const selected_value = ref();
    const default_value = computed(() => {
      if (props.params.data !== undefined && props.params.data !== null && props.params.data[editKey] !== undefined && props.params.data[editKey] !== null) {
        return {name: props.params.data[editKey][0]['name']['eng'] !== undefined ? props.params.data[editKey][0]['name']['eng'] : props.params.data[editKey][0]['name'], id: props.params.data[editKey][0].id, code: props.params.data[editKey][0].code};
      } else {
        return 'Select';
      }
    })
    selected_value.value = default_value.value;
    const dataSource = ref({});
    if (editKey != undefined && editKey !== null) {
      dataSource.value[editKey] = props.params?.dataSource?.[editKey].map(
        (item) => ({ name: item['name']['eng'], id: item.id, code: item.code }
        ));
    }

    const selected_leave_type = ref();
    const default_leave_type = computed(() => {
      if (props.params.data !== undefined && props.params.data !== null && props.params.data['attendanceData'] !== undefined && props.params.data['attendanceData'] !== null && props.params.data['attendanceData']['leave_type'] !== undefined && props.params.data['attendanceData']['leave_type'] !== null && props.params.data['attendanceData']['leave_type'].length !== 0) {
        return {name: props.params.data['attendanceData']['leave_type'][0]['name']['eng'] !== undefined ? props.params.data['attendanceData']['leave_type'][0]['name']['eng'] : props.params.data['attendanceData']['leave_type'][0]['name'], id: props.params.data['attendanceData']['leave_type'][0].id, code: props.params.data['attendanceData']['leave_type'][0].code};
      } else {
        return 'Select leave type';
      }
    })

    selected_leave_type.value = default_leave_type.value;
    const bSettings = store.getters['bSettings'];
    const folderData = bSettings?.output?.data?.records?.[0]?.folderData;
    const leavesDataSource = folderData?.leaveTypes;
    const leavesQuota = store.getters['commonModule/leavesQuotaGetters'];
    let leavesList = [];
    if (leavesQuota !== null && leavesQuota !== undefined) {
      // console.log('leaves quota is', leavesQuota.value)
      //if leavequota result is out match the code of the leaves and add to docpiker list to show in dropdown
      const docPickerList = []
      if (leavesQuota.status != 'error') {
        const applicableLeaves =
          leavesQuota?.applicable_leave_types
        for (var i = 0; i < leavesDataSource?.length; i++) {
          for (var j = 0; j < applicableLeaves?.length; j++) {
            if (
              applicableLeaves[j].code === leavesDataSource[i].code && !applicableLeaves?.[j]?.mandatory_document_upload
            ) {
              leavesDataSource[i].mandatory_document_upload =
                applicableLeaves[j].mandatory_document_upload
              leavesDataSource[i].allow_half_day =
                applicableLeaves[j].allow_half_day
              docPickerList.push(leavesDataSource[i])
            }
          }
        }
      }
      if (docPickerList.length > 0) {
        leavesList = docPickerList;
      } else {
        leavesList = leavesDataSource;
      }

    }
    dataSource.value['leave_types'] = leavesList.map(
      (item) => ({ name: item['name'].replace(/[(""),]/g, '').trim(), id: item.id, code: item.code }
      ));

    const visibleExpr = computed(() => {
      return selected_value?.value?.name === 'Leave';
    });

    const readOnly = computed(() => {
      return selected_leave_type?.value?.name !== 'Compensatory Off';
    });

    const isPopup = () => {
      return true;
    };


    const getValue = () => {
      if (editKey === 'att_type') {
        if (selected_value.value === undefined || selected_value.value === null) {
          return value.value;
        }
        if (selected_value.value?.name !== value.value) {
          if (selected_value.value?.name === 'Leave') {
            if (selected_leave_type.value === 'Select leave type' || selected_leave_type.value === null) {
              selected_value.value = null;
              toast.warning('Please select Leave type', {
                autoClose: 4000,
                closeButton: true,
                isLoading: false,
                position: toast.POSITION.TOP_CENTER,
              })
              return value.value;
            }
            if (selected_leave_type.value.name === 'Compensatory Off') {
              if (date.value !== null) {
                const comOffDate = new Date(date.value)
                const {snapDate} = getConverters()
                const snapedDate = snapDate(comOffDate)
                const note = new Date(snapedDate).toISOString()

                return [selected_value.value, selected_leave_type.value, note]
              } else {
                toast.warning('Please select date', {
                  autoClose: 4000,
                  closeButton: true,
                  isLoading: false,
                  position: toast.POSITION.TOP_CENTER,
                })
                return value.value
              }
            }
            return [selected_value.value, selected_leave_type.value]
          }
          return selected_value.value;
        } else {
          return value.value
        }
      }
      if (editKey === 'sites_id') {
        if (selected_value.value.name !== value.value) {
          return selected_value.value;
        } else {
          return value.value
        }
      }
      return value.value;
    }

    const isCancelBeforeStart = () => {
      return false;
    };

    const isCancelAfterEnd = () => {
      return false;
    };

    onMounted(async() => {
      nextTick(() => {
        if (inputText1.value) {
          inputText1.value.$el.querySelector('input').focus();
        }
      })
    });

    const setFocus = () => {
      const { api, column, rowIndex } = props.params;
      const nextRowIndex = rowIndex + 1; // Calculate the next row index

      if (api && column) {
        api.setFocusedCell(nextRowIndex, column.getId()); // Focus the cell in the same column on the next row
      }
    };

    onUnmounted(() => {
      setFocus();
    });

    const focusNext = async(e, currentIndex) => {
      const refList = [inputText1, inputText2, input1];

      // If the key pressed is 'Enter' or 'Tab', proceed to the next focusable element
      if ((e && e.key === 'Tab') || (e.key === 'Enter' && e.ctrlKey !== true)) {
        e.preventDefault(); // Prevent default behavior

        // Determine direction: forward or backward based on Shift key
        const isShiftPressed = e.shiftKey;
        const nextIndex = isShiftPressed
          ? (currentIndex - 1 + refList.length) % refList.length // move to previous
          : (currentIndex + 1) % refList.length; // move to next

        const nextElement = refList[nextIndex].value;
        if (isHovered !== null && isHovered?.name === selected_value.value?.name) {
          selected_value.value = null;
          isHovered = null;
        }
        // Check conditions to stop editing
        if (selected_value.value?.name !== 'Leave') {
          // If selected_value is not 'Leave', stop editing
          stopEditing();
          return;
        } else if (
          selected_value.value?.name === 'Leave' &&
          ((selected_leave_type.value === 'Select leave type' || selected_leave_type.value?.name === 'Compensatory Off') && date.value === null)
        ) {
          // If selected_value is 'Leave' and leave type is not selected, continue editing
          await nextTick();
          if (nextElement && nextElement.$el) {
            const inputEl = nextElement.$el.querySelector('input');
            if (inputEl) {
              inputEl.focus();
            } else {
              nextElement.focus();
            }
          } else if (nextElement) {
            nextElement.focus();
          }
        } else if (
          selected_value.value?.name === 'Leave' &&
          selected_leave_type.value !== 'Select leave type'
        ) {
          // If selected_value is 'Leave' and a valid leave type is selected, stop editing
          stopEditing();
          return;
        }
      }
    };

    // Function to stop editing
    const stopEditing = () => {
      // Example: stopping the editing in grid or closing modal
      props.params.api.stopEditing();  // Use your grid/api stop editing logic here
    };


    const createKeydownHandler = (currentIndex) => {
      return (map, vm) => ({
        ...map,
        9: async(e) => { // Tab key
          e.preventDefault();
          e.stopPropagation();
          await focusNext(e, currentIndex);
        },
        13: async(e) => { // Enter key
          e.stopPropagation();
          vm.typeAheadSelect(); // Ensure selection
          // Select the highlighted option if Enter key is pressed
          await nextTick();
          await focusNext(e, currentIndex);
        }
      });
    };


    function setHovered(value) {
      if (value && value.name !== '') {
        isHovered = JSON.parse(JSON.stringify(value));
      } else {
        console.warn('Hovered value is invalid:', value);
      }
    }

    const handleKeydown = async(currentIndex, e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();
        await focusNext(e, currentIndex);
      } else if (e.key === 'Enter') {
        e.stopPropagation();
        // vm.typeAheadSelect(); // Ensure selection
        await nextTick();
        await focusNext(e, currentIndex);
      }
    };

    const manualPosition = (dropdownList, component, { width }) => {
      dropdownList.style.width = width;

      const toggleButton = component.$refs.toggle;
      const rect = toggleButton.getBoundingClientRect();
      const dropdownHeight = dropdownList.offsetHeight;

      const spaceAbove = rect.top; // Space available above the toggle button
      const spaceBelow = window.innerHeight - rect.bottom; // Space available below the toggle button

      // Determine where to position the dropdown
      if (spaceBelow < dropdownHeight && spaceAbove >= dropdownHeight) {
        // Position above if there's enough space and below isn't enough
        dropdownList.style.top = `${rect.top - dropdownHeight - 1}px`;
        dropdownList.style.left = `${rect.left}px`;
        component.$el.classList.add('drop-up');
      } else {
        // Position below by default
        dropdownList.style.top = `${rect.bottom + 1}px`;
        dropdownList.style.left = `${rect.left}px`;
        component.$el.classList.remove('drop-up');
      }
    };

    async function closed() {
      if (editKey === 'att_type') {
        if (selected_value.value?.name !== 'Leave') {
          // If selected_value is not 'Leave', stop editing
          stopEditing();
          return;
        } else if (
          selected_value.value?.name === 'Leave' &&
          ((selected_leave_type.value === 'Select leave type' || selected_leave_type.value?.name === 'Compensatory Off') && date.value === null)
        ) {
          // If selected_value is 'Leave' and leave type is not selected, continue editing
          await nextTick();
          if (selected_leave_type.value?.name === 'Compensatory Off') {
            document.getElementById('input1').focus();
          } else {
            if (inputText2.value) {
              inputText2.value.$el.querySelector('input').focus();
            }
          }
        } else if (
          selected_value.value?.name === 'Leave' &&
          selected_leave_type.value !== 'Select leave type'
        ) {
          // If selected_value is 'Leave' and a valid leave type is selected, stop editing
          stopEditing();
          return;
        }
      } else {
        stopEditing();
      }
    }

    return {
      closed,
      visibleExpr,
      readOnly,
      inputText1,
      inputText2,
      input1,
      date,
      dataSource,
      selected_value,
      default_value,
      selected_leave_type,
      default_leave_type,
      editKey,
      createKeydownHandler,
      handleKeydown,
      manualPosition,
      isHovered,
      setHovered,

      isPopup,
      getValue,
      isCancelAfterEnd,
      isCancelBeforeStart,

    };
  }
}
</script>

  <style>
  .dropdown-editor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.dropdown-editor span {
    font-size:15px !important;
    margin: 0 !important;
  }

.form_docpicker {
  width: 100% !important;
}

.drop-up {
  z-index: 1000; /* Ensure dropdown appears above other elements */
  /* You can also add styles like a shadow or border to differentiate it */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Example shadow */
  border: 1px solid #ccc; /* Example border */
}

.hovered {
  background-color: #b82121; /* Or any style you prefer */
}
  </style>
