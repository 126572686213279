<template>
  <div
    v-if="dataReady"
    class="mx-3"
  >
    <WfmTabsHorizontal
      :key="tabKey"
      :tabs-list="tabsList"
      :selected-index="selectedIndex"
      :handle-click="handleClick"
      @add-tab="addTab"
      @del-tab="delTab"
      @change-tab="changeTab"
    />
  </div>
</template>
<script>
import WfmTabsHorizontal from '../../common/wfm-tabs-horizontal.vue';
import { ref, computed, onMounted} from 'vue';
import { useStore } from 'vuex';
import getFolders from '../../composables/getFolders';
import postData from '../../composables/postData';
import getFatRow from '../../composables/fatRowGenerator';
import getFormInfo from '../../composables/getFormInfo';

export default {
  name: 'ProfileInfo',
  components: {
    WfmTabsHorizontal,
  },
  setup(props) {
    const store = useStore();
    const { listData } = postData();
    const dataReady = ref(false)
    const { getFormObject, getCurrentTxn } = getFormInfo();
    const tabKey = ref(0);
    const userData = ref(null)
    const selectedIndex = ref(0)

    // const formData = computed(() => {
    //   return store.getters["commonModule/formData"];
    // });

    const bSettings = computed(() => {
      return store.getters['bSettings'];
    });
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const folderList = getAllFoldersList(bSettings.value);

    // Employees folder
    const employeesFolder = computed(() => {
      const currentFolder = getCurrentFolder('employees', folderList);
      return currentFolder;
    });
    const currentTxn = computed(() => {
      return getCurrentTxn('update', employeesFolder.value);
    })

    //list of details in the form


    const refBy = computed(() => {
      const result = {}
      // result['resetPassword'] = []
      // result['resetPassword'].push({
      //   label: 'Reset PassWord',
      //   component: 'ResetPassword',
      //   name:'resetPassword',
      //   params: {
      //     readOnly:false,
      //   }
      // })
      return result
    })
    const baseParams = computed(() => {
      return {
        rowData: userData.value,
        fieldsToDisplay:formObject.value,
        formObjectFound: true,
        folder:employeesFolder.value,
        txnToUse :currentTxn.value,
        refBy: refBy.value,
        readOnly: false,
        currentTask:'update',
        agGridApi:null
      }
    })
    const formObject = computed(() => {
      const formName = currentTxn.value?.form?.form_name
        ? currentTxn.value?.form?.form_name
        : currentTxn.value?.form;
      return getFormObject(formName, employeesFolder.value);
    });

    const tabsList = computed(() => {
      return  [
        {
          label: 'Update Profile',
          component: 'FolderForm',
          name: 'updateProfile',
          params: baseParams.value,
        }
      ]
    })
    //methods
    function changeTab(index) {
      selectedIndex.value = index
    }
    function touchTabKey() {
      tabKey.value = Math.floor((Math.random() * 10000) + 1);
    }

    async function getProfileInfo() {
      const txnParams = {
        refRows: true,
      };
      const filters = {
        joinop: 'or',
        filterInput: [
          {
            operator: 'iLike',
            path: 'employees.code',
            value: bSettings.value.env.code,
          },
        ],
      };
      txnParams.filters = filters;
      const list = await listData(employeesFolder.value, txnParams);
      const { fatRowGenerator } = getFatRow();
      const fatRow = fatRowGenerator(list);
      const retVal = fatRow[0];
      if (retVal?.registration_image?.[0]?.filepath) {
        localStorage.setItem('profilePic', retVal?.registration_image?.[0]?.filepath);
      }
      return retVal
    }

    function addTab(tabSpec) {
      const idx = tabsList.value.findIndex((obj) => obj.label === tabSpec.label)
      if (idx < 0) {
        tabsList.value.push(tabSpec)
        selectedIndex.value = tabsList.value.length - 1

      }
      else {
        tabsList.value[idx].component = tabSpec.component
        tabsList.value[idx].params = tabSpec.params
        selectedIndex.value = idx

      }

    }

    function delTab(index) {
      tabsList.value.splice(index, 1)
      touchTabKey()
    }

    onMounted(async() => {
      userData.value = await getProfileInfo();
      dataReady.value = true
    });

    return {
      tabsList,
      addTab,
      delTab,
      tabKey,
      changeTab,
      dataReady,
      selectedIndex,

    };
  }
};
</script>


<style scoped>

</style>
