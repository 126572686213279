<template>
  <div
    v-for="(item,index) in quickActionsLit"
    :key="index"
  >
    <div
      class="mt-1 folders"
      @click="openBulkGenerate(item)"
    >
      <v-icon
        name="fa-receipt"
        scale="1.3"
      />
      <span class="task-name ms-1">
        {{ item.label }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import getFolders from '../../../composables/getFolders';

export default {
  name: 'ActionsTab',
  emits: ['hide-sidebar'],
  setup(props, context) {
    //variables
    const router = useRouter();
    const store = useStore();
    const quickActionsLit = ref([
      {name:'payslips/generate', label:'Payslips Generate', folder:'payslips'},
      {name:'invoices/generate', label:'Invoices Generate', folder:'invoices'}
    ])
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const bSettings = store.getters['bSettings'];
    const folderList = getAllFoldersList(bSettings);
    const payslipFolder = getCurrentFolder('payslips', folderList);
    const showGeneratePayslip = ref(payslipFolder?.visibleExpr);

    //Methods
    function openBulkGenerate(item) {
      router.push({ name: 'tabs', params: { name:item.name } });
      context.emit('hide-sidebar')
    }

    return {
      showGeneratePayslip,
      quickActionsLit,
      openBulkGenerate
    }
  }
}
</script>

<style scoped>

.task-name {
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: capitalize;
}

.folders {
  background-color: var(--folder-background);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  font-size: 1rem;
  margin: 10px;
  font-weight: 700;
  border-radius: 10px;
  color: var(--main-color);
  border-left:2px solid var(--main-color);
  box-shadow: 0px 10px 20px -6px var(--folder-shadow);
}

.folders:hover {
  transform: scale(1.02);
  border-radius: 0px;
  padding-left: 40px;
  border-radius: 10px;
  color:white;


}

</style>
