<template>
  <div class="container">
    <div class="data">
      <div
        v-for="value in values()"
        :key="value.code"
      >
        {{ value.first_name }}
      </div>
    </div>
    <div class="select-box">
      <v-select
        v-model="selected"
        :placeholder="placeHolder"
        :options="docpLabels"
        :items="docpLabels"
        style="height: 20px; width: 100%;"
        @mousedown="onMouseDown"
        @keydown="onKeyDown"
        @input="searchDocp"
      >
        <template #no-options="{ search, searching }">
          <em
            v-if="searching && loading"
            style="opacity: 0.5"
          >
            searching for {{ search }}
          </em>
          <em v-else />
        </template>
        <template #spinner>
          <span
            v-if="loading"
            class="spinner-border spinner-border-sm text-info"
            role="status"
            aria-hidden="true"
          />
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, computed } from 'vue';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useStore } from 'vuex';
import makeFilter from '../composables/makeFilter';
import postData from '../composables/postData';
import getFatRow from '../composables/fatRowGenerator.js';
import getFolders from '../composables/getFolders';

export default {
  components: { vSelect },
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {
    const store = useStore();
    const globalBaseData = store.state.dutyCharts;
    // const globalSet = JSON.parse(JSON.stringify(store.state.selectedEmps));
    const selected = ref([]);
    const options = ref([]);
    const searchTerm = ref('');
    let filterParams = reactive([]);
    const loading = ref(false)
    const bSettings = ref(store.getters['bSettings'])
    const { getAllFoldersList, getCurrentFolder } = getFolders();

    const placeHolder = computed(() => 'Search Programs');
    const docpLabels = computed(() => {
      const ret = options.value.map((r) => {
        if (r.label == null) {
          r.label = `${r.name} (${r.code})`;
        }
        return r;
      });
      return ret;
    });

    function markSelectedOption(option) {
      return selected.value.some((item) => item.code === option.code); // Check if option is selected
    }

    async function getDocpickerList() {
      try {
        if (Object.keys(filterParams).length !== 0) {
          const folderList = getAllFoldersList(bSettings.value);
          const currentDocpFolder = getCurrentFolder('tasks', folderList);
          const { listData } = postData();
          const txnParams = { refRows: false };
          txnParams.filters = filterParams;
          txnParams.filters.filterInput.push({
            'path': 'tasks.scheduled',
            'operator': '=',
            'value': 'false'
          });

          if (bSettings.value.env.isDCO != null && bSettings.value.env.isDCO == true) {
            txnParams.filters.filterInput.push({
              'path': 'tasks.subsections_id.id',
              'operator': '=',
              'value': bSettings.value.env.subsections.id
            });
          }

          txnParams.projections = {
            'tasks': ['id', 'name', 'code', 'time_slot'],
          }
          const list = await listData(currentDocpFolder, txnParams);
          if (list.output.type === 'error') {
            console.log(list.output.message);
          }
          else {
            if (list.output?.data?.records?.length > 0) {
              const { fatRowGenerator } = getFatRow();
              const fatRows = fatRowGenerator(list);
              options.value = fatRows.map((item) => ({ id: item.id, name: item.name, code: item.code, time_slot: item.time_slot }));
            }
          }
          loading.value = false;
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    onMounted(async() => {
      await nextTick();
      await getDocpickerList();
      const multiselect = document.getElementsByClassName('vs__search');
      if (multiselect.length > 0) {
        multiselect[multiselect.length - 1].focus();
      }
    });

    async function searchDocp(event) {
      try {
        searchTerm.value = event.target.value;
        if (searchTerm.value.length >= 2) {
          const filterFields = [
            { key:'code' },
            { key:'name' },
          ];
          const folderList = getAllFoldersList(bSettings.value);
          const tasksFolder = getCurrentFolder('tasks', folderList);
          const { generateFilterParams } = makeFilter(
            searchTerm.value,
            tasksFolder,
            filterFields
          );
          filterParams = generateFilterParams();
          loading.value = true;
          await getDocpickerList();
        }
      }
      catch (err) {
        console.log(err)
      }
    }

    function onMouseDown(event) {
      // Keep the focus on the grid cell after the dropdown closes
      if (event.target.textContent != '') {
        setTimeout(() => {
          const cellElement = props.params.eGridCell;
          if (cellElement) {
            cellElement.focus();
          }
        }, 200);
      }
    }

    function onKeyDown(event) {
      if (event.key === 'Enter') {
        // Keep the focus on the grid cell after the dropdown closes
        nextTick(() => {
          const cellElement = props.params.eGridCell;
          if (cellElement) {
            cellElement.focus();
          }
        });
      }
    }

    const getValue = () => {
      const ret = null;
      if (selected.value != null && selected.value != '') {
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1) {
          globalBaseData[fIndex][props.params.colDef.field] = [selected.value];
          store.commit('writeDutyChartsData', globalBaseData);
          setTimeout(() => {
            props.params.api.resetRowHeights();
          }, 0);
        }
      }
      return ret;
    };

    const values = () => {
      let ret = [];
      const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
      if (fIndex > -1 && globalBaseData[fIndex][props.params.colDef.field] != null) {
        ret = globalBaseData[fIndex][props.params.colDef.field];
      }
      return ret;
    }

    return {
      values,
      selected,
      docpLabels,
      markSelectedOption,
      placeHolder,
      searchDocp,
      loading,
      getValue,
      onMouseDown,
      onKeyDown
    };
  },
};
</script>

<style scoped>
.container{
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
}

.select-box{
  position: absolute;
  bottom: 0;
  width: 100%;
}

.selected_option {
  font-weight: 700;
  color: green;
}
.selected_option:hover {
  color: white;
}
</style>
