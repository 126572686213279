import { v4 as uuidv4 } from 'uuid';
import { toast } from 'vue3-toastify';
import attendanceGridUtils from './attendanceGridUtils';
import store from '../../store/index';
import getFolders from './getFolders';
import processFormData from './processFormData';
import makeTxn from './makeTxn';
import postData from './postData';
import getFatRow from './fatRowGenerator';
import { sendMessage } from '../services/websocket'

const allocationGridUtils = () => {

  function createTimeRange(monthYear) {
    const month = monthYear.month;
    let prevMonth = null;
    const startDay = monthYear.day;
    let year = monthYear.year;
    let endDay = new Date(year, month, 0).getDate();
    if (startDay !== 1) {
      endDay = startDay - 1;
      prevMonth = month - 1;
      if (prevMonth === -1) {
        prevMonth = 11;
        year--;
      }
    }
    // Create a Date object with the specified date and time
    let timestamp = new Date(Date.UTC(year, prevMonth !== null ? prevMonth - 1 : month - 1, startDay, 0, 0, 0));
    let timestamp2 = new Date(Date.UTC(year, month - 1, endDay, 23, 59, 59));
    let startOfWeek = null;
    let endOfWeek = null;
    let startMonth = null;
    let endMonth = null;
    let startYear = null;
    let endYear = null;
    if (monthYear.day === null) {
      startOfWeek = monthYear.weekRange.startOfWeek;
      endOfWeek = monthYear.weekRange.endOfWeek;
      startMonth = monthYear.weekRange.startMonth;
      endMonth = monthYear.weekRange.endMonth;
      startYear = monthYear.weekRange.startYear;
      endYear = monthYear.weekRange.endYear;
    }
    timestamp = new Date(Date.UTC(startYear, startMonth, startOfWeek, 0, 0, 0));
    timestamp2 = new Date(Date.UTC(endYear, endMonth, endOfWeek, 0, 0, 0));
    // Format the timestamp
    const formattedTimestamp = timestamp
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate1 = new Date(formattedTimestamp);

    // Subtract 5 hours and 30 minutes
    inputDate1.setHours(inputDate1.getHours() - 5);
    inputDate1.setMinutes(inputDate1.getMinutes() - 31);
    const year1 = inputDate1.getFullYear();
    const month1 = String(inputDate1.getMonth() + 1).padStart(2, '0');
    const day1 = String(inputDate1.getDate()).padStart(2, '0');
    const hours1 = String(inputDate1.getHours()).padStart(2, '0');
    const minutes1 = String(inputDate1.getMinutes()).padStart(2, '0');
    const seconds1 = String(inputDate1.getSeconds()).padStart(2, '0');
    const formattedResult1 = `${year1}-${month1}-${day1} ${hours1}:${minutes1}:${seconds1}`;

    const formattedTimestamp2 = timestamp2
      .toISOString()
      .replace('T', ' ')
      .slice(0, 19);
    const inputDate2 = new Date(formattedTimestamp2);
    // Subtract 5 hours and 30 minutes
    inputDate2.setHours(inputDate2.getHours() - 5);
    inputDate2.setMinutes(inputDate2.getMinutes() - 30);
    const year2 = inputDate2.getFullYear();
    const month2 = String(inputDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(inputDate2.getDate()).padStart(2, '0');
    const hours2 = String(inputDate2.getHours()).padStart(2, '0');
    const minutes2 = String(inputDate2.getMinutes()).padStart(2, '0');
    const seconds2 = String(inputDate2.getSeconds()).padStart(2, '0');
    const formattedResult2 = `${year2}-${month2}-${day2} ${hours2}:${minutes2}:${seconds2}`;
    return [formattedResult1, formattedResult2];
  }

  async function getAllDocpickerList(
    docpOptions,
  ) {
    try {
      let cellEditorDataSource = {};
      const {projectionForFolder} = getProjections();
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      const folderList = getAllFoldersList(bSettings);
      let currentDocpFolder = [];
      let txnParams = null;
      const filterParams = null;
      let folderName = null;
      if (!docpOptions.source?.folder) {
        folderName = docpOptions.name;
      } else {
        folderName = docpOptions.source.folder;
      }
      currentDocpFolder = getCurrentFolder(folderName, folderList);
      if (currentDocpFolder) {
        if (txnParams !== null) {
          txnParams.filters = filterParams;
        } else {
          txnParams = { refRows: true };
        }
        txnParams.projections = projectionForFolder(folderName);
        const { listData } = postData();
        const docpList = await listData(currentDocpFolder, txnParams);
        if (docpList.output.type === 'error') {
          console.log(docpList.output.message);
        } else {
          const { fatRowGenerator } = getFatRow();
          const fatRow = fatRowGenerator(docpList);
          const docPObj = cellEditorDataSource;
          const key = docpOptions.name;
          docPObj[key] = fatRow;
          cellEditorDataSource = docPObj;
        }
      }
      return cellEditorDataSource
    }
    catch (e) {
      console.log(e);
    }
  }

  const getProjections = () => {

    const projectionsList = {
      'sites' : {
        'sites': ['id', 'name', 'code', 'site_code']
      },
      'shifts' : {
        'shifts': ['id', 'name', 'shift_code', 'shift_time']
      },
      'departments': {
        'departments': ['id', 'code', 'name']
      },
      'products': {
        'designations' : ['id', 'code', 'name', 'deleted', 'creation_date', 'last_modified']
      },
      'attendance_types' : {
        'attendance_types' : ['id', 'code', 'name', 'source', 'deleted', 'last_modified']
      },
      'contracts' : {
        'contracts' : ['id', 'date_of_previous_month', 'name', 'contract_period']
      },
      'employees_id' : {
        'employees_id': ['id', 'code', 'deleted', 'persons_id', 'working_hours', 'working_days'],
        'employees_id.persons_id': ['id', 'deleted', 'last_name', 'birth_date', 'first_name', 'middle_name', 'gender'],
        'employees_id.persons_id.gender': ['id', 'code', 'name', 'source', 'deleted', 'last_modified'],
      }
    }
    function projectionForFolder(folderName) {
      return projectionsList[folderName] ? projectionsList[folderName] : {}
    }
    return {projectionForFolder}
  }

  async function deleteAllocation(store, selectedCells, gridApi) {
    if (selectedCells.length === 0) {
      toast.warning('Select cells to delete', {
        autoClose: 4000,
        closeButton: true,
        isLoading: false,
        position: toast.POSITION.TOP_CENTER,
      });
      return selectedCells;
    }
    const sessionId = store.getters['sessionIdGetter'];
    const bSettings = store.getters['bSettings'];
    const { getAllFoldersList, getCurrentFolder } = getFolders()
    const folderList = getAllFoldersList(bSettings);
    const allocationsFolder = getCurrentFolder('allocations', folderList);
    const deleteTxn = allocationsFolder?.txns?.txn_allocations_edits;
    let deleteNotification = null;
    deleteNotification = toast.loading('Deleting allocations...', {
      position: toast.POSITION.TOP_CENTER,
    });
    const empIds = []
    let success = 0;
    let error = 0;
    let leaves = 0;
    for (let i = 0; i < selectedCells.length; i++) {
      const key = Object.keys(selectedCells[i]);
      empIds.push(key);
      for (const obj in selectedCells[i][key[0]]) {
        if (!selectedCells[i][key][obj]?.isLeave) {
          const uuid = uuidv4()
          const params = [
            {
              '_path': 'BASEDATA'
            },
            {
              'allocations_id': selectedCells[i][key][obj].id
            },
            {
              '_path': 'business.folders.allocations'
            },
            {
              'id': selectedCells[i][key][obj].id,
              'contracts_id': [
                selectedCells[i][key][obj].contracts_id[0].id
              ],
              'employees_id': [
                key[0]
              ],
              'products_id': [
                selectedCells[i][key][obj].products_id[0].id
              ],
              'sites_id': [
                selectedCells[i][key][obj].sites_id[0].id
              ],
              'allocations_period': [
                {
                  'id': uuid,
                  'allocations_id': selectedCells[i][key][obj].id,
                  'allocations_range': selectedCells[i][key][obj].allocations_range,
                  'shifts_id': [
                    selectedCells[i][key][obj].shifts_id[0].id
                  ],
                  'allocation_type': [
                    selectedCells[i][key][obj].allocation_type[0].id
                  ]
                }
              ]
            },
            {
              'dataStart': 1,
              'dataEnd': 2,
              'pathIndexes': [
                2
              ],
              [`${selectedCells[i][key][obj].id}`]: '3__D',
              [`${selectedCells[i][key][obj].id}__${selectedCells[i][key][obj].contracts_id[0].id}`]: '3__D',
              [`${selectedCells[i][key][obj].id}__${key}`]: '3__D',
              [`${selectedCells[i][key][obj].id}__${selectedCells[i][key][obj].products_id[0].id}`]: '3__D',
              [`${selectedCells[i][key][obj].id}__${selectedCells[i][key][obj].sites_id[0].id}`]: '3__D',
              [`${uuid}`]: '3__D',
              [`${uuid}__${selectedCells[i][key][obj].shifts_id[0].id}`]: '3__D',
              [`${uuid}__${selectedCells[i][key][obj].allocation_type[0].id}`]: '3__D'
            }
          ]
          deleteTxn.params = params;
          deleteTxn.session_key = sessionId;
          const retVal = await sendMessage(deleteTxn)
          console.log('allocation delete', retVal);
          if (retVal.output.type === 'success') {
            success++;
          }
          else {
            error++;
          }
        } else {
          leaves++;
        }
      }
    }
    if (success !== 0) {
      toast.update(deleteNotification, {
        render: `${success} allocation deleted`,
        autoClose: 4000,
        closeButton: true,
        type: 'success',
        isLoading: false,
      });
    }
    if (error !== 0) {
      if (success === 0) {
        toast.update(deleteNotification, {
          render: `${error} allocation deletion failed`,
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(`${error} allocation deletion failed`, {
          autoClose: 4000,
          closeButton: true,
          type: 'error',
          isLoading: false,
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    if (leaves !== 0) {
      setTimeout(() => {
        toast.update(deleteNotification, {
          render: 'leaves cannot be deleted',
          autoClose: 4000,
          closeButton: true,
          type: 'warning',
          isLoading: false,
        });
      }, 4000)
    }
    gridApi.refreshInfiniteCache();
    return [];
  }

  async function saveAllocation(store) {
    const list = [];
    let saveNotification = null;
    const txnList = store.getters['agGridModule/gridTxnsGetter'];
    if (txnList.length > 0) {
      saveNotification = toast.loading('Updating allocations...', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    const txnStatus = [];
    const messages = [];
    for (let i = 0; i < txnList.length; i++) {
      list.push(await sendMessage(txnList[i]));
      txnStatus.push(list[i].output.type)
      if (list[i].output.type === 'error') {
        messages.push(list[i].output.message)
      }
    }
    let success = 0;
    let error = 0;
    if (txnStatus.length !== 0) {
      for (let i = 0; i < txnStatus.length; i++) {
        if (txnStatus[i] === 'success') {
          success++;
        } else if (txnStatus[i] === 'error') {
          error++;
        }
      }
    }
    if (success != 0) {
      toast.update(saveNotification, {
        render: `${success} allocation marked`,
        autoClose: 4000,
        closeButton: true,
        type: 'success',
        isLoading: false,
      });
    }
    if (error != 0) {
      for (let i = 0; i < messages.length; i++) {
        toast.error(messages[i]);
      }
      toast.update(saveNotification, {
        render: `${error} not marked`,
        autoClose: 4000,
        closeButton: true,
        type: 'error',
        isLoading: false,
      });
    }
    store.commit('agGridModule/gridTxnsMutations', []);
  }

  function checkIfDO(params, message) {
    const bSettings = store.getters['bSettings'];
    if (bSettings.env.code === 'admin') {
      return true;
    }
    if (params.data !== undefined && params.data !== null) {
      if (params.data.employees !== undefined && params.data.employees !== null) {
        const duty_chart_officer = params.data.employees?.[0]?.duty_chart_officer?.[0]?.id;
        if (bSettings?.env?.id === duty_chart_officer) {
          return true;
        } else {
          toast.warning(message, {
            position: toast.POSITION.TOP_CENTER,
          });
          return false;
        }
      }
    }
  }

  function allocationPeriodColumns(timeRange, showContractCellColor, showDesignationCellColor, cellEditorDataSource, selectedContract, selectedCells, selectAll) {
    const { formatDays } = attendanceGridUtils();
    let day = null;
    let month = null;
    let year = null;
    let weekRange = null;
    const today = new Date();
    month = today.getMonth();
    month++;
    year = today.getFullYear();
    if (timeRange !== null) {
      month = timeRange.month;
      year = timeRange.year;
      if (timeRange.day !== null) {
        day = timeRange.day;
      } else {
        weekRange = timeRange.weekRange
      }
    }
    const formattedDays = formatDays(day, month, year, weekRange);
    const result = [];
    for (let i = 0; i < formattedDays.length; i++) {
      const column = {
        headerName: `${formattedDays[i].formattedDay}`,
        field: `${formattedDays[i].day}${formattedDays[i].month}${formattedDays[i].year}`,
        cellRendererSelector: (params) => {
          return {
            component: 'AllocationCellRenderer',
          };
        },
        headerComponent: 'GridHeaderComponent',
        // maxWidth: 51.7,
        cellEditor: 'CellEditor',
        cellEditorPopupPosition: 'under',
        cellEditorPopup: true,
        suppressMovable: true,
        editable: (params) => {
          if (params.node.rowPinned) {
            return false;
          } else {
            if (params.data !== null && params.data !== undefined && Object.keys(params.data).length !== 0) {
              const colId = params.column.colId;
              if (params.data?.[colId] !== undefined) {
                if (params.data[colId].isLeave) {
                  toast.warning('Please reject or delete leave to mark allocations', {
                    position: toast.POSITION.TOP_CENTER,
                  });
                  return false;
                }
              }
            }
          }
          const isDO =  checkIfDO(params, 'Only duty chart officer can make the duty chart');
          return isDO;
        },
        cellRendererParams: {
          'showContractsCellColor':showContractCellColor,
          'showDesignationsCellColor':showDesignationCellColor,
          'cellsSelected': selectedCells,
          'selectAllCells': selectAll,
        },
        cellEditorParams: {
          'cellEditorDataSource':cellEditorDataSource,
          'selectedContract': selectedContract
        },
        enableCellChangeFlash: true,
        onCellValueChanged: allocationCellValueChanged(),
        cellStyle: (params) => {
          return { fontSize: '14px' };
        },
      };
      result.push(column);
    }
    return result;
  }

  function txnDataSetter(value) {
    const txnData = {};
    const editData = value;
    const empId = editData['employees_id'][0].id;
    txnData[empId] = [];
    const edits = {};
    const allocationKeys = Object.keys(editData).filter((key) => key);
    allocationKeys.forEach((key) => {
      const value = editData[key];
      const item = value;
      if (Array.isArray(item)) {
        item[0]['type'] =  'docpicker';
        item[0]['oldDocpId'] = null;
        item[0]['docpNotSet'] = true;
        item[0]['detailType'] = false;
        item[0]['folderId'] = null;
        item[0]['docpType'] = true;
      }
      const detailKey = ['allocation_type', 'shifts_id', 'allocations_range', 'departments_id'].includes(key);
      // if (key !== 'allocations_range') {
      //   item = item[0];
      // }
      if (!detailKey) {
        edits[key] = {
          path: 'allocations.' + key,
          value: item,
          mandatory: false,
        };
      } else if (edits.allocations_period === undefined) {
        edits['allocations_period'] = [];
        edits['allocations_period'].pathofDetail = 'allocations.allocations_period';
        edits['allocations_period'].primaryKey = uuidv4();
        edits['allocations_period'].visible = true;
        edits['allocations_period'].push({
          newRowAdded: true,
          fieldType: 'detail',
          id: uuidv4(),
          [`${key}`]: item
        });
      } else {
        edits['allocations_period'][0][`${key}`] = item
      }
    });
    txnData[empId].push(edits);
    return txnData;
  }

  function allocationCellValueChanged(context) {
    return async(params) => {
      if (params.newValue.edited && params.newValue.allocations_edit.hasOwnProperty('contracts_id') && params.newValue.allocations_edit.hasOwnProperty('sites_id') && params.newValue.allocations_edit.hasOwnProperty('shifts_id')  && params.newValue.allocations_edit.hasOwnProperty('products_id') && params.newValue.allocations_edit.hasOwnProperty('allocation_type')) {
        const { getAttendanceDate } = attendanceGridUtils()
        const allocationDate = getAttendanceDate(params, null);
        const employees_id = 'employees' in params.data ? params.data['employees'] : null;
        const allocations_edits = params?.newValue?.allocations_edit;
        allocations_edits['employees_id'] = employees_id;
        allocations_edits['allocations_range'] = allocationDate;
        const txnData = txnDataSetter(allocations_edits);
        await generateTxns(txnData);
      }
    }
  }

  async function generateTxns(txnData) {
    if (Object.keys(txnData).length !== 0) {
      let txns = {};
      const bSettings = store.getters['bSettings'];
      const { getAllFoldersList, getCurrentFolder } = getFolders();
      const folderList = getAllFoldersList(bSettings);
      const currentFolder = getCurrentFolder('allocations', folderList);
      const sessionId = store.getters['sessionIdGetter'];
      for (const key in txnData) {
        for (let i = 0; i < txnData[key].length; i++) {
          const formData = txnData[key][i];
          const { getGroupedFormData } = processFormData();
          const {
            normalFieldList,
            docpList,
            detailList,
            detailKeyFieldName,
          } = getGroupedFormData(formData, currentFolder, undefined);
          //generate the txnToRun with txn and fieldsData
          const params = {
            normalFieldList: normalFieldList,
            docpList: docpList,
            detailList: detailList,
            folderDetails: currentFolder,
            currentTaskName: 'create',
            detailKeyName: detailKeyFieldName,
            txnType: undefined,
            currentFolderId: undefined,
            txn: currentFolder.txns.txn_allocations_edits,
          };
          const { generateTxn } = makeTxn(params);
          const txnToRun = generateTxn();
          txnToRun['no_response'] = true;
          txnToRun.session_key = sessionId;
          //run the txn
          txns = txnToRun;
        }
      }
      await store.dispatch('agGridModule/gridTxnsSetter', JSON.parse(JSON.stringify(txns)));
    }
  }

  async function allocationList() {

    return [];
  }

  return { checkIfDO, allocationList, allocationPeriodColumns, getAllDocpickerList, saveAllocation, deleteAllocation, createTimeRange, txnDataSetter, generateTxns };
}

export default allocationGridUtils;
