<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    {{ format(value) }}
    <!-- <VueDatePicker  v-model="value" text-input :is-24="true" readonly /> -->
  </div>
  <div
    v-else-if="showField"
  >
    <span
      v-if="!showInTable && !readOnly && !timeStampOptions.fieldOnlyView"
      class="input-group my-2"
    >{{ timeStampOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *</span>
    </span>
    <div
      v-if="!showInTable"
      :class="{'d-block':isMissing}"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <VueDatePicker
      :id="dataTestId"
      ref="datepicker"
      v-model="value"
      :data-test-id="dataTestId"
      :disabled="isReadOnly || isEditNotAllowed"
      :uid="dataTestId"
      :class="{ 'mandatory-field': isMandatory && isMissing,'invalid-field':!isValid }"
      :text-input="textInputOptions"
      :is-24="true"
      :placeholder="placeHolder"
      :teleport="true"
      utc
      :month-change-on-scroll="false"
      show-now-button
      :month-picker="monthPicker"
      auto-apply
      :year-picker="yearPicker"
      :start-time="startTime"
      :ui="uiClasses"
      :format="format"
      @update:model-value="handleChange"
      @focus="handleFocused"
    />
    <v-tooltip
      v-if="showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ format(value) }}
    </v-tooltip>
  </div>
</template>

<script>
import { ref, computed, toRef} from 'vue'
import getConverters from '../../composables/getConverters'
import { useI18n } from 'vue-i18n'
import processExpr from '../../composables/processExprs'
import controlUtility from '../../composables/controlUtility'
export default {
  name: 'WfmTimestamp',
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],
  setup(props, context) {
    //variable
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const datepicker = ref()
    const showInTable = props.params?.showInTable ? true : false
    const readOnly = props.params?.readOnly ? true : false
    const startTime = ref({ hours: 0, minutes: 0 });

    const timeStampOptions =  props.params.fieldOptions
    const timeStampParams = toRef(props.params.data)
    const propertyName = timeStampOptions.name
    // const utcTimeStamp = timeStampParams?.[propertyName]
    const folderName = timeStampOptions?.path?.split('.')[0]
    const idOfTheFolder = timeStampParams.value?.[folderName]?.[0]?.id ? timeStampParams.value?.[folderName]?.[0]?.id : timeStampParams.value?.id
    const value = ref(timeStampParams.value?.[propertyName] ? timeStampParams.value?.[propertyName] : timeStampParams.value?.[folderName]?.[0]?.[propertyName])

    const fieldPath = timeStampOptions?.path
    const isValid = ref(true)
    const valueEntered = ref(false)
    const reportFilter = ref(props.params.data?.reportFilter)
    const monthPicker = ref(timeStampOptions?.filterType == 'month' ? true : false)
    const yearPicker = ref(timeStampOptions?.filterType === 'year' ? true : false)
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)
    const currentFolder = ref(props.params.currentFolder)
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields
    const { monthPickerToUtc} = getConverters()
    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr, formatExpr} = processExpr(currentFolder.value, timeStampParams.value, showInTable)

    //if showing in the grid of readonly thn format and show
    // value.value = readOnly ? timeStampStoredToUserFriendly(utcTimeStamp) : value.value
    const uiClasses = computed(() => {
      return {
        input:  'form-input-date',
      }

    })
    if (yearPicker.value) {
      value.value = new Date(value.value).getFullYear() || new Date().getFullYear()
    }
    if (monthPicker.value) {
      value.value = {
        month: new Date(value.value).getMonth(),
        year: new Date(value.value).getFullYear()
      }
    }

    function parseDate(dateString) {
      // to make the typed date be a proper dd/mm/yyyy with time as current time
      const [day, month, year] = dateString.split('/').map(Number);
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentSeconds = now.getSeconds();
      return new Date(year, month - 1, day, currentHours, currentMinutes, currentSeconds);
    }


    // const folderFields = inject("folder-fields")
    const textInputOptions = computed(() => {
      return {
        enterSubmit: true,
        openMenu: true,
        format:(value) => {
          const date = parseDate(value)
          if (date != 'Invalid Date') {
            return date
          }
        }
      }

    })

    const placeHolder = computed(() => {
      if (monthPicker.value) {
        return 'choose month'
      }
      else if (yearPicker.value) {
        return 'choose year'
      }
      else {
        return timeStampOptions.placeHolder ? timeStampOptions.placeHolder : timeStampOptions.label.eng

      }
    })
    const format = (date) => {

      const dateToFormat = (typeof value.value) == 'string' ? (value.value) : date
      // c.log('finalDate', dateToFormat)
      if (yearPicker.value) {
        return new Date(dateToFormat).getFullYear()
      }
      if (timeStampOptions.format_expr != null) {
        return formatExpr(date, timeStampOptions.format_expr)
      }
      else {
        const dateToShow = new Date(dateToFormat)
        if (yearPicker.value) {
          dateToShow.setDate(1)
          dateToShow.setMonth(0)
        }
        if (monthPicker.value) {
          dateToShow.setDate(1)
        }
        const dateFormated = dateToShow.toDateString()
        if (dateFormated === 'Invalid Date') {
          return date
        }
        else {
          return dateFormated
        }
      }
      // }
    }

    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })

    const isVisible = computed(() => {
      const retVal = processVisibleExpr(timeStampOptions.visible_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(timeStampOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(timeStampOptions.readonly_expr)
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(timeStampOptions.default_value)
      if (retVal == 'now' && value.value == null) {
        const defaultIst = new Date();
        const defaultUtc = defaultIst.toISOString()
        return defaultUtc
      }
      else {
        return null
      }
    });
    if (defaultValue.value) {
      getDefaultValue()
    }
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${timeStampOptions.type}/${propertyName}`
        }
      }
    })
    //methods
    function handleChange() {
      //time in utc already
      let newValue = value.value
      if (reportFilter.value) {
        if (timeStampOptions.filterType == 'month') {
          newValue = monthPickerToUtc(value.value)

        }
        else {
          newValue = value.value
        }
      }
      if (newValue) {
        valueEntered.value = true
      }
      if (timeStampOptions.valid_expr !== undefined) {
        isValid.value = processValidExpr(timeStampOptions.valid_expr, newValue)

      }

      if (isValid.value) {
        // context.emit("invalid-check", { propertyName: propertyName, value: false })
        invalidCheck({ propertyName: propertyName, value: false })
        if (showInTable) {
          timeStampParams.value[propertyName] = newValue
          props.params.cellValueChanged(timeStampParams.value)
        }
        //else emit event to control
        else {
          context.emit('input-change', { path: fieldPath, value: newValue, folderId: idOfTheFolder, mandatory: timeStampOptions.mandatory_expr })
        }
      }
      else {
        // context.emit("invalid-check", { propertyName: propertyName, value: true })
        invalidCheck({ propertyName: propertyName, value: true })
      }
    }
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }
    // function handleClosed() {
    //   //on close giving the focus back to the input field inside the datepicker
    //   document.getElementById(`dp-input-${dataTestId.value}`).focus()
    // }
    function handleFocused() {
      datepicker.value.openMenu()
    }

    return {
      value,
      handleFocused,
      handleChange,
      timeStampOptions,
      showInTable,
      readOnly,
      currentLanguage,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      isValid,
      reportFilter,
      monthPicker,
      yearPicker,
      format,
      propertyName,
      showField,
      mandatoryStatus,
      // handleClosed,
      isEditNotAllowed,
      datepicker,
      placeHolder,
      dataTestId,
      uiClasses,
      textInputOptions,
      startTime
    }
  }
}
</script>
<style>
.form-input-date {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color) !important;
  color:var(--controls-font-color) !important;
  border:1px solid var(--controls-border-color) !important;
  cursor: pointer;
  border-radius: 8px;

}
.dp_input{
  border:none !important;
  background-color: red !important;
}
.dp__disabled {
 background: var(--form-disabled-bg) !important;
 cursor:not-allowed !important;
 opacity: 0.5;
}
</style>
<style scoped>
.dp_input{
  border:none !important;
    background-color: var(--controls-background-color) !important;

}
.dp__input_wrap{
   min-height: 5px !important;
  width: 100% !important;
      background-color: var(--controls-background-color) !important;

}
.input-group-text {
  background-color: antiquewhite;
}


.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}


.invalid-field{
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black

}
</style>
