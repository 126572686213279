<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        Employees
      </div>
      <input
        v-model="filterText"
        type="text"
        :placeholder="inputFocused ? '' : 'Search Employees'"
        @input="filterEmployees"
        @focus="onFocus"
        @blur="onBlur"
      />
    </div>
    <div class="row mt-2 sort-btn">
      <div
        class="col-sm-6"
        style="font-size: 12px;"
        @click="sortRowsOn('code')"
      >
        Code
        <span>&#8593;</span>
        <span>&#8595;</span>
      </div>
      <div
        class="col-sm-6"
        style="font-size: 12px;"
        @click="sortRowsOn('name')"
      >
        Name
        <span>&#8593;</span>
        <span>&#8595;</span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import getUtility from '../../../composables/getUtility.js';

export default defineComponent({
  name: 'CustomEmployeeColHeader',
  props: {
    column: {
      type: Object,
      default: null,
    },
    api: {
      type: Object,
      default: null,
    },
    colDef: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const sortByAsc = ref(true);
    const filterText = ref('');
    const inputFocused = ref(false)
    const { debounce } = getUtility();

    function sortRowsOn(fieldToSort) {
      sortByAsc.value = !sortByAsc.value;
      props.params?.handleSortRowsOn(fieldToSort);
    }

    const filterEmployees = debounce(() => {
      const filterValue = filterText.value.trim();

      props.params.api.setFilterModel({
        [props.params.column.colId]: filterValue ? {type: 'contains', filter: filterValue} : null,
      });

      props.params.api.onFilterChanged();
    }, 2000);

    watch(
      () => props.params.api.getFilterModel(),
      (newFilterModel) => {
        const currentFilter =
          newFilterModel[props.params.column.colId]?.filter || '';
        filterText.value = currentFilter;
      },
      { deep: true, immediate: true }
    );

    function onFocus() {
      inputFocused.value = true
    }
    function onBlur() {
      inputFocused.value = false
    }
    return {
      sortRowsOn,
      sortByAsc,
      filterEmployees,
      filterText,
      onFocus,
      inputFocused,
      onBlur
    };
  },
});
</script>

<style scoped>
/* Add styles for the input group */
.input-group {
  display: flex;
  align-items: center;
}
.input-group .form-control {
  flex: 1;
}
.input-group .btn {
  margin-left: 5px;
}

</style>
