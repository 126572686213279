<template>
  <WfmToolbar
    :data-test-id="'form_' + folder.name"
    custom-class="toolbar-class"
  >
    <template #left-utility>
      <div class="d-flex me-2">
        <div>
          <v-icon
            fill="white"
            :name="listIcon"
            scale="1.5"
            style="cursor: pointer;"
            :data-test-id="`${folder.name}/${currentTask}/expandForm`"
            @click="expandTheList"
          />
          <v-tooltip
            activator="parent"
            location="left"
          >
            {{ listExpanded ? 'hide list' : 'show list' }}
          </v-tooltip>
        </div>
        <div>
          <v-icon
            :fill="mandatoryOnlyIconColor"
            class="mt-1"
            name="bi-patch-exclamation"
            scale="1.2"
            style="cursor: pointer;"
            :data-test-id="`${folder.name}/${currentTask}/mandatoryFilter`"
            @click="showOnlyMandatoryFields"
          />
          <v-tooltip
            activator="parent"
            location="left"
          >
            {{ mandatoryFilter ? 'show all fields' : 'show only mandatory fields' }}
          </v-tooltip>
        </div>
        <WfmActionsList
          :actions="actionsToPerform?.length>0?actionsToPerform:null"
          :data-test-id="'form_' + folder.name"
          @action-click="performActionModal"
        />
        <div
          v-if="showBusinessesFilter"
          class="ms-3"
        >
          <WfmBusinessFilter :folder="folder" />
        </div>
      </div>
    </template>
    <template #right-utility>
      <div class="d-flex">
        <!-- refBys in toolbar -->
        <div
          v-if="showRef"
          class="justify-end row my-auto"
        >
          <WfmRefBy
            :ref-bys="refBy"
            :folder="folder"
            :row-data="rowData"
            @add-refby-tab="addRefByTab"
          />
        </div>

        <!-- update Button in Toolbar -->
        <div
          v-if="!readOnly && !isEditNotAllowed && !invoiceApprovalCase"
          class=""
        >
          <WfmButton
            button-class="update-btn"
            @button-click="handleClick()"
          >
            {{ txnToUse?.label?.eng||"null" }}
          </WfmButton>
        </div>
      </div>
    </template>
  </WfmToolbar>
  <WfmForm
    :key="formKey"
    class="propsheet-container"
    :class="customClass"
    :data-test-id="'wfm_form_' + folder.name"
  >
    <template #form-header>
      <div class="footer-content d-flex justify-content-between ">
        <!-- mandatory fields or invalid check  -->
        <div class="d-flex">
          <div
            v-if="missingFields.length!==0"
            class="fs-6 mt-1"
          >
            <span class="me-2">Mandatory*</span>
          </div>
          <div class="row">
            <div
              v-for="(item,index) in missingFields"
              :key="index"
              class="mt-2 col"
            >
              <span
                class="badge bg-danger mt-1"
                :style="{'cursor':'pointer' ,
                         'font-size':'12px'}"
                @click="scrollToTheField(item)"
              >{{ item.split('/')[1] }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- body section of the form  -->
    <template #form-body>
      <WfmFieldGroup
        :params="rowData"
        :fields-to-display="fieldsToDisplay"
        :form-object-found="formObjectFound"
        :base-folder="folder"
        :current-task="currentTask"
        :filled-data="filledData"
        :ag-grid-api="agGridApi"
        :get-txn-to-run="getTxnToRun"
        :refresh-tab-data="refreshTabData"
      />
    </template>
    <template #form-footer>
      <div
        v-if="showLeaveQuota"
        class="leaves-info container"
      >
        <span class="mt-1 p-2">
          Leaves Left For {{ currentEmpName }}
        </span>
        <div class="row mt-3 text-center">
          <div
            class="mt-1"
            data-test-id="leaves_left_count"
          >
            Current month : {{ leavesQuota?.leaves_left }}
          </div>
          <div
            class="mt-1 text-center"
            data-test-id="yearly_leaves_left_count"
          >
            Current year : {{ leavesQuota?.yearly_leaves_left || 0 }}
          </div>
        </div>
      </div>
    </template>
    <!-- footer section modal -->
  </WfmForm>
</template>

<script>
import { computed, provide, ref, onMounted, onActivated} from 'vue';
import {keys} from '../../provideKeys.js'
import WfmForm from '../../common/wfm-form.vue'
import WfmButton from '../../common/wfm-button.vue';
import WfmFieldGroup from '../wfm-folders/wfm-fieldGroup.vue'
import actionsListInFolder from '../../composables/actionsListInFolder'
import { toast } from 'vue3-toastify'
import foldersUtility from '../../composables/foldersUtility';
import makeFilter from '../../composables/makeFilter'
import postData from '../../composables/postData'
import getFatRow from '../../composables/fatRowGenerator'
import makeTxnForBulk from '../../composables/makeTxnForBulk'
import { sendMessage } from '../../services/websocket'
import makeTxn from '../../composables/makeTxn'
import processFormData from '../../composables/processFormData'
import processOtherForm from '../../composables/processOtherForm'
import getFormInfo from '../../composables/getFormInfo'
import usePartnersId from '../../composables/usePartnersId.js';
import WfmToolbar from '../../common/wfm-toolbar.vue';
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import WfmBusinessFilter from '../../common/wfm-business-filter.vue';
import permissionsCheck from '../../composables/permissionCheck'
import WfmRefBy from '../../common/wfm-refBy.vue';

// composables

import { useStore } from 'vuex'

export default {
  name: 'FolderForm',
  components: {
    WfmForm,
    WfmFieldGroup,
    WfmButton,
    WfmToolbar,
    WfmActionsList,
    WfmRefBy,
    WfmBusinessFilter
  },
  props: {
    rowData: {
      type: Object,
      default : () => {}
    },
    fieldsToDisplay: {
      type: Array,
      default: () => []
    },
    formObjectFound: {
      type: Boolean,
      default : true
    },
    folder: {
      type: Object,
      default: null
    },
    refBy: {
      type: Object,
      default :null
    },
    currentTask:{
      type:String,
      default:null
    },
    txnToUse:{
      type:Object,
      default:null
    },
    rowNode:{
      type:Object,
      default:null
    },
    readOnly:{
      type:Boolean,
      default:false
    },
    agGridApi:{
      type:Object,
      default:null
    },
    customClass:{
      type:String,
      default:null
    },
    filledData:{
      type:Object,
      default:null
    }
  },
  emits: ['add-tab', 'change-form-state', 'del-tab', 'refresh-data', 'updateKeepAliveState'],
  setup(props, context) {
    //variables
    const store = useStore()
    const formData = ref({})
    const loadRefBy = ref(false)
    const {getIcon} = useIcons()
    provide(keys.formData, formData)
    const notificationId = ref()
    const listIcon = ref('bi-list')
    const listExpanded = ref(false)
    provide(keys.listExpanded, listExpanded)
    const mandatoryFilter = ref(false)
    //this flag is provided from the folderForm to tell the child component
    //it should be visible in the form only if its a mandatory field
    provide(keys.mandatoryFilter, mandatoryFilter);
    const mainFolderId = ref(props.rowData?.id)
    const incompleteForm = ref(false)
    const inValidFieldsPresent = ref(false)
    const formSaveClicked = ref(false)
    const sessionId = store.getters['sessionIdGetter'];
    const forceDelete = ref(false)
    const deleteTxn = ref(false)
    const deleteConfirmation = ref(false)
    const mandatoryOnlyIconColor = ref('white')
    const formKey = ref(6)
    //const calendarData = ref()
    const leavesQuota = ref(null)
    const clearFormState = ref(false)
    provide(keys.clearFormState, clearFormState)
    provide(keys.leavesQuota, leavesQuota)
    const currentRowNode = ref(props.rowNode)
    const showLeaveQuota = computed(() => {
      return props.folder.name === 'leaves' && currentEmpId.value ? true : false
    })
    //composables declare
    const { getFormObject, getCurrentTxn } = getFormInfo()
    const {getCurrentSelectedPartnersId} = usePartnersId()
    const mandatoryFields = ref([])
    const invalidFields = ref([])
    provide(keys.mandatoryFields, mandatoryFields)
    provide(keys.invalidFields, invalidFields)
    const { getGroupedFormData, emptyTheContainer } = processFormData()
    const { getOtherFormDataGroup, emptyOthersContainer } = processOtherForm()

    onActivated(() => {
      //this is the best way so far. storing the rowData in vuex onactivated,
      //this then fixes issues like expr while switching tabs and folder etc.
      store.dispatch('commonModule/currentRowDataSetter', props.rowData);

      touchFormKey()
    })

    const {evalPermission} = permissionsCheck()

    // Refs
    // const {getNullObject} = getUtility()
    const showRef = computed(() => {
      if (props.currentTask === 'create') {
        return false
      }
      else {
        return true
      }
    })
    const folderFields = computed(() => {
      // console.log('rpwD', props)
      // if (props.currentTask === 'create') {
      //   const cloneOfFolderFields = JSON.parse(JSON.stringify(props.folder.fields));
      //   const emptyFolderFields = getNullObject(cloneOfFolderFields)
      //   return emptyFolderFields
      // }
      // else if (props.currentTask === 'update') {
      //   return props.rowData
      // }
      // else {
      //   return props.folder.fields
      // }
      return props.rowData
    })

    const showBusinessesFilter = computed(() => {
      const hasPartnersId = props.folder.fields.hasOwnProperty('partners_id')
      const excludedFolder = ['bulk_upload', 'salary_structures'].includes(props.folder.name)
      return hasPartnersId && !excludedFolder
    })
    provide(keys.folderFields, folderFields)
    const missingFields = computed(() => {
      let retVal = []
      if (formSaveClicked.value) {
        // const mandatoryFieldsInForm = getMandatoryFields()
        const mandatoryFieldsInForm = mandatoryFields.value
        if (props.currentTask === 'create') {
          retVal = mandatoryFieldsInForm.filter((field) => !formData.value.hasOwnProperty(field.split('/')[1]));
        }
      }
      return retVal
    })
    provide(keys.missingFields, missingFields)

    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings']
      return bSettingString
    })
    //computed properties
    const invoiceApprovalCase = ref(props.txnToUse.name == 'txn_invoices_update')
    const isEditNotAllowed = computed(() => {
      //here checking if txn to run is allowed or not
      //if the target_swhandle is same as logged in allow
      if (showBusinessesFilter.value == false) return false;
      if (bSettings.value.env?.user_selected_partner?.[0]?.code == bSettings.value.swhandle) {
        return false
      }
      else {
        //if not same check shared_permission
        const sharedPermissionExpr = props.txnToUse?.shared_permissions?.envPermission
        const editAllowed = evalPermission(sharedPermissionExpr, bSettings.value.env)
        return !editAllowed
      }

    })
    provide(keys.editNotAllowed, isEditNotAllowed)
    const actionsToShow = computed(() => {
      const listOfTxn = props.folder.txns
      const {actionsToShowInForm} = actionsListInFolder(listOfTxn)
      const retVal = actionsToShowInForm()
      return retVal
    })
    const actions = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      for (var i = 0; i < actionsToShow.value.length; i++) {
        const index = i
        const iconsName = actionsToShow?.value?.[i]?.label?.eng.split(' ')[0];
        const obj = {
          name:actionsToShow?.value?.[i]?.label?.eng,
          icon:getIcon(iconsName)?.name,
          fill:getIcon(iconsName)?.fill,
          action:() => {
            runTxn(actionsToShow.value[index])
          }
        }
        retVal.actions.push(obj)
      }
      return retVal
    })
    const actionsToPerform = computed(() => {
      const retVal = []
      if (props.currentTask !== 'create') {
        if (actions.value.actions.length > 0) {
          retVal.push(actions.value)
        }
      }
      return retVal
    })

    const currentEmpId = computed(() => {
      let retVal = null
      if (props.currentTask == 'create') {
        retVal = formData.value?.employees_id?.value?.[0]?.id
      }
      else if (props.currentTask == 'update') {
        retVal = props.rowData?.employees_id?.[0].id
      }
      return retVal
    })
    const currentEmpName = computed(() => {
      return formData.value?.employees_id?.value?.[0].persons_id?.[0].first_name?.eng || props.rowData?.employees_id?.[0].persons_id?.[0].first_name?.eng
    })
    const formObject = computed(() => {
      //get the current txn object depending on the txntype
      //get the form object that is needed
      let currentTxn = null
      if (props.txnToUse != null) {
        currentTxn = props.txnToUse
      }
      else {
        currentTxn = getCurrentTxn(
          props.currentTask,
          props.folder
        )
      }
      const formName = currentTxn?.form?.form_name ? currentTxn?.form?.form_name : currentTxn?.form
      return getFormObject(formName, props.folder)
    })
    // //watch for the change in the state of the formOpen and assign accordingly
    // watch(currentEmpId, (newValue) => {
    //   try {
    //     if (props.folder.name === 'leaves') {
    //       getLeavesQuota(currentEmpId.value)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // });

    //methods

    async function addRefByTab(tabsSpecs) {
      context.emit('add-tab', tabsSpecs)
    }

    function refByDataTestId(refs) {
      return `${props.folder.name}/refBy/${refs.label}`
    }
    function touchFormKey() {
      formKey.value = Math.floor(Math.random() * 1000000)
    }
    function scrollToTheField(fieldName) {
      var targetDiv = document.getElementById(fieldName);

      if (targetDiv) {
        // Scroll to the div
        targetDiv.scrollIntoView({ behavior: 'smooth', block: 'center' });
        targetDiv.focus()
      } else {
        console.error("Div with keyword '" + fieldName + "' not found.");
      }
    }
    function applyBusinessFilter(newValue) {
      // fieldsToFilter.value = newValue
    }

    function dependencyDelete() {
      forceDelete.value = true
      removeRecord()
    }
    async function runTxn(txn) {
      //todo last and first check
      const txnNameArray = txn.name.split('_')
      if (txnNameArray[txnNameArray.length - 1] === 'delete') {
        removeRecord(txn)
      }
      else {
        const txnName = txnNameArray[txnNameArray.length - 1] == ('terminate' || 'Terminate') ? 'Left' : txnNameArray[txnNameArray.length - 1]

        await makeParamsToSend(txn, txnName)

      }
    }
    function cancelDelete() {
      deleteConfirmation.value = false
    }
    async function makeParamsToSend(txn, bulkTxnName) {
      const selectedRows = props.agGridApi.getSelectedRows()
      currentRowNode.value = props.agGridApi.getSelectedNodes()
      //start a notification of task progress  with specific id
      notificationId.value = toast.loading(`working to ${bulkTxnName}...`, {
        position: toast.POSITION.TOP_CENTER
      })
      const dataToChange = {path:'', value:[]}
      let docpSelected = [];
      const txnName = bulkTxnName
      let propertyName = null
      let docpSource = null
      const {changeWorkingFolder} = foldersUtility()
      let docpSourceFolder = null

      if (props.folder.name === 'employees') {
        propertyName = 'employee_status'
        docpSource = props.folder.fields[propertyName].source
        docpSourceFolder = changeWorkingFolder(docpSource, bSettings.value?.output.data.records[0])
      }
      else if (props.folder.name === 'leaves') {
        propertyName = 'leave_status'
        docpSource = props.folder.fields[propertyName]?.source;
        docpSourceFolder = changeWorkingFolder(docpSource, bSettings.value?.output.data.records[0])
      }
      //getting the docpId
      const { generateFilterParams } = makeFilter(txnName, docpSourceFolder, null, null, true);
      const filterParams = generateFilterParams();
      const txnParams = {filters:filterParams, refRows:true};
      const { listData } = postData()
      //adding the partners filter to get the target_swhandles data
      txnParams.filters.filterInput.push({joinop:'or', filterInput:[{ path:`${docpSourceFolder.name}.partners_id`, operator:'=', value:getCurrentSelectedPartnersId()}]})
      const list = await listData(docpSourceFolder, txnParams);
      if (list.output.type === 'error') {
        console.log(list.output.message);
      }
      else {
        if (list.output.data.records.length > 0) {
          const { fatRowGenerator } = getFatRow();
          docpSelected = fatRowGenerator(list);
        }
      }
      //loop thru each selected row to create formData
      for (var i = 0; i < selectedRows?.length; i++) {
        if (props.folder.name === 'leaves') {
          dataToChange.path = 'leaves.leave_status'
          if (selectedRows[i].leave_status?.[0].id) {
            dataToChange.value.push({oldDocpId:selectedRows[i].leave_status?.[0].id, newId:docpSelected[0]?.id, txnOp:'U', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})
          }
          else {
            dataToChange.value.push({oldDocpId:null, newId:docpSelected[0]?.id, txnOp:'C', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

          }
        }

        else if (props.folder.name === 'employees') {
          dataToChange.path = 'employees.employee_status'
          if (selectedRows[i][propertyName] && selectedRows[i][propertyName].length != 0) {
            dataToChange.value.push({oldDocpId:selectedRows[i][propertyName][0].id,
              newId:docpSelected[0]?.id, txnOp:'U', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})
          }
          else {
            dataToChange.value.push({oldDocpId:null, newId:docpSelected[0]?.id, txnOp:'C', mainFolderId:selectedRows[i].id, propertyName:propertyName, params:selectedRows[i]})

          }
        }

      }

      const formData = dataToChange
      //generate the txnToRun with txn and fieldsData
      const { generateTxn } = makeTxnForBulk(
        formData,
        props.folder,
        txn,
        txnName
      )

      const txnToRun = generateTxn()
      txnToRun.session_key = sessionId;
      txnToRun.target_swhandle = bSettings.value.env?.user_selected_partner?.[0]?.code
      list.value = await sendMessage(txnToRun)
      await handleResult(list.value)
    }

    async function removeRecord(txn) {
      deleteTxn.value = true
      //as we are dealing with tabs selected row should be the one which is the active tab
      // gridApi.value.deselectAll();
      // currentRowNode.value[0]?.setSelected(true)
      const currentRecord = props.rowData
      const txnType = 'delete'
      const dataToDelete = []
      dataToDelete.push({id: currentRecord.id})
      notificationId.value = toast.loading('working on it...', {
        position: toast.POSITION.TOP_CENTER
      })

      const params = {
        normalFieldList:dataToDelete,
        folderDetails:props.folder,
        currentTaskName:props.currentTask,
        txnType:txnType,
        currentFolderId:mainFolderId.value
      }

      //generate the txnToRun with txn and fieldsData
      const { generateTxn } = makeTxn(params)
      const txnToRun = generateTxn()
      txnToRun.session_key = sessionId;
      if (forceDelete.value) {
        txnToRun.deleteDependentData = true
      }
      const list = await sendMessage(txnToRun)
      await handleResult(list)
      forceDelete.value = false
      txnToRun.deleteDependentData = false

    }
    //handle the retVal
    async function handleResult(data) {

      if (data.output.type === 'success') {
        const { fatRowGenerator } = getFatRow()
        //add the new row to the top without refreshing whole grid
        if (props.currentTask === 'create') {
          if (Object.keys(data.output.data.records).length !== 0) {
            const records = [];
            for (let i = 0; i < data.output.data.records.length; i++) {
              records.push(JSON.parse(data.output.data.records[i]))
            }
            data.output.data.records = records;
            const listData = fatRowGenerator(data)
            props.agGridApi?.applyTransaction({
              add: [listData[0]],
              addIndex: 0
            })
          }
          // currentTaskName.value = "";
          //empty the formData
        } else {
          //make the grid update without refresh
          const records = [];
          for (let i = 0; i < data.output.data.records.length; i++) {
            records.push(JSON.parse(data.output.data.records[i]))
          }
          data.output.data.records = records;

          const listData = fatRowGenerator(data)
          if (listData[0] && !deleteTxn.value) {
            //general update txn should handle here
            if (currentRowNode.value !== undefined) {
              currentRowNode.value?.[0]?.setData(listData[0])
            }
            context.emit('refresh-data', listData[0])

          } else {
            //in case of delete txn match the rowID from the list and remove that row
            const selectedRowId = props.rowData.id
            const completeRows = []
            props.agGridApi.forEachNode((node) => completeRows.push(node.data));
            const rowsToRemove = completeRows.filter((row) => selectedRowId == row.id);
            props.agGridApi?.applyTransaction({
              remove: rowsToRemove
            })
            //close the tab
            context.emit('del-tab')
          }

        }
        //if success show update the notification with success status
        toast.update(notificationId.value, {
          render: 'done',
          autoClose: 1000,
          closeButton: true,
          type: 'success',
          isLoading: false
        })
        clearState()
        touchFormKey()
      }
      if (data.output.type === 'error') {
        if (!deleteTxn.value) {
          context.emit('change-form-state', true)
        }
        if (deleteTxn.value) {
          deleteConfirmation.value = true
        }
        toast.update(notificationId.value, {
          render: data.output.message,
          type: 'error',
          isLoading: false,
          autoClose:3000,
          closeButton: true,
          position: toast.POSITION.TOP_CENTER,
        });
      }
      //empty the arrays that is returned to make the txn after its used to avoid being used again in another txn
      emptyTheContainer()
      emptyOthersContainer()
    }

    function performActionModal(action) {
      console.log('handle action in the modal')
      action()
    }

    function clearState() {
      try {
        //empty the form data
        formSaveClicked.value = false
        listIcon.value = 'bi-list'
        // store.dispatch("commonModule/formDataSetter", {})
        formData.value = {}
        if (props.currentTask === 'create') {
          clearFormState.value = true;
        }
        //empty the gridApis
        store.dispatch('agGridModule/detailGridApiSetter', [])
        listExpanded.value = false
        // mandatoryCheck("reset")
        //invalidCheck("reset")
        context.emit('change-form-state', false)

      } catch (error) {
        console.log(error)
      }
    }
    //expand the list
    function expandTheList() {
      listExpanded.value = !listExpanded.value
      if (listExpanded.value) {
        listIcon.value = 'bi-list-nested'
      } else {
        listIcon.value = 'bi-list'
      }
    }

    function showOnlyMandatoryFields() {
      //here the mandatoryFilter will change which is provided in each controls
      mandatoryFilter.value = !mandatoryFilter.value

      mandatoryOnlyIconColor.value = mandatoryFilter.value ? 'red' : 'white'
    }

    function refreshTabData(rowData) {
      context.emit('refresh-data', rowData);
    }

    function getTxnToRun() {
      try {
        const sessionId = store.getters['sessionIdGetter'];
        const {
          normalFieldList,
          docpList,
          detailList,
          detailKeyFieldName,
        } = getGroupedFormData(formData.value, props.folder, null)
        const params = {
          normalFieldList:normalFieldList,
          docpList:docpList,
          detailList:detailList,
          folderDetails:props.folder,
          currentTaskName:props.currentTask,
          detailKeyName:detailKeyFieldName,
          currentFolderId:mainFolderId.value,
          txn:props.txnToUse
        }

        //generate the txnToRun with txn and fieldsData
        const { generateTxn } = makeTxn(params)
        const txnToRun = generateTxn();
        txnToRun.session_key = sessionId;
        txnToRun.target_swhandle = bSettings.value.env?.user_selected_partner?.[0]?.code
        return txnToRun;
      }
      catch (err) {
        console.log(err);
      }
    }

    //on clicking save run the transaction
    async function saveHandle(data, txnType) {
      try {
        console.log('test test save handle')
        formSaveClicked.value = true
        //check if mandatory fields are filled if not stop txn
        const dataToSave = data ? data : formData.value
        listIcon.value = 'bi-list'
        if (missingFields?.value.length > 0) {
          incompleteForm.value = true
        }
        else if (invalidFields.value.length > 0) {
          inValidFieldsPresent.value = true
        }
        else {
          incompleteForm.value = false
          inValidFieldsPresent.value = false
        }
        //if mandatoryfields not missing
        if (incompleteForm.value && props.currentTask === 'create') {
          toast.error('please check all the fields')
        }
        else if (inValidFieldsPresent.value) {
          toast.error('please correct the invalid inputs in fields')
        }
        else {
          // formData.mainFolderId = mainFolderId.value
          //if formObject is found
          //get the form object that is needed
          //close the modal
          notificationId.value = toast.loading('working on it...', {
            position: toast.POSITION.TOP_CENTER,
          })
          // formOpen.value = false
          if (formObject.value !== undefined) {
            const txnToRun = getTxnToRun();
            const list = await sendMessage(txnToRun)
            context.emit('change-form-state', false)
            await handleResult(list)
          }
          //if no formObject found for the currentTxn then make useful groups differently
          else {
            const { fieldsData, docpList, detailList, detailKeyFieldName } =
              getOtherFormDataGroup(dataToSave, props.folder, txnType)
            //generate the txnToRun with txn and fieldsData
            const params = {
              normalFieldList:fieldsData,
              docpList:docpList,
              detailList:detailList,
              folderDetails:props.folder,
              currentTaskName:props.currentTask,
              detailKeyName:detailKeyFieldName,
              txnType:txnType,
              currentFolderId:mainFolderId.value,
            }
            const { generateTxn } = makeTxn(params)
            const txnToRun = generateTxn()
            //if txn to run is not found (some folders dont have update) show error
            if (!txnToRun || txnToRun === undefined) {
              toast.error('sorry folder cant be updated by you')
            } else {
              const  list = await sendMessage(txnToRun)
              await handleResult(list)
            }
          }
        }
      } catch (err) {
        throw err
      }
    }

    function handleClick(name) {
      if (!isEditNotAllowed.value) {
        saveHandle()
      }
    }
    //shortCut keys


    onMounted(() => {
      //on mount get the leavesQuota to show on form if employees id is found
      // if (showLeaveQuota.value && currentEmpId.value) {
      //   getLeavesQuota(currentEmpId.value)
      // }
    })
    return {
      addRefByTab,
      listIcon,
      showRef,
      //isUser,
      refByDataTestId,
      handleClick,
      actionsToPerform,
      performActionModal,
      expandTheList,
      showOnlyMandatoryFields,
      mandatoryOnlyIconColor,
      listExpanded,
      missingFields,
      scrollToTheField,
      formKey,
      deleteConfirmation,
      dependencyDelete,
      cancelDelete,
      showLeaveQuota,
      mandatoryFilter,
      loadRefBy,
      leavesQuota,
      currentEmpName,
      isEditNotAllowed,
      invoiceApprovalCase,
      applyBusinessFilter,
      showBusinessesFilter,
      getTxnToRun,
      refreshTabData
      //selectRefBy
    };

  }};
</script>
<style>
.dropdown-container-class{
   text-transform: capitalize;
   padding:3px;
   background-color: var(--refBy-background);
   color:var(--refBy-color) !important;
   border-radius: 6px;
   font-size:12px;
   padding-left:8px;
   font-weight: 600;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   padding-right:8px;
}
</style>

<style scoped>
.toolbar-class{
  background-color: var(--toolbar-color);
  padding-top:30px;
  padding-bottom:30px;
  margin-top: 0px;

}

.update-btn {
     color: #6faa5dff;
     color: white;
     border: 1px solid white;
     height: fit-content;
     font-weight: 600;
     font-size: 12px;
     margin:10px 20px;
     border-radius: 8px;
    box-shadow: 1px 1px #2e688dff
}

.update-btn:hover {
     color: white;
     border: 1px solid white;
     background-color: var(--co-main-color);
 }
 .propsheet-container{
width: 100%;
padding:20px;
height: 78vh;
margin-bottom: 10px;
padding-bottom:10px;
overflow-y: scroll;
background:var(--main-background-color);
border-bottom-right-radius: 8px;
border-bottom-left-radius: 8px;
}

.footer-content{
  background-color: var(--main-background-color);
  padding:10px;
}

.leaves-info{
  margin-top:50px;
  color:var(--co-main-color);
  font-weight: 600;
  padding:300px;
}

</style>
