import { createRouter, createWebHashHistory } from 'vue-router';
import UserAuthentication from '../views/user-authentication/UserAuthentication.vue';
import Home from '../views/Home.vue';
// import List from "../views/wfm-folders/FolderList.vue";
import ProfileInfo from '../views/settings/ProfileInfo.vue';
import Workflow from '../views/settings/Workflow.vue';
import Settings from '../views/settings/Settings.vue';
import AttendanceGrid from '../views/attendance/AttendanceGrid.vue';
import AllocationGrid from '../views/allocation/AllocationGrid.vue';
import BulkGenerate from '../views/quick-actions/BulkGenerate.vue';
import Reportsview from '../views/reports/ReportsView.vue';
import TabsPage from '../views/wfm-folders/TabsPage.vue';
import dutyChartGrid from '../components/duty_charts_ag_grid.vue';

import store from '../../store/index';
import getFolders from '../composables/getFolders';
import attendanceGridUtils from '../composables/attendanceGridUtils';
import allocationGridUtils from '../composables/allocationGridUtils';
import BusinessSettings from '../views/settings/BusinessSettings.vue';
import usePartnersId from '../composables/usePartnersId';
const routes = [
  {
    path: '/user-auth',
    name: 'UserAuthentication',
    beforeEnter: checkLogin,
    component: UserAuthentication
  },
  {
    path: '/',
    name: 'Home',
    beforeEnter: guardRoute,
    component: Home
  },
  // {
  //   path: "/list/:name",
  //   name: "List",
  //   component: List,
  //   beforeEnter: guardRoute,
  // },
  {
    path: '/tabs/:name',
    name: 'tabs',
    component: TabsPage,
    beforeEnter: guardRoute,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileInfo,
    beforeEnter: guardRoute,
    props:true
  },
  {
    path:'/business_settings',
    name:'business_settings',
    component:BusinessSettings,
    beforeEnter: guardRoute,
    props:true
  },
  {
    path: '/workflow',
    name: 'workflow',
    component: Workflow,
    beforeEnter: guardRoute,
    props:true
  },

  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    beforeEnter: guardRoute,
    props:true
  },

  {
    path: '/generatePayslip',
    name: 'payslip',
    component: BulkGenerate,
    beforeEnter: guardRoute,
    props:true
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: AttendanceGrid,
    beforeEnter: guardRoute
  },
  {
    path: '/allocations',
    name: 'Allocation',
    component: AllocationGrid,
    beforeEnter: guardRoute
  },
  {
    path: '/generateInvoices',
    name: 'Invoices',
    component: BulkGenerate,
    beforeEnter: guardRoute
  },
  {
    path:'/reports-view/:name',
    name:'Reportsview',
    component:Reportsview,
    beforeEnter:guardRoute
  },
  {
    path:'/duty-charts',
    name:'dutyCharts',
    component:dutyChartGrid,
    beforeEnter:guardRoute
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

function guardRoute(to, from, next) {
  const isAuthenticated = store.getters['userModule/isLoggedIn'] ? true : false;
  if (isAuthenticated) {
    next()
  }
  else {
    next('/user-auth')
  }
}

//check if the user is logged in already
function checkLogin() {
  if (store.getters['userModule/isLoggedIn']) {
    router.push('/')
  }
}


router.beforeEach(async(to, from, next) => {
  const isAuthenticated = store.getters['userModule/isLoggedIn'] ? true : false;
  if (isAuthenticated) {
    const bSettings  = store.getters['bSettings'];
    console.log('BSETTING', bSettings);
    if (bSettings === null) {
      await store.dispatch('bSettingsSetter');
      const { getDocpList } = getFolders();
      const foldersDocpList = getDocpList();
      store.commit('folderModule/foldersDocpListMutation', foldersDocpList);
    }
    if (from.name === 'Attendance') {
      const editList = store.getters['agGridModule/gridEditsGetter'];
      if (editList.length > 0) {
        const answer = window.confirm('You have some unsaved changes. Do you want to save changes before going back?');
        if (answer) {
          const gridApi = store.getters['agGridModule/attendanceGridApi'];
          const { save } = attendanceGridUtils();
          await save(store, gridApi);
          next()
        } else {
          store.commit('agGridModule/gridEditsMutations', []);
          next();
        }
      }
    }
    if (from.name === 'Allocations') {
      const txnList = store.getters['agGridModule/gridTxnsGetter'];
      if (txnList.length > 0) {
        const answer = window.confirm('You have some unsaved changes. Do you want to save changes before going back?');
        if (answer) {
          const { saveAllocation } = allocationGridUtils();
          await saveAllocation(store);
          next()
        } else {
          store.commit('agGridModule/gridTxnsMutations', []);
          next();
        }
      }
    }
    //get partners list
    const {getPartnersList, getPePartnersId} = usePartnersId()
    await getPartnersList()
    if (bSettings != null) {
      bSettings.env.pe_partners_id = getPePartnersId()

    }
  }
  return next();
})

export default router;
