<template>
  <div style="margin-top:12px; margin-left:5px">
    <WfmButton
      button-class="update-btn"
      @button-click="openInvoiceApprovalUI()"
    >
      <div v-if="isPe">
        <v-icon name="fa-eye" /> Review Documents
      </div>
      <div v-else>
        <v-icon name="fa-upload" /> Upload Documents
      </div>
    </WfmButton>
  </div>
</template>

<script>
import WfmButton from '../../common/wfm-button.vue';
import getFolders from '../../composables/getFolders';
import getFormInfo from '../../composables/getFormInfo'
import { ref } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'WfmBaseRowActions',
  components: {
    WfmButton,
  },
  props: {
  },
  emits:['open-invoice-approval-ui'],
  setup(props, context) {
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const { getFormObject, getCurrentTxn } = getFormInfo()
    const store = useStore();
    const bSettings = ref(store.getters['bSettings']);
    const isPe = bSettings.value.env?.partner_groups.indexOf('pe') > -1;
    function openInvoiceApprovalUI() {
      const currentTaskName = 'update'; //right now form handling in client side code is hardcoded with only three general txns(create, update and delete)
      const folderList = getAllFoldersList(bSettings.value);
      const folder = getCurrentFolder('invoices', folderList);
      const currentTxn = getCurrentTxn(currentTaskName, folder);
      const formName = currentTxn.form.form_name != null ? currentTxn.form.form_name : currentTxn.form
      const formObject = getFormObject(formName, folder)
      const tabSpecParams = {
        rowData: JSON.parse(JSON.stringify(props.params.data)),
        rowNode:  props.params.api.getSelectedNodes(),
        fieldsToDisplay: formObject,
        txnToUse: currentTxn,
        formObjectFound: true,
        folder: folder,
        currentTask: currentTaskName,
        readOnly: false,
        agGridApi: props.params.api
      }
      props.params.colDef.openInvoiceApprovalUI({ currTxn: currentTxn, currRowId: props.params.data.id, tabSpecParams });
    }
    return {
      openInvoiceApprovalUI,
      isPe
    };
  }
};
</script>

<style>
</style>
