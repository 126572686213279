import { createApp } from 'vue'
import App from './App.vue'
import router from '../src/router'
import store from '../store'
import { setupConnection } from '../src/services/websocket';


//bootstrap css only
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

//npm i18n for translation
import i18n from '../src/common/translation'

import vSelect from 'vue-select';

//npm vue-datepicker for all purpose date picker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

//bootstrap icons
import {
  BiArrowRepeat, BiCalendar2Plus, BiCalendar3, BiCalendarMinus, BiCalendarX, BiCaretDown, BiCaretLeftFill, BiCaretRightFill, BiCaretUp, BiCheck, BiCheck2Circle, BiCheckAll, BiCheckCircle, BiCheckCircleFill, BiClipboard2Data, BiClipboard2DataFill, BiCurrencyExchange, BiDownload, BiEye, BiEyeFill, BiEyeSlash, BiEyeSlashFill, BiFileEarmarkSpreadsheet, BiFiletypePdf, BiFiletypeXlsx, BiFlag, BiFolderFill, BiInfoCircle, BiList, BiListNested, BiPatchExclamation, BiPeopleFill, BiPersonBadge, BiPersonCircle, BiPersonWorkspace, BiPlusCircle, BiPlusCircleFill, BiPlusSlashMinus, BiPower, BiPrinter, BiPrinterFill, BiQuestionCircle, BiReceipt, BiSearch, BiSortAlphaDown, BiSortAlphaUp, BiTable, BiThreeDotsVertical, BiTranslate, BiTrash, BiX, BiXCircle, BiXCircleFill, BiArrowUpSquare
} from 'oh-vue-icons/icons/bi'
import {
  FaBan, FaBed, FaBolt, FaCashRegister, FaCheckCircle, FaClock, FaCopy, FaExternalLinkAlt, FaFileContract, FaFileInvoiceDollar, FaFileUpload, FaFilter, FaHospitalUser, FaIdCardAlt, FaMoneyCheckAlt, FaNetworkWired, FaPiggyBank, FaReceipt, FaRegularEdit, FaRoute, FaSitemap, FaSort, FaSortAlphaDown, FaSortAlphaUp, FaSpinner, FaTasks, FaTimes, FaTrash, FaTv, FaUmbrellaBeach, FaUpload, FaEye
} from 'oh-vue-icons/icons/fa'
import {
  MdAddcircleOutlined, MdArrowdropdown, MdArrowdropdownSharp, MdArrowdropup, MdAttachfileTwotone, MdCancelRound, MdCloseRound, MdDevices, MdEditcalendarSharp, MdFilteraltoff, MdFilteraltSharp, MdFirstpage, MdGradeOutlined, MdGroups, MdHelpOutlined, MdInsertdrivefile, MdLastpage, MdNavigatebefore, MdNavigatenext, MdPersonadd, MdReportgmailerrorred, MdSearchoffSharp, MdSearchSharp, MdSendtomobile, MdStickynote2Round, MdAddcircleoutline, MdGroupaddOutlined, MdPhonelinkTwotone, MdCalculateOutlined
} from 'oh-vue-icons/icons/md'
import {
  RiMailSendFill, RiMailSendLine, RiOrganizationChart
} from 'oh-vue-icons/icons/ri'
import { GiCeilingLight } from 'oh-vue-icons/icons/gi'

addIcons(
  BiArrowRepeat, BiCalendar2Plus, BiCalendar3, BiCalendarMinus, BiCalendarX, BiCaretDown, BiCaretLeftFill, BiCaretRightFill,
  BiX, BiXCircle, BiXCircleFill, BiEyeSlash,
  BiCaretUp, BiCheck, BiCheck2Circle, BiCheckAll, BiCheckCircle, BiCheckCircleFill, BiClipboard2Data, BiClipboard2DataFill,
  BiCurrencyExchange, BiDownload, BiEye, BiEyeFill, BiEyeSlashFill, BiFileEarmarkSpreadsheet, BiFiletypePdf,
  BiFiletypeXlsx, BiFlag, BiFolderFill, BiInfoCircle, BiList, BiListNested, BiPatchExclamation, BiPeopleFill,
  BiPersonBadge, BiPersonCircle, BiPersonWorkspace, BiPlusCircle, BiPlusCircleFill, BiPlusSlashMinus, BiPower,
  BiPrinter, BiPrinterFill, BiQuestionCircle, BiReceipt, BiSearch, BiSortAlphaDown, BiSortAlphaUp, BiTable,
  BiThreeDotsVertical, BiTranslate, BiTrash, FaBan, FaBed, FaBolt, FaCashRegister,
  FaCheckCircle, FaClock, FaCopy, FaExternalLinkAlt, FaFileContract, FaFileInvoiceDollar, FaFileUpload, FaFilter,
  FaHospitalUser, FaIdCardAlt, FaMoneyCheckAlt, FaNetworkWired, FaPiggyBank, FaReceipt, FaRegularEdit, FaRoute,
  FaSitemap, FaSort, FaSortAlphaDown, FaSortAlphaUp, FaSpinner, FaTasks, FaTimes, FaTrash, FaTv, FaUmbrellaBeach,
  MdEditcalendarSharp, MdFilteraltoff, MdFilteraltSharp, MdFirstpage, MdGradeOutlined, MdGroups, MdHelpOutlined,
  MdInsertdrivefile, MdLastpage, MdNavigatebefore, MdNavigatenext, MdPersonadd, MdReportgmailerrorred,
  MdSearchoffSharp, MdSearchSharp, MdSendtomobile, MdStickynote2Round,
  MdArrowdropdown, MdArrowdropdownSharp, MdArrowdropup, MdAttachfileTwotone, MdCancelRound, MdAddcircleOutlined, MdAddcircleoutline, MdGroupaddOutlined, MdCloseRound, MdDevices, MdSearchSharp,
  RiMailSendFill, RiMailSendLine, RiOrganizationChart, GiCeilingLight, MdPhonelinkTwotone, MdCalculateOutlined, BiArrowUpSquare, FaUpload, FaEye
)
//npm oh-vue icons for customizable icons
import { OhVueIcon, addIcons } from 'oh-vue-icons'
//vuetify adding but styles mixes
//npm for advance toast notification
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import {VOtpInput, VStepper, VTooltip} from 'vuetify/components'
const vuetify = createVuetify({
  components: {
    VOtpInput,
    VStepper,
    VTooltip
  }})

setupConnection();

const app = createApp(App)
app.component('VSelect', vSelect);
app.component('VueDatePicker', VueDatePicker)
app.component('VIcon', OhVueIcon)
app.use(i18n)
app.use(vuetify)
app.use(store)
app.use(Vue3Toastify)
app.use(router).mount('#app')
