<template>
  <div :class="[isRegisterView ? registerViewClass : '', defaultClass]">
    <img
      :src="require('../../assets/images/saralweb-icon-512x512.png')"
      placeholder="img"
    />
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'WfmLoader',
  setup(props) {
    const defaultClass = ref('wfm-loader');
    const registerViewClass = ref('register-view')
    const isRegisterView = ref(false);
    if (props?.params?.isRegisterView !== undefined && props?.params?.isRegisterView !== null) {
      isRegisterView.value = props.params.isRegisterView;
    }
    return {
      isRegisterView,
      defaultClass,
      registerViewClass
    };
  },
};
</script>

<style>
.register-view {
  top: auto !important;
  left: auto !important;
}

.wfm-loader {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 5px solid rgba(0, 128, 0, 0.7);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.wfm-loader:before {
  content: "";
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border-top: 5px solid #f6f6f6;
  position: absolute;
  animation: rt 1.2s infinite;
  top: -5px;
  left: -5px;
}

@keyframes rt {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wfm-loader img {
  width: 75px;
  height: 75px;
  position: fixed;
  padding-top: 2px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
