<template>
  <WfmToolbar
    custom-class="action-list p-4 ps-2 pe-1"
    :data-test-id="'generateTxn'"
  >
    <template #left-utility>
      <WfmActionsList
        :actions="actionsToPerform.length>0?actionsToPerform:null"
        :data-test-id="'generateTxn'"
        @action-click="performAction"
      />
    </template>
    <template #right-utility>
      <div class="d-flex">
        <div>
          <WfmButton
            button-class="update-btn"
            @button-click="generateTxn()"
          >
            {{ currentTask + ' ' + folder.name }}
          </WfmButton>
        </div>
      </div>
    </template>
  </WfmToolbar>
  <!-- show the form -->
  <div class="body-container">
    <WfmRadioButton
      v-if="folder.name=='payslips'"
      :choices="radioOptions"
      @handle-change="selectionChanged"
    />
    <div class="row">
      <div class="col-sm-10">
        <WfmFieldGroup
          :key="formKey"
          :params="filledParams"
          :fields-to-display="fieldsToDisplay"
          :form-object-found="true"
          current-task="create"
          :base-folder="folder"
        />
      </div>
    </div>
    <WfmProgressLoader
      v-if="payslipsGenerationStarted && !allProcessDone && folder.name!='invoices'"
      :folder-name="folder.name"
      @all-process-done="handleAllProcessDone"
      @generated="handleGenerated"
    />
    <div
      class="containers"
    >
      <div class="text-success">
        <h5>
          {{ allProcessDone? 'Generation Complete for' : 'Generating' }}
          {{ totalCount>0?totalCount:'' + folder.name }}
        </h5>
        <h6>({{ periodEntered }})</h6>
      </div>
      <div class="mt-3 table-container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th
                v-if="folder.name=='payslips'"
                scope="col"
              >
                Employee Name
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                Message
              </th>
              <th scope="col">
                {{ folder.name }}
              </th>
            </tr>
          </thead>
          <tbody
            ref="listBody"
            class="list-body"
          >
            <tr
              v-for="(eachSlip, index) in generatedData"
              :key="index"
            >
              <th
                scope="row"
                class="text-center"
              >
                {{ index + 1 }}
              </th>
              <td
                v-if="folder.name=='payslips'"
                class="text-center"
              >
                <div v-if="eachSlip.status=='success'">
                  {{ getPayslipName(eachSlip.data) }}
                </div>
                <div v-else>
                  {{ eachSlip?.data?.employee_name }}
                </div>
              </td>
              <td class="text-center">
                <div>{{ eachSlip.status?.[0]?.code || eachSlip?.status }}</div>
              </td>
              <td
                class="text-center"
                :class="{
                  'text-danger': eachSlip.status=='error',
                  'text-success': eachSlip.status=='success',
                }"
              >
                <div>{{ eachSlip.msg }}</div>
              </td>
              <td class="text-center">
                <v-icon
                  v-if="eachSlip.status === 'error'"
                  name="md-reportgmailerrorred"
                  fill="red"
                  scale="1.3"
                />
                <v-icon
                  v-else
                  name="fa-receipt"
                  fill="black"
                  scale="1.3"
                  @click="openGeneratedSlip(eachSlip)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import WfmToolbar from '../../common/wfm-toolbar.vue';
import WfmFieldGroup from '../wfm-folders/wfm-fieldGroup.vue';
import {computed, ref, provide} from 'vue'
import {keys} from '../../provideKeys.js'
import getFormInfo from '../../composables/getFormInfo';
import WfmButton from '../../common/wfm-button.vue';
import store from '../../../store';
import { sendMessage } from '../../services/websocket';
import { toast } from 'vue3-toastify';
import getFatRow from '../../composables/fatRowGenerator'
import getConverters from '../../composables/getConverters';
import getFolders from '../../composables/getFolders';
import postData from '../../composables/postData';
import WfmRadioButton from '../../common/wfm-radio-button.vue';
import WfmActionsList from '../../common/wfm-actions-list.vue';
import useIcons from '../../composables/getIcons'
import WfmProgressLoader from '../../common/loader/wfm-progress-loader.vue';
import getProjections from '../../composables/getProjections.js';
export default {
  name: 'BulkGenerate',
  components: {
    WfmToolbar,
    WfmFieldGroup,
    WfmButton,
    WfmRadioButton,
    WfmActionsList,
    WfmProgressLoader
  },
  props:{
    folder:{
      type:Object,
      default:null
    },
    currentTask:{
      type:String,
      default:null
    },
    agGridApi:{
      type:String,
      default:null
    }

  },
  emits:['add-tab'],
  setup(props, context) {
    //store
    const {projectionForFolder} = getProjections()
    const generatedData = ref([])
    const payslipsGenerationStarted = ref(false)
    const allProcessDone = ref(false)
    const formData = ref({})
    const totalCount = ref(0)
    const currentCount = ref(0)
    const employeesSelected = ref(true)
    const departmentsSelected = ref(false)
    const empTypeSelected = ref(false)
    const {getIcon} = useIcons()
    const formKey = ref(0);
    const filledParams = ref({})
    const listBody = ref()
    const { getAllFoldersList, getCurrentFolder } = getFolders();
    const bSettings = ref(store.getters['bSettings']);

    const employeesList = computed(() => {
      return formData.value['employees_id']?.value
    })

    provide(keys.formData, formData)
    const notificationId = ref()
    const periodEntered = computed(() => {
      const fieldName = props.folder.name == 'payslips' ? 'pay_period' : 'invoice_period'
      const payPeriodSQl = formData.value[fieldName]?.value
      let startDate = ''
      let endDate = ''
      const {SQLTsRangeToArray} = getConverters();
      if (payPeriodSQl != null) {
        const payPeriod = JSON.stringify(SQLTsRangeToArray(payPeriodSQl, 'UTC', true))
        const payPeriodArrary = JSON.parse(payPeriod)
        startDate = new Date(payPeriodArrary[0]).toDateString()
        endDate = new Date(payPeriodArrary[1]).toDateString()
      }

      return `${startDate}-${endDate}`
    })
    const radioOptions = computed(() => {
      return {
        name:'Generate Payslips For',
        options:[
          {
            name:'Department',
            checked:false
          },
          {
            name:'Employees Name',
            checked:true
          },
          {
            name:'Employee Type',
            checked:false
          }
        ]
      }
    })


    const {getCurrentTxn, getFormObject} = getFormInfo()

    const currentTxn = computed(() => {
      return getCurrentTxn(props.currentTask, props.folder)
    })

    function touchFormTest() {
      formKey.value = Math.floor((Math.random() * 10000) + 1);
    }

    function scrollToEnd() {
      if (listBody.value && listBody.value.lastElementChild) {
        listBody.value.lastElementChild.scrollIntoView({ behavior: 'smooth' });
      }
    }

    const fieldsToDisplay = computed(() => {
      const formName = currentTxn.value?.form?.form_name || currentTxn.value?.form;
      const fields = getFormObject(formName, props.folder);
      const folderName = props.folder.name
      if (folderName === 'payslips') {
        const exclusionMap = {
          employeesSelected: ['payslips.departments_id', 'payslips.employee_types'],
          departmentsSelected: ['payslips.employees_id', 'payslips.employee_types'],
          empTypeSelected: ['payslips.employees_id', 'payslips.departments_id']
        };
        return fields.filter((field) => {
          const path = field.path;
          return !(
            (employeesSelected.value && exclusionMap.employeesSelected.includes(path)) ||
      (departmentsSelected.value && exclusionMap.departmentsSelected.includes(path)) ||
      (empTypeSelected.value && exclusionMap.empTypeSelected.includes(path))
          );
        });
      }
      else {
        return fields
      }

    });
    function handleGenerated(txnResponse) {
      const retVal = txnResponse?.ctrl
      const statusType = retVal?.output?.type
      const msg = retVal?.output?.message
      currentCount.value = retVal?.count_start
      totalCount.value = retVal?.count_end
      const currentRecord = retVal?.output?.data?.records
      const records = []
      if (currentRecord != null) {
        store.state.gen_payslips = true;
        for (let i = 0; i < currentRecord.length; i++) {
          records.push(JSON.parse(retVal.output.data.records[i]));
        }
        retVal.output.data.records = records;
        generatedData.value.push({status:statusType, data:retVal, msg:`${props.folder.name} Generated`})
        scrollToEnd()
      }
      else {
        const dataToPass = txnResponse?.ctrl?.params
        //keeping for now as employee name is not coming in the list of failed records,
        //when employees names shows we can display

        generatedData.value.push({status:statusType, data:dataToPass, msg: msg?.split(':').length > 1 ? msg?.split(':')[1] : msg })
        scrollToEnd()


      }

    }
    function handleAllProcessDone(val) {
      allProcessDone.value = val
    }

    function performAction(action) {
      action()
    }
    function getPayslipName(retVal) {
      const records = retVal.output.data.records || [];
      const employeeRowIdx = records.findIndex((rec) => rec._path == 'business.folders.employees') + 1;
      const personRowIdx = records.findIndex((rec) => rec._path == 'business.folders.persons') + 1;
      const firstName = records[personRowIdx]?.first_name?.eng || '';
      const middleName = records[personRowIdx]?.middle_name?.eng || ''
      const lastName = records[personRowIdx]?.last_name?.eng || ''
      const code = records[employeeRowIdx]?.code
      return firstName + ' ' + middleName + ' ' + lastName + ' (' + code + ')';
    }
    function openGeneratedSlip(retVal) {
      let slipData = null
      if (props.folder.name == 'invoices') {
        slipData = retVal
      }
      else if (props.folder.name == 'payslips') {
        const data = retVal.data
        const { fatRowGenerator } = getFatRow()
        slipData = fatRowGenerator(data)[0];
      }

      const employeeName = slipData?.employees_id?.[0]?.persons_id[0]?.first_name?.eng || slipData?.contracts_id?.[0]?.name?.eng
      const payrollMonth = slipData?.payroll_month || slipData?.invoice_month
      const monthOfSlip = new Date(payrollMonth).toLocaleString('default', { month: 'short' });
      const yearOfSlip = new Date(payrollMonth).getFullYear()
      console.log('test', slipData, employeeName, payrollMonth, monthOfSlip, yearOfSlip)
      const tabsSpec = {
        label: employeeName,
        component:'HtmlTemplate',
        name:`${employeeName} ${monthOfSlip}/${yearOfSlip}`,
        params:{
          readOnly :false,
          folder:props.folder,
          data:slipData
        }
      }
      context.emit('add-tab', tabsSpec)
    }
    function selectionChanged(name) {
      payslipsGenerationStarted.value = false
      if (name === 'Department') {
        departmentsSelected.value = true,
        employeesSelected.value = false,
        empTypeSelected.value = false
      }
      else if (name === 'Employees Name') {
        employeesSelected.value = true,
        departmentsSelected.value = false,
        empTypeSelected.value = false

      }
      else if (name === 'Employee Type') {
        employeesSelected.value = false,
        departmentsSelected.value = false,
        empTypeSelected.value = true

      }

      touchFormTest()
      for (var key in formData.value) {
        if (key == 'pay_period') {
          filledParams.value[key] = formData.value[key].value
        }
      }
      generatedData.value = []
      currentCount.value = 0
      totalCount.value = 0
    }

    function getLastDateTwoMonthsBeforeUTC(input) {
      // Parse the input string which is expected in "YYYY-MM-DD HH:mm:ss" format (UTC).
      const [datePart, timePart] = input.split(' ');
      const [yearStr, monthStr, dayStr] = datePart.split('-');
      const [hourStr, minuteStr, secondStr] = timePart.split(':');

      const year = Number(yearStr);
      const month = Number(monthStr); // 1-12
      const day = Number(dayStr);
      const hour = Number(hourStr);
      const minute = Number(minuteStr);
      const second = Number(secondStr);

      // Create a Date object from the input assuming UTC.
      // Note: subtract 1 from month because JavaScript Date months are 0-indexed.
      const inputDateUTC = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

      // Subtract 1 month.
      // getUTCMonth() returns 0-indexed month, so subtract 1.
      const targetMonth = inputDateUTC.getUTCMonth() - 1;
      const targetYear = inputDateUTC.getUTCFullYear();

      // Date.UTC handles underflow correctly.
      // To get the last date of the target month, we can construct a date for the 0th day of the following month.
      const lastDateTargetMonth = new Date(Date.UTC(targetYear, targetMonth + 1, 0));

      // If the month subtraction caused a year change (for example, input month February returns targetMonth = -1),
      // the Date.UTC computation correctly gives December of the previous year.

      // Set the time to 18:30:00 UTC.
      lastDateTargetMonth.setUTCHours(18, 30, 0, 0);

      // Format the result components ensuring two-digit month/day etc.
      const formattedYear = lastDateTargetMonth.getUTCFullYear();
      const formattedMonth = String(lastDateTargetMonth.getUTCMonth() + 1).padStart(2, '0');
      const formattedDay = String(lastDateTargetMonth.getUTCDate()).padStart(2, '0');
      const formattedHour = String(lastDateTargetMonth.getUTCHours()).padStart(2, '0');
      const formattedMinute = String(lastDateTargetMonth.getUTCMinutes()).padStart(2, '0');
      const formattedSecond = String(lastDateTargetMonth.getUTCSeconds()).padStart(2, '0');

      return `${formattedYear}-${formattedMonth}-${formattedDay} ${formattedHour}:${formattedMinute}:${formattedSecond}`;
    }

    function convertDateRangeStrIntoDateStr(dateRangeStr) {
      // Use a regular expression to extract the first date string within double quotes.
      const match = dateRangeStr.match(/"([^"]+)"/);
      if (!match) {
        throw new Error('Invalid input format');
      }
      const firstDateStr = match[1];

      // Create a Date object from the first date string.
      const date = new Date(firstDateStr);

      // Format using UTC getters.
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-indexed.
      const day = String(date.getUTCDate()).padStart(2, '0');
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      const seconds = String(date.getUTCSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    async function validatePrevInvoice(params) {
      try {
        let res = false;
        const folderList = getAllFoldersList(bSettings.value);
        const currentDocpFolder = getCurrentFolder('invoices', folderList);
        const { listData } = postData();
        const txnParams = { refRows: true };
        txnParams.filters = {
          'filterInput': [
            {
              'path': 'invoices.contracts_id.id',
              'operator': '=',
              'value': params.contracts_id[0]
            },
            {
              'path': 'invoices.invoice_month',
              'operator': '=',
              'value': getLastDateTwoMonthsBeforeUTC(convertDateRangeStrIntoDateStr(params.invoice_period))
            },
          ],
          'joinop': 'and'
        }
        txnParams.projections = {
          'invoices': ['id', 'approval_documents', 'partners'],
          'invoices.approval_documents': ['id', 'doc_name', 'status'],
          'invoices.approval_documents.doc_name': ['id', 'code'],
          'invoices.approval_documents.status': ['id', 'code'],
          'invoices.partners': ['id', 'invoice_compl_docs'],
          'invoices.partners.invoice_compl_docs': ['id', 'doc_name', 'restr_lvl'],
          'invoices.partners.invoice_compl_docs.doc_name': ['id', 'code'],
          'invoices.partners.invoice_compl_docs.restr_lvl': ['id', 'code'],
        }

        const list = await listData(currentDocpFolder, txnParams);
        if (list.output.type === 'error') {
          console.log(list.output.message);
        }
        else {
          if (list.output?.data?.records?.length > 0) {
            const { fatRowGenerator } = getFatRow();
            const fatRows = fatRowGenerator(list);
            if (fatRows.length > 0) {
              const approvalDocs = fatRows[0].approval_documents;
              const partnerComplDocs = fatRows[0].partners[0].invoice_compl_docs;
              const remBlockingDocs = approvalDocs.filter((ad) => partnerComplDocs.findIndex((pcd) => pcd.doc_name[0].code == ad.doc_name[0].code && pcd.restr_lvl[0].code == 'block' && ad.status[0].code == 'pending') > -1);
              if (remBlockingDocs.length > 0) {
                toast.error(`${remBlockingDocs.length} document(s) is/are not uploaded yet for previous month's invoice!`, {
                  position: 'top-center',
                  autoClose: 10000
                });
                res = true;
              }
              else {
                const remWarningDocs = approvalDocs.filter((ad) => partnerComplDocs.findIndex((pcd) => pcd.doc_name[0].code == ad.doc_name[0].code && pcd.restr_lvl[0].code == 'warn' && ad.status[0].code == 'pending') > -1);
                if (remWarningDocs.length > 0 && !confirm(`${remWarningDocs.length} document(s) is/are not uploaded yet for previous month's invoice, proceed?`)) {
                  res = true;
                }
              }
            }
          }
        }
        return res;
      }
      catch (err) {
        throw err;
      }
    }

    async function generateTxn() {
      try {
        const params = {};
        const folderName = props.folder.name;
        //using the form Data to prepare the params for txn.
        Object.keys(formData.value).forEach((key) => {
          const field = formData.value[key];
          if (field?.value) {
            if (Array.isArray(field.value) && field.value[0]?.id) {
              params[key] = field.value.map((each) => each.id);
            } else {
              params[key] = field.value;
            }
          }
        });
        params.projections = projectionForFolder(`${folderName}_generate`);
        if (folderName == 'invoices') {
          const shouldNotProceed = await validatePrevInvoice(params);
          if (shouldNotProceed) {
            return;
          }
        }
        allProcessDone.value = false;
        notificationId.value = toast.loading(`generating ${props.folder.name}...`, {
          position: toast.POSITION.TOP_CENTER,
        });
        runTxn(currentTxn.value, params);
      } catch (error) {
        throw error;
      }
    }

    async function runTxn(txn, params) {
      try {
        const sessionId = store.getters['sessionIdGetter'];
        txn.params = params
        txn.session_key = sessionId
        const retVal = await sendMessage(txn)
        if (retVal.output.type == 'error') {
          toast.update(notificationId.value, {
            render: retVal.output.message,
            autoClose: 3000,
            closeButton: true,
            type: 'error',
            isLoading: false,
          });
        }
        else if (retVal.output.type == 'success') {
          payslipsGenerationStarted.value = true
          toast.update(notificationId.value, {
            render: `${props.folder.name} Generation Started..`,
            autoClose: 2000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
          if (props.folder.name == 'invoices') {
            const { fatRowGenerator } = getFatRow()
            const records = []
            for (let i = 0; i < retVal.output.data.records.length; i++) {
              records.push(JSON.parse(retVal.output.data.records[i]))
            }
            retVal.output.data.records = records;

            generatedData.value = fatRowGenerator(retVal);
            generatedData.value.forEach((each, index) => {
              each.msg = 'Invoiced Created Successfully'
              props.agGridApi?.applyTransaction({
                add: [each]
              });
            });

          }
          // touchFormTest()
        }

      } catch (error) {
        throw error
      }


    }
    const actionsList = computed(() => {
      const retVal = {name:'Actions', actions:[]}
      const downloadAction =
        {
          name:'Download',
          icon:getIcon('Download')?.name,
          fill:getIcon('Download')?.fill,
          action:() => {
            console.log('download function add here')
          }
        }
      const sendMailAction = {
        name:'Send Mail',
        icon:getIcon('Send')?.name,
        fill:getIcon('Send')?.fill,
        action:() => {
          console.log('send mail function here')
        }
      }
      retVal.actions.push(downloadAction)
      retVal.actions.push(sendMailAction)
      return retVal
    })

    const actionsToPerform = computed(() => {
      const retVal = []
      retVal.push(actionsList.value)
      return retVal
    })

    return {
      actionsList,
      actionsToPerform,
      performAction,
      fieldsToDisplay,
      generateTxn,
      generatedData,
      payslipsGenerationStarted,
      openGeneratedSlip,
      employeesSelected,
      departmentsSelected,
      empTypeSelected,
      touchFormTest,
      formKey,
      periodEntered,
      radioOptions,
      selectionChanged,
      filledParams,
      getPayslipName,
      handleGenerated,
      employeesList,
      currentCount,
      totalCount,
      handleAllProcessDone,
      allProcessDone,
      listBody
    }
  }
}
</script>

<style scoped>
.table thead{
  position: sticky;
}

.list-body{
  text-align: left;
}
.list-header{
  text-align: left;

}
.containers{
  height: 75%;
  margin-top: 20px;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}
.table-container{
  height: fit-content;
  max-height: 72%;
  overflow-y: scroll;
}

.table-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.body-container{
  background-color: var(--main-background-color);
  height: 95%;
  padding:20px;
}

.update-btn {
    color: #6faa5dff;
    color: white;
    border: 1px solid white;
    height: fit-content;
    font-weight: 600;
    font-size: 12px;
    margin:10px 20px;
    border-radius: 8px;
    box-shadow: 1px 1px #2e688dff;

}

.update-btn:hover {
    color: white;
    border: 1px solid white;
    background-color: var(--co-main-color);
}

.action-list {
  color: white;
  background-color: var(--toolbar-color);
}


.glass {
  position: relative;
  display: inline-block;
  padding: 15px 25px;
  background-color: red;
  background-image: linear-gradient(green, lightgreen);
}

.glass:after {
  content: '';
  position: absolute;
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
}

</style>
