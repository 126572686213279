/* eslint-disable no-redeclare */
import store from '../../store';
import generatePatch from './generatePatch';
import foldersUtility from './foldersUtility';
import { v4 as uuidv4 } from 'uuid';

const makeTxn = (params) => {

  const {
    normalFieldList = [],
    docpList = [],
    detailList = [],
    folderDetails = {},
    currentTaskName = null,
    txnType = null,
    currentFolderId = null,
    txn = null
  } = params;

  const bSetting = store.getters['bSettings'];
  // const swHandle = store.getters["userModule/swHandle"]
  //get available txn in the current folder
  const txnAvailable = folderDetails.txns;
  const currentFolder = folderDetails.name;
  const folderType = folderDetails.foldertype;
  let baseFolder = null;

  baseFolder = `business.${folderType}.${currentFolder}`;

  //const baseFolder = `directory.${folderType}.${currentFolder}`
  let txnToRun = null;
  let txnOp = null;

  if (txnType === 'delete') {
    txnToRun = txn || txnAvailable[`txn_${currentFolder}_delete`];
    txnOp = 'D';
  } else {
    txnToRun = txn
    txnOp = currentTaskName === 'create' ? 'C' : 'U';
  }

  //final params data will be added here as it gets processed
  const finalData = {};
  const dataToDelete = []
  //adding the current folder id to the final data
  //TODO:-needed or not depending on txn type decide later
  finalData.id = currentFolderId ? currentFolderId : uuidv4();
  const personsId = uuidv4()
  const mainFolderId = finalData.id;
  //normalData to process and store normal Data(i.e not detail,not docp)
  let normalData = {};
  //docpData to process and store docps
  let docpData = {};

  //making prams for normal fields
  if (txnType === 'delete') {
    for (var i = 0; i < normalFieldList.length; i++) {
      const idToDelete = normalFieldList[i].id
      dataToDelete.push({data:{id:idToDelete}, op:'D'})

    }
  }
  else {
    for (const key in normalFieldList) {
      const mainFolder = normalFieldList[key]?.path ? normalFieldList[key].path.split('.')[0] : currentFolder;

      const folderId = normalFieldList[key].folderId ? normalFieldList[key].folderId : personsId

      //if mainfolder is same as currentfolder
      if (mainFolder === currentFolder) {
      //add the value of the normalFields to the final object
        if (key === 'deleted' && normalFieldList[key] == true) {
          txnOp = 'U'
        }
        if (normalFieldList[key].isBoolean) {
          finalData[key] = normalFieldList[key].value
        }
        else {
          finalData[key] = normalFieldList[key].value === 0 || normalFieldList[key].value ? normalFieldList[key].value : normalFieldList[key];

        }
        if (normalFieldList[key].value === null) {
          finalData[key] = null;
        }
      } else {
      //in cases like where employees is changing persons data
        normalData = { ...normalData, id:folderId, [key]: normalFieldList[key].value };
        finalData[mainFolder] = [
          {
            op: txnOp,
            data: normalData,
            key:folderId
          },
        ];
      }
    }
    //making params for docp
    for (const key in docpList) {
    //check if the docp in the currentFolder
      const txnOpToUse = txnOp;
      let fieldOp = txnOp;
      const mainFolder = docpList[key]?.path ? docpList[key].path.split('.')[0] : currentFolder;
      const folderId = docpList[key].folderId ? docpList[key].folderId : personsId;
      //now loop through the value of the docp formData to make params
      for (var i = 0; i < docpList[key].value.length; i++) {
      //check if we dealing with multidocp or not
      //if its multidocp.. there is no update just create or delete
      //if it is not thn there is create and update
        if (docpList[key].value[i].isMultipleDocp) {
          if (docpList[key].value[i].toAdd && !docpList[key].value[i].toRemove) {
            fieldOp = 'C'
            if (mainFolder === currentFolder) {
              //if the finalData[key] is empty add the new data,if not spread the old values and add the new data
              finalData[key] = !finalData[key] ? [{ op: fieldOp, data: { id: docpList[key].value[i].id } }] : [...finalData[key], {op:fieldOp, data:{id:docpList[key].value[i].id}}]
            } else {
              const newElement = { op: fieldOp, data: { id: docpList[key].value[i].id } }
              if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
                docpData = {
                  ...finalData[mainFolder][0].data,
                  id: folderId,
                  [key]: [...(finalData[mainFolder][0].data[key] || []), newElement]
                }
                finalData[mainFolder][0].data = docpData
              } else {
                docpData = {
                  ...docpData,
                  [key]: [...(docpData[key] || []), newElement]
                }
                finalData[mainFolder] = [
                  { op: txnOpToUse, data: { id: folderId, ...docpData } }
                ]
              }
            }
          }
          else if (docpList[key].value[i].toRemove && !docpList[key].value[i].toAdd) {
            fieldOp = 'D'
            const oldDocp = docpList[key].value[i].id
            if (mainFolder === currentFolder) {

              finalData[key] = !finalData[key]
                ? [{ op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp }]
                : [...finalData[key], { op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp }];
            } else {
              const newElement = { op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp };

              if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
                docpData = { ...finalData[mainFolder][0].data, id: folderId, [key]: [...finalData[mainFolder][0].data[key] || [], newElement] };
                finalData[mainFolder][0].data = docpData;
              } else {
                finalData[mainFolder] = [{ op: txnOpToUse, data: { id: folderId, [key]: [newElement] } }];
              }

            }
          }


        }
        else {
          //if not multi docp
          //if the docp is not set before
          if (docpList[key].value[i].docpNotSet) {
            fieldOp = 'C';
            if (mainFolder === currentFolder) {
              //if the finalData[key] is empty add the new data,if not spread the old values and add the new data
              finalData[key] = !finalData[key]
                ? [{ op: fieldOp, data: { id: docpList[key].value[i].id } }]
                : [...finalData[key], {op:fieldOp, data:{id:docpList[key].value[i].id}}]
            } else {
              // if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
              //   docpData = { ...finalData[mainFolder][0].data, id: folderId, [key]: [{ op: fieldOp, data: { id: docpList[key].value[i].id } }] };
              //   finalData[mainFolder][0].data = docpData;
              // } else {
              //   docpData = { ...docpData, [key]: [{ op: fieldOp, data: { id: docpList[key].value[i].id } }] };
              //   finalData[mainFolder] = [{ op: txnOpToUse, data: { id: folderId, ...docpData } }];
              // }

              const newElement = { op: fieldOp, data: { id: docpList[key].value[i].id }}

              if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
                docpData = {...finalData[mainFolder][0].data, id: folderId,
                  [key]: [...(finalData[mainFolder][0].data[key] || []), newElement]
                }
                finalData[mainFolder][0].data = docpData
              } else {
                docpData = {
                  ...docpData,
                  [key]: [...(docpData[key] || []), newElement]
                }
                finalData[mainFolder] = [
                  { op: txnOpToUse, key:folderId, data: { id: folderId, ...docpData } }
                ]
              }

              // const newElement = { op: fieldOp, data: { id: docpList[key].value[i].id } };

              // if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
              //   docpData = { ...finalData[mainFolder][0].data, id: folderId, [key]: [...(finalData[mainFolder][0].data[key] || []), newElement] };
              //   finalData[mainFolder][0].data = docpData;
              // } else {
              //   docpData = { ...docpData, [key]: [newElement] };
              //   finalData[mainFolder] = [{ op: txnOpToUse, data: { id: folderId, ...docpData } }];
              // }


            }
          }
          //if the docp is set before
          else {
            const oldDocp = docpList[key].value[i].oldDocpId
            // const deleteOp = "D"
            fieldOp = docpList[key].value[i].toBeDeleted ? 'D' : 'U'
            // const docpToDelete = {op:deleteOp, data:{id:oldDocp}}
            if (mainFolder === currentFolder) {

              finalData[key] = !finalData[key]
                ? [{ op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp }]
                : [...finalData[key], { op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp }];
            } else {
              // if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
              //   docpData = { ...finalData[mainFolder][0].data, id: folderId, [key]: [{ op: fieldOp, data:{id:docpList[key].value[i].id}, key:oldDocp }] };
              //   finalData[mainFolder][0].data = docpData;
              // } else {
              //   docpData = { ...docpData, [key]: [{ op: fieldOp, data:{id:docpList[key].value[i].id}, key:oldDocp}] };
              //   finalData[mainFolder] = [{ op: txnOpToUse, data: { id: folderId, ...docpData } }];
              // }
              const newElement = { op: fieldOp, data: { id: docpList[key].value[i].id }, key: oldDocp };

              if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
                docpData = { ...finalData[mainFolder][0].data, id: folderId, [key]: [...finalData[mainFolder][0].data[key] || [], newElement] };
                finalData[mainFolder][0].data = docpData;
              } else {
                finalData[mainFolder] = [{ op: txnOpToUse, key:folderId, data: { id: folderId,  [key]: [newElement] } }];
              }

            }
          }
        }

      }


    }
    //making params for detail
    for (const key in detailList) {
      const mainFolder = detailList[key]?.pathofDetail ? detailList[key].pathofDetail.split('.')[0] : currentFolder;
      let detailData = {};
      //detail primarykey and primary field

      //TODO:- was needed earlier,need and confirm and decide to keep or not
      const detailPrimaryKey = detailList[key].primaryKey
      const detailPrimaryKeyField = detailList[key].primaryKeyField;
      //path of the current detail field
      const pathofDetail = detailList[key].pathofDetail;
      //the folder in which detail table is located
      let detailFolder = null;
      const containerFolder = pathofDetail.split('.')[0];
      //if the field is not in the current folder , change the folder to source folder
      if (folderDetails.name !== containerFolder) {
        const sourceFolder = folderDetails.fields[containerFolder].source;
        const { changeWorkingFolder } = foldersUtility();
        detailFolder = changeWorkingFolder(sourceFolder, bSetting.output.data.records[0]);
      }
      //if it is from the current folder use the folderdetails that is passed
      else {
        detailFolder = folderDetails;
      }
      //traverse inside each detailList key to process eachrow of data entered
      for (var i = 0; i < detailList[key].length; i++) {
        const eachRow = detailList[key][i];
        //first condition: check if the row is newly added or existing row
        if (eachRow.newRowAdded) {
        //traverse eachrow of data entered to check each field
          for (const item in eachRow) {
          //second condition: check if the field is of docpicker(docpicker inside detail)
            if (detailFolder.fields?.[key]?.fields?.[item]?.type === 'docpicker') {
              if (eachRow[item] != null) {
                for (var j = 0; j < eachRow[item].length; j++) {
                  //check if its multidocpikcer in detail
                  if (eachRow[item][j].isMultipleDocp) {
                    //if its multidocp in detail for new added rows
                    const dstDocp = eachRow[item][j]?.id;
                    //as the docp in the detail is being created, sending our own txnop='C' to patch generator
                    detailData[item] = !detailData[item] ? [{ op: 'C', data: { id: dstDocp } }] : [...detailData[item], { op: 'C', data: { id: dstDocp } }];
                  }
                  else {
                    //if it is not multidocp
                    //dst of docp is the id we get in our selected docp dropdown
                    const dstDocp = eachRow[item][j]?.id;
                    //as the docp in the detail is being created, sending our own txnop='C' to patch generator
                    detailData[item] = [{ op: 'C', data: { id: dstDocp } }];
                  }
                }

              }

            }
            //if the field is not docpicker type then just keep it in the detailData with its field name as key
            else {
              if (item != 'newRowAdded' && item !== 'fieldType') {
                detailData = { ...detailData, [item]: eachRow[item] };
              }
            }
          }
          //we need to send the primary key of the detail table in the data
          const primaryKeyId = detailPrimaryKey || personsId
          detailData[detailPrimaryKeyField] = mainFolder === currentFolder ? (detailPrimaryKey || mainFolderId) : (detailPrimaryKey || personsId)
          const idOfDetail = eachRow.id;
          if (mainFolder == currentFolder) {
            if (finalData[key]) {
              finalData[key].push({ data: detailData, op: 'C' });
            } else {
              finalData[key] = [{ data: detailData, op: 'C' }];
            }
          } else {
            if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
              if (finalData[mainFolder][0].data[key] && finalData[mainFolder][0].data[key].length > 0) {
              // finalData[mainFolder][0].data.id = detailPrimaryKey;
                finalData[mainFolder][0].data[key].push({ data: { ...detailData, id: idOfDetail, [detailPrimaryKeyField]: primaryKeyId }, op: 'C' });
              }
              else {
              // finalData[mainFolder][0].data.id = detailPrimaryKey;
                finalData[mainFolder][0].data[key] = [{ data: { ...detailData, id: idOfDetail, [detailPrimaryKeyField]: primaryKeyId }, op: 'C' }];
              }

            } else {
              finalData[mainFolder] = [{
                op: 'U',
                key:detailPrimaryKey,
                data: {
                  id: detailPrimaryKey,
                  [key]: [
                    {
                      data: {
                        ...detailData,
                        id: idOfDetail,
                        [detailPrimaryKeyField]: detailPrimaryKey,
                      },
                      op: 'C',
                    },
                  ],
                },
              },
              ];
            }
          }
        }
        //if the row of detail table is not newly added (existing row)
        else {
          //traverse eachrow of data entered to check each field
          const toDelete = eachRow.deleted ? true : false
          for (const item in eachRow) {
          //check if its docpicker type
            if (detailFolder.fields?.[key]?.fields?.[item]?.type === 'docpicker') {
              let opTouse = toDelete ? 'D' : 'U';
              for (var j = 0; j < eachRow[item]?.length; j++) {
              //check if its multidocpikcer in detail
                if (eachRow[item][j].isMultipleDocp) {
                //if its multidocp in detail for new added rows
                  const dstDocp = eachRow[item][j]?.id || eachRow[item]?.[0].id;
                  const oldDocp = eachRow[item][j]?.oldDocpId ? eachRow[item][j].oldDocpId : dstDocp
                  if (eachRow[item][j].toAdd && !eachRow[item][j].toRemove) {
                  //if the item is to be added
                    opTouse = 'C';
                    detailData[item] = [...detailData[item], { op: opTouse, data: { id: dstDocp } }];
                  }
                  else if (eachRow[item][j].toRemove && !eachRow[item][j].toAdd) {
                  //if the item is to be removed
                    opTouse = 'D'
                    detailData[item] = [...detailData[item], { op: opTouse, data: { id: dstDocp }, key:oldDocp}];

                  }

                }
                else {
                //if it is not multidocp
                  const dstDocp = eachRow[item][j]?.id || eachRow[item]?.[0].id;
                  const oldDocp = eachRow[item][j]?.oldDocpId ? eachRow[item][j].oldDocpId : dstDocp
                  //in case of update if the docp of the field is not set earlier. it should have op:'C' while doing update
                  if (!eachRow[item][j] || eachRow[item][j]?.docpNotSet) {
                    opTouse = 'C';
                    detailData[item] = [{ op: opTouse, data: { id: dstDocp } }];
                  }
                  else {
                    detailData[item] = [{ op: opTouse, data: { id: dstDocp }, key:oldDocp}];
                  }

                }
              }
            } else {
              if (item != 'newRowAdded' && item != 'deleted' && item != 'fieldType') {
                detailData = { ...detailData, [item]: eachRow[item] };
              }
            }
          }
          //if the entry is not newly added add txnOp='U' along with other data
          detailData[detailPrimaryKeyField] = detailPrimaryKey || mainFolderId;
          const idOfDetail = eachRow.id;
          const useOp = toDelete ? 'D' : 'U'
          if (mainFolder == currentFolder) {
            if (finalData[key]) {
              finalData[key].push({ data: detailData, op: useOp, key:useOp === 'U' ? idOfDetail : null });
            } else {
              finalData[key] = [{ data: detailData, op: useOp, key:useOp === 'U' ? idOfDetail : null }];
            }
          } else {
          // finalData[mainFolder][key].push({data,op:testOp})
            if (finalData[mainFolder] && finalData[mainFolder].length > 0) {
              if (finalData[mainFolder][0].data[key] && finalData[mainFolder][0].data[key].length > 0) {
                finalData[mainFolder][0].data[key].push({data: detailData, op: useOp, key:useOp === 'U' ? idOfDetail : null});
              }
              else {
                finalData[mainFolder][0].data[key] = [{data: detailData, op: useOp, key:useOp === 'U' ? idOfDetail : null}];
              }
            } else {
              finalData[mainFolder] = [
                {
                  op: 'U',
                  key:detailPrimaryKey,
                  data: {
                    id: detailPrimaryKey,
                    [key]: [
                      {
                        data: {
                          ...detailData,
                          id: idOfDetail,
                          [detailPrimaryKeyField]: detailPrimaryKey,
                        },
                        op: useOp,
                        key:useOp === 'U' ? idOfDetail : null
                      },
                    ],
                  },
                },
              ];
            }
          }
        }
      }
    }
  }


  const { genPatch } = generatePatch();
  //preparing  the parameters to pass to patchGetter
  function makeParams() {
    if (txnType === 'delete') {
      dataToDelete
      const params = {
        baseFolderPath: baseFolder,
        params: {
          [baseFolder]:dataToDelete
        },
      };
      console.log('here', params);
      return { params };
    }
    else {
      const params = {
        baseFolderPath: baseFolder,
        params: {
          [baseFolder]: [
            {
              op: txnOp,
              data: finalData,
              key:txnOp == 'U' ? mainFolderId : null //final params data after processing
            },
          ],
        },
      };
      console.log('here', params);
      return { params };
    }

  }

  //generate txn
  function generateTxn() {
    //get the params
    const { params } = makeParams();
    //get the patch by passing the besettings and params to th genPatch
    txnToRun.params = genPatch(bSetting.output.data.records[0], params);
    //send it back
    return txnToRun;
  }
  return { generateTxn };
};

export default makeTxn;
