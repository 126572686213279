<template>
  <div
    v-if="readOnly"
    :data-test-id="dataTestId"
    class="list-view-class"
  >
    {{ value }}
  </div>
  <div
    v-else-if="showField"
  >
    <span
      v-if="!showInTable && !readOnly"
      class="input-group my-2"
    >{{ numberOptions.label[currentLanguage] }}
      <span
        v-if="isMandatory"
        class="ms-2"
        style="color: red"
      >
        *
      </span>
    </span>
    <div
      v-if="!showInTable"
      :class="{ 'd-block': isMissing || !isValid }"
      class="invalid-feedback p-0 text-xs"
    >
      {{ errorText }}
    </div>
    <input
      :id="dataTestId"
      v-model="value"
      :data-test-id="dataTestId"
      :disabled="isReadOnly || isEditNotAllowed"
      :class="{ 'mandatory-field': isMandatory && isMissing,
                'invalid-field':!isValid}"
      class="form-control form-input"
      type="number"
      :placeholder="placeHolder"
      @change="handleChange"
    >
    <v-tooltip
      v-if="showInTable && value!=null"
      activator="parent"
      open-delay="600"
      location="top"
    >
      {{ value }}
    </v-tooltip>
  </div>
</template>

<script>
import {
  ref, computed, toRef
} from 'vue';
import { useI18n } from 'vue-i18n';
import processExpr from '../../composables/processExprs';
import controlUtility from '../../composables/controlUtility';
export default {
  name: 'WfmNumber',
  props: {
    params: {
      type: Object,
      default: null,
    }
  },
  emits: ['input-change', 'invalid-check', 'mandatory-check'],

  setup(props, context) {
    //variables
    const i8vue = useI18n()
    const currentLanguage = i8vue.locale.value
    const value = ref(null);
    const showInTable = props.params?.showInTable ? true : false;
    const readOnly = props.params?.readOnly ? true : false;
    const numberOptions = props.params.fieldOptions
    const numberParams = toRef(props.params.data)
    const propertyName = numberOptions.name;
    const folderName = numberOptions?.path?.split('.')[0]
    const idOfTheFolder = numberParams.value?.[folderName]?.[0]?.id ? numberParams.value?.[folderName]?.[0]?.id : numberParams.value?.id
    const fieldPath = numberOptions?.path
    value.value = numberParams.value?.[propertyName] ?? numberParams.value?.[folderName]?.[0]?.[propertyName];
    const mandatoryStatus = props.params.mandatoryStatus
    const isEditNotAllowed = props.params.isEditNotAllowed
    const missingFields = props.params.missingFields

    const isValid = ref(true)
    const valueEntered = ref(false)
    const currentFolder = ref(props.params.currentFolder)
    const mandatoryFields = props.params.mandatoryFields
    const invalidFields = props.params.invalidFields
    const { invalidCheck, mandatoryCheck } = controlUtility(invalidFields, mandatoryFields)

    const { processReadOnly, processVisibleExpr, processMandatoryExpr, processDefaultValue, processValidExpr} = processExpr(currentFolder.value, numberParams.value, showInTable)

    //computed
    const placeHolder = computed(() => {
      return numberOptions.placeHolder ? numberOptions.placeHolder : numberOptions.label.eng
    })
    const isMissing = computed(() => {
      if (missingFields?.value?.includes(dataTestId.value)) {
        if (valueEntered.value) {
          return false
        }
        else {
          return true

        }
      }
      else {
        return false
      }
    })
    const isVisible = computed(() => {
      const retVal = processVisibleExpr(numberOptions.visible_expr)
      return retVal
    })
    const isReadOnly = computed(() => {
      const retVal = processReadOnly(numberOptions.readonly_expr)
      return retVal
    })
    const isMandatory = computed(() => {
      const retVal = processMandatoryExpr(numberOptions.mandatory_expr) && isVisible.value
      if (!showInTable) {
        mandatoryCheck({
          propertyName: dataTestId.value,
          value: retVal,
        })
      }
      return retVal
    })
    const errorText = computed(() => {
      if (!isValid.value) {
        return 'Invalid Input'
      }
      if (isMissing.value) {
        return 'Mandatory field'
      }
      else {
        return ''
      }
    })
    const defaultValue = computed(() => {
      const retVal = processDefaultValue(numberOptions.default_value)
      return retVal
    });
    const showField = computed(() => {
      if (!mandatoryStatus?.value) {
        return true
      }
      else {
        if (isMandatory.value) {
          return true
        }
        else {
          return false
        }
      }
    })
    const dataTestId = computed(() => {
      if (showInTable || readOnly) {
        const rowIndex = props.params.node.rowIndex
        const tableName = props.params.tableName
        return `${currentFolder.value.name}/${tableName}/${propertyName}/${rowIndex}`
      } else {
        if (currentFolder.value !== null) {
          return `${currentFolder.value.name}/${propertyName}`
        }
        else {
          return `${numberOptions.type}/${propertyName}`
        }
      }
    })

    //methods
    function handleChange() {
      try {
        const newValue = value.value;
        //don't let string thru
        if (typeof newValue !== 'string') {
          if (newValue) {
            valueEntered.value = true
          }
          if (numberOptions.valid_expr !== undefined) {
            isValid.value = processValidExpr(numberOptions.valid_expr, newValue)
          }
          if (isValid.value) {
          // context.emit("invalid-check", { propertyName: propertyName, value: false })
            invalidCheck({ propertyName: propertyName, value: false })

            //if the data is changed in ag grid
            if (showInTable) {
              numberParams.value[propertyName] = newValue
              props.params.cellValueChanged(numberParams.value)
            }
            //else emit event to control
            else {
              context.emit('input-change', { path: fieldPath, value: newValue, folderId: idOfTheFolder, mandatory: numberOptions.mandatory_expr }

              );
            }
          }
          else {
          // context.emit("invalid-check", { propertyName: propertyName, value: true })
            invalidCheck({ propertyName: propertyName, value: true })
          }

        }
        else {
          if (newValue == '') {
            context.emit('input-change', { path: fieldPath, value: null, folderId: idOfTheFolder, mandatory: numberOptions.mandatory_expr })
          }

        }
      } catch (error) {
        throw error
      }


    }
    if (defaultValue.value) {
      getDefaultValue()
    }
    function getDefaultValue() {
      const defaultSelectedValue = defaultValue.value
      value.value = defaultSelectedValue
      handleChange()
    }

    return {
      value,
      handleChange,
      numberOptions,
      showInTable,
      readOnly,
      currentLanguage,
      isMandatory,
      isReadOnly,
      isMissing,
      errorText,
      isValid,
      propertyName,
      showField,
      isEditNotAllowed,
      placeHolder,
      dataTestId
    };
  },
};
</script>

<style scoped>
.input-style {
  width: 100%;
  color: red;
}

.input-group-text {
  background-color: antiquewhite;
}


.form-input {
  min-height: 5px;
  width: 100%;
  background-color: var(--controls-background-color);
  color:var(--controls-font-color);
  border:1px solid var(--controls-border-color);
}

.mandatory-field {
  border: 1px solid rgb(214, 65, 85) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  color: black
}
</style>
