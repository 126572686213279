import WfmUuid from '../common/form-controls/wfm-uuid.vue'
import WfmNsp from '../common/form-controls/wfm-nsp.vue'
import WfmDocpicker from '../common/form-controls/wfm-docpicker.vue'
import WfmFloat from '../common/form-controls/wfm-float.vue'
import WfmTimestamp from '../common/form-controls/wfm-timestamp.vue'
import WfmBoolen from '../common/form-controls/wfm-boolen.vue'
import WfmText from '../common/form-controls/wfm-text.vue'
import WfmShiftRange from '../common/form-controls/wfm-shift-range.vue'
import WfmNumber from '../common/form-controls/wfm-number.vue'
import WfmSourceType from '../common/form-controls/wfm-source-type.vue'
import WfmTsRange from '../common/form-controls/wfm-ts-range.vue'
import WfmLoctext from '../common/form-controls/wfm-loctext.vue'
import WfmDocpFile from '../common/form-controls/wfm-docp-file.vue';
import WfmDetailActions from '../common/form-controls/wfm-detail-actions.vue';
import WfmBaseRowActions from '../common/form-controls/wfm-base-row-actions.vue';
import ProgressBar from '../common/loader/wfm-progress-loader.vue'
import HeaderComponent from '../views/wfm-folders/HeaderComponent.vue'
import i18n from '../common/translation'
import {keys} from '../provideKeys.js'
import {inject } from 'vue'
// import { inject } from "vue"
const gridConfig = () => {
  //get current language , to be set for labels of column fields
  const currentLanguage = i18n.global.locale.value
  //injecting  here to use in agrid cell renderer params for

  const mandatoryStatus = inject(keys.mandatoryFilter, false)
  const isEditNotAllowed = inject(keys.editNotAllowed, false)
  const missingFields = inject(keys.missingFields, [])
  const invalidFields = inject(keys.invalidFields, [])
  const mandatoryFields = inject(keys.mandatoryFields, [])

  //making a list of fields to show for now
  const controlTypes = {
    'uuid': WfmUuid,
    'nsp_payhead_structure': WfmNsp,
    'docpicker':WfmDocpicker,
    'file_upload':WfmDocpFile,
    'float': WfmFloat,
    'timestamp': WfmTimestamp,
    'boolean': WfmBoolen,
    'text': WfmText,
    ' text':WfmText,
    'shift_range': WfmShiftRange,
    'number': WfmNumber,
    'source_type': WfmSourceType,
    'tsrange': WfmTsRange,
    'loctext':WfmLoctext,
    //added interval for tsrange
    'interval':WfmTsRange,
    'int':WfmNumber,
    'numeric':WfmNumber,
    'progress': ProgressBar,
    'base_row_actions':  WfmBaseRowActions,
    'detail_actions': WfmDetailActions
  }

  //columnDef for the readOnly grid
  function columnDefs(fields, tableName, currentFolder, ascSortToggle, toggleSort, hasRows) {

    const finalFields = fields
    // if (fields?.name?.foldername === "reports") {
    //   finalFields = {label:fields.label, last_modified:fields.last_modified}
    // }
    const columnDef = []
    for (const key in finalFields) {
      let type = finalFields[key]?.type
      if (type === 'docpicker' && finalFields[key].file_upload) {
        type = 'file_upload'
      }
      const column = {
        //for now currentLanguage substitutes for null value..for testing
        headerName: finalFields[key]?.label?.[currentLanguage] === 'Deleted' ? '' : (finalFields[key]?.label[currentLanguage]) || finalFields[key].label,
        resizable: true,
        flex:1,
        suppressMovable: true,
        minWidth:type === 'boolean' ? 100 : 150,
        field: finalFields[key]?.name,
        cellRenderer: controlTypes[type],
        cellRendererParams: {
          fieldOptions: fields[key],
          readOnly: true,
          tableName,
          currentFolder:currentFolder,
          mandatoryStatus,
          isEditNotAllowed,
          missingFields,
          mandatoryFields,
          invalidFields,
        },
        autoHeight: true,
        cellDataType: false,
        editable: false,
        cellStyle : { 'text-overflow':'ellipsis', 'white-space':'nowrap', 'overflow': 'hidden', 'padding-left': '10px', 'text-align':'left'}
      }
      const isMultiple = finalFields[key]?.is_multiple ? true : false
      const isFileUpload = finalFields[key]?.file_upload ? true : false
      if (hasRows && !isMultiple && !isFileUpload) {
        column.headerComponent = HeaderComponent;
        column.headerComponentParams = {
          headerName: finalFields[key]?.label?.[currentLanguage] === 'Deleted'
            ? ''
            : (finalFields[key]?.label[currentLanguage] || finalFields[key].label),
          currentFolder,
          fieldInfo: finalFields[key],
          ascSortToggle,
          toggleSort
        };
      }
      if (type === 'file_upload') {
        column['maxWidth'] = 100;
      }
      columnDef.push(column)
    }
    columnDef.push({
      headerName: 'swHandle',
      field: 'swHandle',
      minWidth: 100, // Adjust width as needed
    });
    if (Array.isArray(fields) && fields[0].foldername === 'bulk_upload') {
      columnDef.push({
        headerName: 'Bulk Upload Progress',
        field: 'bulkUploadProgress',
        cellRenderer: controlTypes['progress'],
        minWidth: 350, // Adjust width as needed
      });
    }
    else if (Array.isArray(fields) && fields[0].foldername === 'invoices') {
      columnDef.push({
        headerName: '',
        field: 'baseRowActions',
        cellRenderer: controlTypes['base_row_actions'],
        maxWidth: 180, // Adjust width as needed
      });
    }
    return columnDef
  }
  // function reverseRowIndex(params) {
  //   const totalRowCount = params.api.getDisplayedRowCount()
  //   return totalRowCount - params.node.rowIndex;
  // }
  function detailColumnDef(fields, tableName, currentFolder, cellValueChanged, addNewRow, baseGridParams) {
    // let finalFields=fieldsToShow(fields)
    const detailColDef = []
    //serial no before each row in ag grid instance
    detailColDef.push({
      headerName: '#',
      maxWidth: 50,
      valueGetter: (params) => {
        return  params.node.rowIndex + 1
      },
    })

    for (const key in fields) {
      let horizontalScroll = false
      if (fields[key]?.foldername === 'leave_types') {
        horizontalScroll = true
      }
      let type = fields[key]?.type
      if (type === 'docpicker' && fields[key].file_upload) {
        type = 'file_upload'
      }
      if (fields[key]?.label.eng !== 'Id' && fields[key]?.label.eng !== 'Key') {
        //controls to show in detail grid
        //colDef for each field type.
        detailColDef.push({
          headerName: key === 'deleted' ? '' : (fields[key]?.label[currentLanguage] || currentLanguage),
          resizable: true,
          sortable: true,

          // minWidth: key === "deleted" ? 10 : 100,
          maxWidth: fields[key]?.maxWidth,
          suppressKeyboardEvent:suppressKeyboardEvent,

          field : fields[key]?.name,
          cellEditorParams: {
            showInTable: true,
            editingMode: true
          },
          // suppressKeyboardEvent:suppressKeybo  ardEvent,
          cellRenderer: controlTypes[type],
          cellRendererParams:function(params) {
            return {
              fieldOptions: fields[key],
              showInTable: true,
              tableName:tableName,
              cellValueChanged:cellValueChanged,
              rowNode: params.node,
              currentFolder:currentFolder,
              mandatoryStatus,
              isEditNotAllowed,
              missingFields,
              mandatoryFields,
              invalidFields,
              baseGridParams
            }
          },
          getQuickFilterText: function(params) {
            //for quick search aggrid in detail table.
            //returning params.value with docp name and nsp type specificied
            return params.value?.path?.[0] || params.value?.[0]?.name?.eng  || params.value
          },
          autoHeight: true,
          cellDataType: false,
          flex: horizontalScroll ? null : 1,
        })
      }
    }
    return detailColDef
  }
  function getAllFocusableElementsOf(el) {
    return Array.from(
      el.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    ).filter((focusableEl) => {
      return focusableEl.tabIndex !== -1;
    });
  }
  function getEventPath(event) {
    const path = [];
    let currentTarget = event.target;
    while (currentTarget) {
      path.push(currentTarget);
      currentTarget = currentTarget.parentElement;
    }
    return path;
  }
  const GRID_CELL_CLASSNAME = 'ag-cell';

  function suppressKeyboardEvent({ event }) {
    const { key, shiftKey } = event;
    const path = getEventPath(event);
    const isTabForward = key === 'Tab' && shiftKey === false;
    const isTabBackward = key === 'Tab' && shiftKey === true;
    let suppressEvent = false;
    // Handle cell children tabbing
    if (isTabForward || isTabBackward) {
      const eGridCell = path.find((el) => {
        if (el.classList === undefined) return false;
        return el.classList.contains(GRID_CELL_CLASSNAME);
      });
      if (!eGridCell) {
        return suppressEvent;
      }
      const focusableChildrenElements = getAllFocusableElementsOf(eGridCell);
      const lastCellChildEl = focusableChildrenElements[focusableChildrenElements.length - 1];
      const firstCellChildEl = focusableChildrenElements[0];
      // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
      if (isTabForward && focusableChildrenElements.length > 0) {
        const isLastChildFocused = lastCellChildEl && document.activeElement === lastCellChildEl;
        if (!isLastChildFocused) {
          suppressEvent = true;
        }
      }
      // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
      else if (isTabBackward && focusableChildrenElements.length > 0) {
        const cellHasFocusedChildren =
          eGridCell.contains(document.activeElement) &&
          eGridCell !== document.activeElement;
        // Manually set focus to the last child element if cell doesn't have focused children
        if (!cellHasFocusedChildren) {
          lastCellChildEl.focus();
          // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
          // move to the 2nd last child element
          event.preventDefault();
        }
        const isFirstChildFocused = firstCellChildEl && document.activeElement === firstCellChildEl;
        if (!isFirstChildFocused) {
          suppressEvent = true;
        }
      }
    }
    return suppressEvent;
  }

  return { columnDefs, detailColumnDef }
}

export default gridConfig
