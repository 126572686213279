<template>
  <span
    v-for="(eachRefByGroup, key) in refBys"
    :key="key"
    class="me-3 col"
  >
    <div v-if="eachRefByGroup.length>1">
      <WfmDropDown
        :items="eachRefByGroup"
        :drop-down-name="key"
        custom-container-class="dropdown-container-class"
        custom-menu-class="dropdown-menu-class"
        list-icon="fa-external-link-alt"
        @select-item="selectRefBy"
      />
    </div>
    <div v-else>
      <WfmButton
        :data-test-id="refByDataTestId(eachRefByGroup[0])"
        @click="addRefByTab(eachRefByGroup[0])"
      >
        <div class="ref-label d-flex">
          <div>
            <span
              v-if="eachRefByGroup[0].loading"
              class="spinner-border spinner-border-sm text-primary"
              role="status"
            />
            <v-icon
              v-else
              :fill="`var(--toolbar-color)`"
              name="fa-external-link-alt"
              scale="0.7"
            />
          </div>
          <div class="ms-1">
            {{ key }}
          </div>
        </div>
      </WfmButton>
    </div>
  </span>
</template>

<script>
import WfmButton from '../common/wfm-button.vue'
import getFolders from '../composables/getFolders'
import {computed} from 'vue'
import { useStore } from 'vuex'
import makeFilter from '../composables/makeFilter'
import getProjections from '../composables/getProjections'
import postData from '../composables/postData'
import getFatRow from '../composables/fatRowGenerator'
import calendarUtils from '../composables/calendarUtils';
import WfmDropDown from '../common/wfm-dropdown.vue'
export default {
  name:'WfmRefBy',
  components:{
    WfmButton,
    WfmDropDown
  },
  props: {
    refBys: {
      type: Object,
      required: true,
      default:null
    },
    folder:{
      type:Object,
      default:null
    },
    rowData:{
      type:Object,
      default:null
    }
  },
  emits:['add-refby-tab'],
  setup(props, context) {
    const { getFolderByName} = getFolders();
    const store = useStore()
    // const calendarData = ref()
    const {projectionForFolder} = getProjections()
    const { eventClassNames, events, eventContent} = calendarUtils();
    function selectRefBy(item) {
      addRefByTab(item)
    }
    function refByDataTestId(refs) {
      return `${props.folder.name}/refBy/${refs.label}`
    }
    const bSettings = computed(() => {
      const bSettingString = store.getters['bSettings']
      return bSettingString
    })
    // function convertDataForCalender(rowData) {
    //   const test = dataGenerator(rowData)
    //   return test
    // }
    // const initialDate = computed(() => {
    //   const firstDate = Object.keys(calendarData.value)[0];
    //   const today = new Date(firstDate)
    //   const month = today.getMonth()
    //   const year = today.getFullYear()
    //   const formattedDate = `${year}-${String(month).padStart(2, '0')}-01`;
    //   return formattedDate;

    async function addRefByTab(refBy) {
      refBy.loading = true
      const sTerm = props.rowData.id
      let tabSpecs = {}
      //using the object key to make searchPath
      const basePathArray = refBy?.key?.split('_docp_') || null
      if (basePathArray != null) {
      //in some cases detail path needs to be made from the key we get
        basePathArray[0] = basePathArray?.[0]?.split('_detail_')?.join('.')
      }

      const searchPath = `${basePathArray?.[0]}.docp(${basePathArray?.[1]}).dst`
      //passing the folder instead of folderName
      const folderObjectFromName = (fname) => getFolderByName(fname, bSettings.value);
      const listFolder = folderObjectFromName(refBy.name)

      if (refBy.name === 'attendance') {
        tabSpecs = {
          label:'Calender',
          component:'WfmCalendar',
          params:calendarParams(listFolder, sTerm, searchPath, listFolder),

        }
      }
      else if (refBy.name === 'resetPassword') {
        tabSpecs = refBy
      }
      else {
        //else show list
        tabSpecs = {
          label: refBy.name,
          component: 'FolderList',
          //passing searchPath to params too
          params : {folder: listFolder, searchTerm: sTerm, searchPath:searchPath, readOnly:true}
        }
      }
      tabSpecs.name = `${props.rowData.code || props.rowData.name.eng || props.rowData.name.eng || props.rowData.employees_id?.[0]?.code} ${refBy.label}`
      refBy.loading = false

      context.emit('add-refby-tab', tabSpecs)


    }

    const calendarParams = (folder, sterm, searchPath, listFolder) => {
      const retVal = {
        'readOnly':true,
        'selectable': false,
        'eventClassNames': eventClassNames,
        'folder':folder,
        'eventContent': eventContent,
        'current-task':'create',
        'update-events': async(startDate, endDate) => {
          //returns events data for the current range to the Fullcalendar to show as events
          return await getAttendance(sterm, searchPath, folder, startDate, endDate)
        },
        'initialDate': new Date(),
      }
      return retVal
    }
    async function getAttendance(searchTerm, searchPath, listFolder, startDate, endDate) {
      let retVal = []
      const txnParams = {}
      const fieldsToFilter = []
      //preparing the start and end date to fetch data between
      startDate.setUTCHours(0, 0, 0, 0)
      endDate.setUTCHours(0, 0, 0, 0);
      const utcStartDate = new Date(startDate.toISOString())
      const utcEndDate = new Date(endDate.toISOString())
      utcStartDate.setHours(utcStartDate.getHours() - 5);
      utcStartDate.setMinutes(utcStartDate.getMinutes() - 30);
      utcEndDate.setHours(utcStartDate.getHours() - 5);
      utcEndDate.setMinutes(utcStartDate.getMinutes() - 30);
      const filterValue = [utcStartDate, utcEndDate]

      fieldsToFilter.push({key:'attendance_date', value:filterValue, operator:'between'})
      const combinedFilter = true
      const { generateFilterParams } = makeFilter(
        searchTerm,
        listFolder,
        fieldsToFilter, //specific fields to search on ,if any.(takes an array like [code,designation , etc])
        searchPath,        // if we have searchPath then its possible to generate specific filter params with the path
        combinedFilter
      )
      const filterParmams = generateFilterParams()

      if (searchTerm && searchPath) {
        //add filters and projections
        txnParams.filters = filterParmams
        txnParams.refRows = true
        txnParams.projections = projectionForFolder(listFolder.name)

        //txn params send
        const { listData } = postData()
        const list = await listData(listFolder, txnParams)
        if (list.output.type === 'success') {
          const attendanceList = list.output.data.records[0].attendance
          list.output.data.records = attendanceList
          if (Object.keys(list.output.data.records).length !== 0) {
            const { fatRowGenerator } = getFatRow()
            const listData = fatRowGenerator(list)
            const finalList = listData.filter((each) => {
              if (each['deleted'] !== undefined) {
                if (each.deleted === false) {
                  return each
                }
              } else {
                return each;
              }
            })
            // order the list being displayed by last_modified first
            retVal = finalList.slice().sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified))
          }
        }
      }
      // const processedData = convertDataForCalender(retVal)
      return events(retVal)


    }

    return {
      refByDataTestId,
      addRefByTab,
      selectRefBy
    }

  }
}
</script>

<style scoped>
.ref-button {
  padding: 10px;
}
.ref-label {
   text-transform: capitalize;
   padding:5px;
   background-color: var(--refBy-background);
   color:var(--refBy-color);
   border-radius: 6px;
   font-size:12px;
   padding-left:8px;
   font-weight: 600;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   padding-right:8px;
}
.ref-label :hover{
  color: rgb(127, 169, 113);
}


</style>
