<template>
  <WfmForm
    data-test-id="wfm_form_login_page"
  >
    <template #form-body>
      <div class="content">
        <div class="input-field mt-2">
          <input
            v-model="swHandle"
            type="text"
            autofocus
            name="swhandle"
            placeholder="swhandle"
            data-test-id="swhandle"
            @change="updateSwHandle"
          />
        </div>
        <div class="input-field mt-3">
          <input
            v-model="empCode"
            type="text"
            name="username"
            placeholder="UserName"
            data-test-id="username"
            @input="updateUserName"
          />
        </div>
        <div class="input-field mt-3 d-flex">
          <input
            v-model="password"
            :type="showPassword?'text':'password'"
            name="password"
            class="ms-2"
            placeholder="Password"
            data-test-id="password"
            @input="updatePassWord"
          />
          <div
            v-if="password"
            class="my-auto"
          >
            <v-icon
              class="mt-4 p-1"
              fill="grey"
              :name="showPassword?'bi-eye-slash':'bi-eye'"
              scale="1.6"
              @click="togglePassword()"
            />
          </div>
        </div>
        <div
          v-if="errorMsg"
          class="link text-danger"
          data-test-id="error_message"
        >
          <span>{{ errorMsg }}</span>
        </div>
      </div>
    </template>
  </WfmForm>

  <div class="action">
    <WfmButton
      name="login"
      data-test-id="btn_login"
      @button-click="loginHandle"
    >
      <span v-if="!loading">
        Login
      </span>
      <span v-if="loading">
        <v-icon
          fill="white"
          name="fa-spinner"
          scale="1.3"
          class="mt-1"
          animation="spin"
        />
        logging in..
      </span>
    </WfmButton>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';
import postData from '../../composables/postData';
import { useStore } from 'vuex';
import WfmButton from '../../common/wfm-button.vue';
import WfmForm from '../../common/wfm-form.vue';
import getFolders from '../../composables/getFolders';

export default {
  name: 'LoginPage',
  components: {
    WfmButton,
    WfmForm
  },
  setup() {
    // Variables
    const router = useRouter();
    const store = useStore();
    const swHandle = ref('');
    const empCode = ref('');
    const password = ref('');
    const errorMsg = ref('');
    const loading = ref(false)
    const showPassword = ref(false)
    // Composables
    const { login } = postData();

    // Methods
    function updateSwHandle() {
      store.dispatch('userModule/swHandleSetter', swHandle.value);
    }

    function updateUserName() {
      store.dispatch('userModule/usernameSetter', empCode.value);
    }

    async function loginHandle() {
      try {
        if (swHandle.value && empCode.value && password.value) {
          loading.value = true
          const retval = await login(swHandle.value, empCode.value, password.value);
          const responseOutputType = retval.output.type;
          const responseValue = retval.output.data.records; //need to throw error if responseValue.length == 0
          console.log(responseValue)
          if (responseOutputType == 'success' && responseValue.length > 0) {
            store.commit('sessionIdMutation', responseValue[0]);
            // await store.dispatch('bSettingsSetter');
            const { getDocpList } = getFolders();
            const foldersDocpList = getDocpList();
            store.commit('folderModule/foldersDocpListMutation', foldersDocpList);
            await store.dispatch('userModule/isLoggedInSetter', true);
            if (responseValue[0].first_name != null) {
              const empName = responseValue[0].first_name;
              localStorage.setItem('employeeName', empName);
            }
            let photoFilePath = null;
            if (responseValue[0].filepath !== null) {
              photoFilePath = responseValue?.[0]?.filepath;
              localStorage.setItem('profilePic', photoFilePath);
            }
            toast.success('logged in', {
              position: toast.POSITION.TOP_CENTER,
            });

            loading.value = false
            router.go('/');
            errorMsg.value = '';
          } else if (responseOutputType == 'error') {
            loading.value = false
            errorMsg.value = 'Invalid credentials';
            toast.error('logIn Failed', {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        } else {
          errorMsg.value = `please enter ${swHandle.value ? '' : 'swhandle,'}${empCode.value ? '' : 'username,'}${password.value ? '' : 'password'}`;
        }
      } catch (error) {
        console.log(error);
      }
    }

    function togglePassword() {
      showPassword.value = !showPassword.value
    }

    // Return variables and methods
    return {
      swHandle,
      updateSwHandle,
      empCode,
      updateUserName,
      password,
      loginHandle,
      errorMsg,
      loading,
      showPassword,
      togglePassword
    };
  }
};
</script>

<style scoped>
/* Existing styles */

input {
  width: 20rem;
  height: 50px;
  margin-top: 20px;
  border-radius: 5px;
  text-align: center;
}

label {
  color: green;
}

.content {
  padding: 35px;
  height: 450px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
}

.input-field {
  padding: 12px 10px;
  border-bottom: 1px solid #747474;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  margin-right: 10px;
  margin-left:10px;

}

.input-field input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 10px 1px;
  border: 0;
  outline: none;
  transition: all 0.2s;
}

.input-field input::-webkit-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-moz-placeholder {
  text-transform: uppercase;
}

.input-field input:-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::-ms-input-placeholder {
  text-transform: uppercase;
}

.input-field input::placeholder {
  text-transform: uppercase;
}

.input-field input:focus {
  border-color: #222;
}

.link {
  text-decoration: none;
  color: #747474;
  letter-spacing: 0.2px;
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
}

.action {
  display: flex;
}

.selected {
  color: Red;
}

/* Updated styles for the login button */
.action button {
  width: 100%;
  padding: 18px;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.2px;
  outline: 0;
  transition: all 0.3s;
  background: #004084;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.action button:hover {
  background: #3c4d6d;
}
</style>
