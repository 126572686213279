const agGridModule = {
  namespaced: true,
  state: {
    detailGridApi: [],
    attendanceGridApi:null,
    gridTxns: [],
    gridEdits: [],
    attendanceGridRows: [],
  },
  getters: {
    detailGridApi: function(state) {
      return state.detailGridApi
    },
    attendanceGridApi:function(state) {
      return state.attendanceGridApi
    },
    gridEditsGetter: (state) => {
      return state.gridEdits;
    },
    gridTxnsGetter: (state) => {
      return state.gridTxns;
    },
    attendanceGridRows: function(state) {
      return state.attendanceGridRows;
    },
  },
  mutations: {

    detailGridApiMutation: function(state, value) {
      state.detailGridApi = value;
    },
    attendanceGridApiMutation:function(state, value) {
      state.attendanceGridApi = value
    },
    gridTxnsMutations: (state, value) => {
      state.gridTxns = value;
    },
    gridEditsMutations: (state, value) => {
      state.gridEdits = value;
    },

    attendanceGridRowsMutation: function(state, value) {
      state.attendanceGridRows = value;
    },
  },
  actions: {
    detailGridApiSetter(context, value) {
      if (Object.keys(value).length === 0) {
        context.commit('detailGridApiMutation', value)
      }
      //if value is not empty and is not stored in the array of detailGridApis store it
      else {
        let isContain = false;
        let index = -1;
        for (let i = 0; i < context.state.detailGridApi.length; i++) {
          if (context.state.detailGridApi[i]['tableName'] === value.tableName) {
            isContain = true;
            index = i;
            break;
          } else {
            isContain = false;
          }
        }
        if (isContain) {
          context.state.detailGridApi.splice(index, 1, value);
        } else {
          value = [...context.state.detailGridApi, value]
          context.commit('detailGridApiMutation', value)
        }
      }
    },
    attendanceGridApiSetter(context, value) {
      context.commit('attendanceGridApiMutation', value)
    },

    gridEditsSetter(context, value) {
      const editList = JSON.parse(JSON.stringify(context.state.gridEdits));
      editList.push(value);
      context.commit('gridEditsMutations', editList);
    },

    gridTxnsSetter(context, value) {
      const txnList = JSON.parse(JSON.stringify(context.state.gridTxns));
      txnList.push(value);
      context.commit('gridTxnsMutations', txnList);
    },
    attendanceGridRowsSetter(context, value) {
      context.commit('attendanceGridRowsMutation', value);
    },
  }
}

export default agGridModule;
