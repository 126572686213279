/*
makes filter params to send with txn when searching in list
searchTerm: current keyword to search
currentFolder: the folder detail in which to search
*/
//TODO: decide on type pf filedstoFilter and come up with loops to create filter accordingly
import getFolders from './getFolders'
import store from '../../store/index';
const makeFilter = (searchTerm, currentFolder, fieldsToFilter, pathToSearch, combinedFilter = false) => {
  const bSettings = store.getters['bSettings'];
  const {getFolderByName} = getFolders()
  const personsFolder = getFolderByName('persons', bSettings)
  const folderName = currentFolder?.name
  const fieldsInFolder = currentFolder?.fields
  const personFolderFields = personsFolder?.fields
  const excludedTypes = ['uuid', 'timestamp', 'number', 'detail', 'tsrange', 'boolean', 'dow_type', 'float', 'source_type', 'file', 'numeric', 'shift_range', 'txn_state', 'created_by', 'json']
  let finalFilter = {}
  const orFilterInput = []
  const andFilterInput = []
  let filters = {}
  const searchTermToSend = searchTerm?.split(' ').join('%')
  function checkNumberOfEmployeeDocp(fields) {
    //function to get all fields which are employees docpicker
    const docpFields = []
    for (const key in fields) {
      if (fields[key].type == 'docpicker'  && ![''].includes(key))
      {
        docpFields.push(fields[key])
      }
    }
    return docpFields.filter((each) =>  each.source.folder == 'employees')
  }
  function fieldsToFilterOn() {
    if (fieldsToFilter && fieldsToFilter.length > 0) {
      return fieldsToFilter
    }
    else {
      const retVal = []
      for (var key in fieldsInFolder) {

        if (!excludedTypes.includes(fieldsInFolder[key].type)) {
          retVal.push(key)
        }
      }
      return retVal

    }
  }
  function addToFilter(value, joiningOp) {
    if (joiningOp == 'or') {
      orFilterInput.push(value)
    }
    else if (joiningOp == 'and') {
      andFilterInput.push(value)
    }
  }
  function generateFilterParams() {
    let finalFields = []
    const employeeDocps = checkNumberOfEmployeeDocp(currentFolder?.fields)
    if (combinedFilter) {
      finalFields = fieldsToFilterOn()

    }
    //normal folder filters
    // console.log('path to search', pathToSearch)

    if (pathToSearch && pathToSearch != '') {
      const toAdd = {path:pathToSearch, operator:'=', value:searchTerm}
      const joiningOp = finalFields.length == 0 ? 'or' : 'and'
      addToFilter(toAdd, joiningOp)
    }
    if (finalFields.length != 0 && !pathToSearch) {
      //if fields to filter given
      for (var j = 0; j < finalFields.length; j++) {
        const joiningOp = finalFields[j].joinop || 'or'
        const fieldKey = finalFields[j].key || finalFields[j]
        const propertyName = finalFields[j].propertyName || null
        const fieldValue = finalFields[j].value || '%' + searchTermToSend + '%'
        const operator = finalFields[j].operator || 'iLike'
        //addition to that employee filter to look in persons folder

        if (currentFolder.fields[fieldKey]?.type === 'docpicker') {
          if (!['registration_image', 'registration_device', 'documents', 'txn_state', 'auto_attendance_sync', 'partner_type', 'contract_types', 'logo', 'config', 'tds_expr'].includes(fieldKey)) {
            if (fieldsToFilter?.length > 0) {

              if (fieldsToFilter.some((each) => each.key === fieldKey)) {
                const keyArr = fieldKey.split('_');
                if (keyArr[keyArr.length - 1] !== 'id') {
                  const toAdd = {path:`${folderName}.${fieldKey}.code`, operator:operator, value:fieldValue}
                  addToFilter(toAdd, joiningOp)
                }

              }
            }
            else {
              const keyArr = fieldKey.split('_');
              if (keyArr[keyArr.length - 1] !== 'id') {
                const toAdd = {path:`${folderName}.${fieldKey}.code`, operator:operator, value:fieldValue}
                addToFilter(toAdd, joiningOp)
              }
            }
          }
          if (fieldKey === 'designation' || fieldKey === 'employee_type' || fieldKey === 'departments') {
            if (propertyName !== 'reporting_manager') {
              const toAdd = {path:`${folderName}.${fieldKey}.name`, operator:operator, value:fieldValue}
              addToFilter(toAdd, joiningOp)

            }
          }
        }
        else {
          if (currentFolder.fields[fieldKey]?.type != null) {
            if (!['tds_expr', 'calculation_expr', 'process_order'].includes(fieldKey)) {
              const toAdd = {path:`${folderName}.${fieldKey}`, operator:operator, value:fieldValue}
              addToFilter(toAdd, joiningOp)
            }
          }

        }

      }
      if (employeeDocps.length > 0) {
        for (var i = 0; i < employeeDocps.length; i++) {
          for (const key in personFolderFields) {

            let basePath = null
            //loop thru each employee docp and make basePath
            if (currentFolder.name === 'employees') {
              basePath = 'employees'
            }
            else {
              basePath = `${currentFolder.name}.${employeeDocps[i].name}`
            }

            if (!excludedTypes.includes(personsFolder.fields[key].type) && personsFolder.fields[key].type !== 'docpicker') {
              if (fieldsToFilter?.length > 0) {
                if (fieldsToFilter.some((each) => each.key === key)) {
                  const toAdd = {path:`${basePath}.persons_id.${key}`, operator:'iLike', value:'%' + searchTermToSend + '%'}
                  addToFilter(toAdd, 'or')
                }
              }
              else {
                if (!['identity_identification_mark', 'epf_number', 'gstin', 'esic_number', 'uan', 'birth_place', 'created_by'].includes(key)) {
                  const toAdd = {path:`${basePath}.persons_id.${key}`, operator:'iLike', value:'%' + searchTermToSend + '%'}
                  addToFilter(toAdd, 'or')
                }
              }
            }
          }
        }

        const searchTermSplit = searchTerm?.split(' ')
        if (searchTermSplit && searchTermSplit.length > 1) {
          let basePath = null
          if (currentFolder.name === 'employees') {
            basePath = 'employees'
          }
          else {
            basePath = `${currentFolder.name}.${employeeDocps[i].name}`
          }
          const firstName = {path:`${basePath}.persons_id.first_name`, operator:'iLike', value:'%' + searchTermSplit[0] + '%'}
          addToFilter(firstName, 'or')
          const lastname = {path:`${basePath}.persons_id.last_name`, operator:'iLike', value:'%' + searchTermSplit[1] + '%'}
          addToFilter(lastname, 'or')

        }
      }

    }

    andFilterInput.push({
      filterInput:orFilterInput,
      joinop:'or'
    })
    finalFilter = {
      filterInput:andFilterInput,
      joinop:'and'
    }
    filters = {
      ...finalFilter}
    return filters;
  }
  return { generateFilterParams }
}

export default makeFilter;
