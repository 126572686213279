import store from '../../store';
import { inject, computed } from 'vue';
import {keys} from '../provideKeys.js'

const processExpr = (currentFolder, params, isDetail) => {
  //current folder set
  const folderToUse = currentFolder
  //currentFolderFields will be fields from current folder with values in case of update and without values in case of create
  //if injection not found, using default value of currentFoler.fields
  const currentRowData = computed(() => store.getters['commonModule/currentRowDataGetter'])

  const currentFolderFields = inject(keys.folderFields, currentFolder?.fields)
  //getting bSettings from store
  const bSettings = store.getters['bSettings']

  const env = bSettings.env
  // Create an object to store dynamic variables
  const dynamicVariable = computed(() => {
    if (isDetail) {
      return  {
        [folderToUse?.name]: currentRowData.value
      }
    }
    else {
      if (currentFolderFields?.value) {
        if (Array.isArray(currentFolderFields.value)) {
          return  {
            [folderToUse?.name]: currentFolderFields.value[0]
          }
        }
        else {
          return  {
            [folderToUse?.name]: currentFolderFields.value
          }
        }

      }
      else {
        if (Array.isArray(currentFolderFields)) {
          return  {
            [folderToUse?.name]: currentFolderFields[0]
          }
        }
        else {
          return  {
            [folderToUse?.name]: currentFolderFields
          }
        }

      }
    }


  })


  // Function to process visibility expression
  const processVisibleExpr = (expr, formData) => {
    if (formData != null) {
      const tosend = {[folderToUse.name]:formData}
      return  !!new Function(`with(this){return ${expr}}`).call(tosend);
    }
    else {
      const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;

      if (size > 0) {
        try {

          const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}
          return !!new Function(`with(this){return ${expr}}`).call(tosend);
        } catch (error) {
          return false;
        }
      }

      return false;
    }

  };
  // Function to process mandatory expression
  const processMandatoryExpr = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    if (size > 0) {
      try {
        const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}

        return !!new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return false;
      }
    }

    return false;
  };

  // Function to process read-only expression
  const processReadOnly = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    if (size > 0) {
      try {
        const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], env, params}

        return !!new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return false;
      }
    }

    return false;
  };

  // Function to process read-only expression
  const processValidExpr  = (expr, value) => {
    try {

      const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], value, params}
      return !!new Function(`with(this){return ${expr}}`).call(tosend);
    } catch (error) {
      return false;
    }

  };
  //Function to process defaultExpr
  const processDefaultValue = (expr) => {
    const size = dynamicVariable.value?.[folderToUse?.name] ? Object.keys(dynamicVariable.value[folderToUse?.name]).length : 0;
    const tosend = {[folderToUse?.name]:dynamicVariable.value[folderToUse?.name], env, params}
    if (size > 0) {
      try {
        return new Function(`with(this){return ${expr}}`).call(tosend);
      } catch (error) {
        return expr;
      }
    }

  };
  // Function to process computed expression
  const computedExpr  = (expr) => {
    try {
      const tosend = {[folderToUse.name]:params}
      return  new Function(`with(this){return ${expr}}`).call(tosend);

    } catch (error) {
      return false;
    }

  };
    // Function to process computed expression
  const filterValueExpr  = (expr, params) => {
    try {
      const tosend = {[folderToUse.name]:dynamicVariable.value[folderToUse.name], params, env}
      return  new Function(`with(this){return ${expr}}`).call(tosend);

    } catch (error) {
      return false;
    }

  };
  const formatExpr = (value, expr) => {
    try {
      return  eval(expr)
    } catch (error) {
      return value;
    }
  }
  const valueExpr = (expr) => {
    try {
      return  eval(expr, env)
    } catch (error) {
      console.log('error')
      return expr;
    }
  }
  return { processVisibleExpr, processMandatoryExpr, processReadOnly, processValidExpr, processDefaultValue, formatExpr, computedExpr, filterValueExpr, valueExpr};
};

export default processExpr;
