<template>
  <div class="container">
    <div
      v-for="value in values()"
      :key="value"
      class="main"
    >
      <div class="data">
        {{ value.name }} <span v-if="!rowPinned">({{ value.code }})</span>
      </div>
      <div
        v-if="!rowPinned"
        class="data"
      >
        {{ formatTimeSlot(value.time_slot) }}
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import getConverters from '../composables/getConverters';
const { storedRangeToUserFriendly } = getConverters();

export default {
  props: {
    params: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props) {

    const formatTimeSlot = (timeSlot) => {
      const ret = storedRangeToUserFriendly(timeSlot);
      return `${ret[0]} to ${ret[1]}`;
    }

    const rowPinned = props.params.node.rowPinned == 'bottom';

    const values = () => {
      let ret = [];
      const store = useStore();
      if (rowPinned) {
        const globalBaseData = store.state.dutyChartPinnedBottomRows;
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && globalBaseData[fIndex][props.params.colDef.field] != null) {
          ret = globalBaseData[fIndex][props.params.colDef.field];
        }
      }
      else {
        const globalBaseData = store.state.dutyCharts;
        const fIndex = globalBaseData.findIndex((gd) => gd.id == props.params.data.id);
        if (fIndex > -1 && globalBaseData[fIndex][props.params.colDef.field] != null) {
          ret = globalBaseData[fIndex][props.params.colDef.field];
        }
      }
      return ret;
    }

    return {
      values,
      formatTimeSlot,
      rowPinned
    }
  }
};
</script>

<style scoped>
.data{
  height: 10px
}
</style>
