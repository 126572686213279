<template>
  <div class="pinned-row-container">
    <div
      v-for="val in totalRows.val"
      :key="val"
      style="display: flex;"
    >
      <span v-if="totalRows.left">
        <div class="bg-success rounded">{{ val }}</div>
        <div class="bold-style">Working Min / Punch Count</div>
      </span>
      <span
        v-else
        class="mt-2 bold-style"
      >{{ val }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import attendanceGridUtils from '../../../composables/attendanceGridUtils';
import attendanceGridDataProcessor from '../../../composables/attendanceGridDataProcessor';
import { useStore } from 'vuex';

export default {
  name: 'PinnedRowRenderer',
  setup(props) {
    const store = useStore();
    const { getGrandTotalForRightBottomCorner, convertMinutesToHours, getDisplayStringForHrs } = attendanceGridUtils();
    // const {getFormattedDateField} = attendanceGridDataProcessor();

    const totalRows = computed(() => {
      let retVal = '';
      if (props.params.column.pinned === 'left') {
        const rowCount = props.params.api.getDisplayedRowCount();
        retVal = {'left':true, 'val':['Total Rows ' + rowCount]};
      } else if (props.params.column.pinned === 'right') {
        retVal = {'val':getGrandTotalForRightBottomCorner(store.state.attSummary?.aggregateSummary), 'center':true};
      } else {
        const {getFormattedDateField} = attendanceGridDataProcessor();
        let result = {};
        props.params.api.forEachNode((r, i) => {
          if (r.data !== null && r.data !== undefined && r.data.employees != null && r.data.employees != null) {
            for (let i = 0; i < r.data.employees?.dailyCount?.length; i++) {
              if (getFormattedDateField(r.data.employees.dailyCount[i].attendance_date) === props.params.column.colId) {
                const retVal = {}
                retVal.punch = r.data.employees.dailyCount[i].total_punch_count || '0'
                retVal.workingMinute = convertMinutesToHours(r.data.employees.dailyCount[i].working_minutes);
                result = retVal
              }
            }
          }
          /*if (r.data !== null && r.data !== undefined) {
            if (r.data[props.params.column.colId] !== undefined) {
              const workingHours = convertMinutesToHours(r.data[props.params.column.colId].working_minutes);
              const overtimeHours = convertMinutesToHours(r.data[props.params.column.colId].overtime_minutes);
              result += (workingHours || 0) + (overtimeHours || 0);
            }
          }*/
        });
        retVal = {'val':[`${getDisplayStringForHrs(result.workingMinute)} / ${result.punch || 0}`], 'right':true};
      }
      return retVal;
    });

    return {
      totalRows
    };
  }
}
</script>

<style>
.ag-cell .pinned-row-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  border-right: 0.01px solid #dde2eb;
  flex-wrap: wrap;
}
.pinned-row-container .bg-success{
  background-color: hsla(83, 79.8%, 75.3%, 0.5)!important;
  color:black !important;
}
.ag-cell .pinned-row-container div {
  margin: 5px;
  height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.bold-style{
  font-weight: 600;
  color:black;
}
</style>
