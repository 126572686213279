<template>
  <div
    class="custom-tooltip"
    style="backgroundColor: gray"
  >
    <table class="table table-sm table-striped table-hover list-report">
      <thead class="thead-light print-header">
        <tr>
          <td>Site</td>
          <td>Shift</td>
          <td>Desg</td>
          <td>In Time</td>
          <td>Out Time</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ calcData.site }}</td>
          <td>{{ calcData.shift }}</td>
          <td>{{ calcData.designation }}</td>
          <td>{{ calcData.inTime }}</td>
          <td>{{ calcData.outTime }}</td>
        </tr>
      </tbody>
      <thead
        v-if="calcData.history != null"
        class="thead-light print-header"
      >
        <tr>
          <td colspan="5">
            Details
          </td>
        </tr>
      </thead>
      <thead
        v-if="calcData.history != null"
        class="thead-light print-header"
      >
        <tr class="header">
          <th scope="col">
            Date/Time
          </th>
          <th scope="col">
            Status
          </th>
          <th scope="col">
            WH
          </th>
          <th scope="col">
            OT
          </th>
          <th scope="col">
            By
          </th>
        </tr>
      </thead>
      <tbody
        v-if="calcData.history != null"
      >
        <tr
          v-for="(data, i) in calcData.history"
          :key="i"
        >
          <td>{{ data.dt }}</td>
          <td>{{ data.status }}</td>
          <td>{{ data.wh }}</td>
          <td>{{ data.ot }}</td>
          <td>{{ data.by }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { computed, onBeforeMount } from 'vue';

export default {
  name: 'AttendanceCellDetailsRenderer',
  setup(props) {
    let exception = null;
    const calcData = computed(() => {
      const retval = {};
      const colDate = props.params.colDef.field;
      const attData = props.params.data[colDate];
      retval.shift = (attData?.shifts_id?.[0]?.name == null) ? '--' : attData?.shifts_id?.[0]?.name;
      retval.site = (attData?.sites_id?.[0]?.code == null) ? '--' : attData?.sites_id?.[0]?.code;
      retval.designation = (attData?.designation?.[0]?.name == null) ? '--' : attData?.designation?.[0]?.name;
      retval.inTime = (attData?.in_time == null) ? '--' : new Date(attData.in_time).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})
      retval.outTime = (attData?.out_time == null) ? '--' : new Date(attData.out_time).toLocaleTimeString(undefined, {hour: '2-digit', minute: '2-digit'})

      if (props.params.value.exception != null) {
        retval.history = props.params.value.exception.map((v) => {
          const data = {};
          data.dt = new Date(v.edit_timestamp).toLocaleString(undefined, {year : '2-digit', month: 'short', day : 'numeric', hour: '2-digit', minute: '2-digit'});
          data.status = v.att_type[0].code;
          data.wh = v.working_minutes == null ? null : `${Math.floor(v.working_minutes / 60)}:${v.working_minutes % 60}`
          data.ot = v.overtime_minutes == null ? null : `${Math.floor(v.overtime_minutes / 60)}:${v.overtime_minutes % 60}`
          data.by = v.description;
          return data;
        })
      }
      console.log('Computed :: ', retval)
      return retval;
    })

    onBeforeMount(() => {
      exception = props.params.value;
    });
    return {
      exception,
      calcData
    };
  }
}
</script>
<style>
table {
    border-collapse: collapse;
    font-family: sans-serif;
    font-size: 0.6rem;
    letter-spacing: 1px;
}

.table td, .table th {
  border: 1px solid #dee2e6;
  padding: 0.3rem;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
    margin-bottom: 1rem;
    color: #212529;
    }

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);

}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.list-report{
    padding : 5px 10px;
}
.list-report tr th, .list-report tr td {

    vertical-align: top;
    padding: 7px 6px;
    border-collapse : collapse;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    text-align: center;
}

.list-report tr.header{
    font-weight : bold;
}
</style>
