<template>
  <div class="workflow-view">
    <div class="header-container">
      <span class="header-span">Settings</span>
    </div>
    <div class="folder-container">
      <div class="d-flex justify-space-between h-100">
        <div class="editor-pane">
          <div class="editor-toolbar">
            <WfmButton
              :disabled="diagnostics.length > 0"
              :button-class="diagnostics.length > 0 ? 'btn btn-danger' : 'btn btn-success'"
              @click="setDiagram()"
            >
              Draw Chart
            </WfmButton>
          </div>
          <div class="editor-surface">
            <Codemirror
              v-model="code"
              placeholder="Code goes here..."
              :style="{ height: '400px' }"
              :autofocus="true"
              :indent-with-tab="true"
              :tab-size="2"
              :extensions="extensions"
              @ready="handleReady"
              @change="handleChange"
              @focus="log('focus', $event)"
              @blur="log('blur', $event)"
            />
          </div>
        </div>
        <div class="editor-pane">
          <vue-mermaid-string :value="diagram" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import {Codemirror}  from 'vue-codemirror';
//import { basicSetup } from "codemirror";
import { wfmlang, wfmlangCompletion, wfmlangLinter, wfmlangLintGutter, wfmlangLinterFn } from 'codemirror-lang-wfmlang';
//import { javascript } from '@codemirror/lang-javascript';
import VueMermaidString from 'vue-mermaid-string';
import WfmButton from '../../common/wfm-button.vue';
export default {
  name: 'WorkFlow',
  components: {
    Codemirror,
    VueMermaidString,
    WfmButton
  },
  setup() {
    // Variables
    const i18n = useI18n();
    const currentLanguage = ref(i18n.locale.value);

    const code = ref('')
    const diagnostics = ref([])
    const extensions = [wfmlang(), wfmlangCompletion, wfmlangLinter, wfmlangLintGutter]
    //    const extensions = [javascript()]

    // Codemirror EditorView instance ref
    const view = ref()
    const handleReady = (payload) => {
      view.value = payload.view
    }

    // // Status is available at all times via Codemirror EditorView
    // const getCodemirrorStates = () => {
    //   const state = view.value.state
    //   const ranges = state.selection.ranges
    //   const selected = ranges.reduce((r, range) => r + range.to - range.from, 0)
    //   const cursor = ranges[0].anchor
    //   const length = state.doc.length
    //   const lines = state.doc.lines
    //   // more state info ...
    //   // return ...
    // }


    const diagram = ref('')

    // Methods
    function setDiagram() {
      if (diagnostics.value.length == 0) {
        console.log('setting diagram value to: ', code.value)
        //TODO - find the supported way of working with vue-codemirror on nexttick
        setTimeout(() => { diagram.value = code.value })
      }
    }

    function handleChange(value, viewUpdate) {
      diagnostics.value =  wfmlangLinterFn(viewUpdate.view)
      setDiagram()
    }

    onMounted(() => {
      code.value =
`flowchart TD

  begin --> |create@ReportingOfficers|created
  created --> |upload@HR_Manager|uploaded
  uploaded --> |approve@ApprovingOfficers|approved
  uploaded --> |reject@ApprovingOfficers|rejected
  rejected --> |approve@ApprovingOfficers|approved
`
    });

    return {
      handleReady,
      log: console.log,
      code,
      diagnostics,
      extensions,
      diagram,
      setDiagram,
      handleChange,
      currentLanguage
    };
  },
};

</script>

<style scoped>
.workflow-view {
  display: inline-block;
  height: 95vh;
  align-items: center;
  width: 100%;
}

.header-container {
  height: 4rem;
  padding: 1rem;
  margin-top: 1.2rem;
}

.header-span {
  color: #292929;
  font-size: 1.2rem;
}

.folder-container {
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: calc(11rem * 5 + 8rem); /* Adjust according to your card width */
  margin: 0rem auto;
  height: 30rem;
  margin-top: 1.2rem;
}

.editor-pane {
  width: 100%;
  height: 100%;
}
.editor-toolbar {
  width: 100%;
  text-align: right;
}
.editor-surface {
  width: 100%;
  height: 100%;
  text-align: left;
}

.markup-editor {
  width: 100%;
  height: 100%;
}

</style>
