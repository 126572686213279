<template>
  <div
    class="options settings-menu-item"
    @click="appSettings"
  >
    <v-icon
      name="bi-info-circle"
      scale="1.1"
    />
    <span class="ms-2">Settings</span>
  </div>

  <!-- hold language switch for now -->
  <!-- <div class="options d-flex">
      <v-icon name="bi-translate" :fill="`var(--main-color)`" scale="1.1" />
      <WfmLanguageSwitch class="ms-1" />
    </div> -->
  <div
    class="options"
    data-test-id="logout-menu-item"
    @click="logoutEmp"
  >
    <v-icon
      name="bi-power"
      scale="1.3"
    />
    <span class="ms-2">Log out</span>
  </div>
  <!-- hold theme switch for now -->
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import postData from '../../../composables/postData.js';
import { clearStoreSession } from '../../../services/localstore.js';
import { clearSession } from '../../../services/sessionStore.js'

// import WfmLanguageSwitch from "./wfm-language-switch.vue";

export default {
  name: 'ProfileMenu',
  components: {
    // WfmLanguageSwitch,
  },
  props: {
    actions: {
      type: Object,
      default: null,
    },
  },
  emits:['close-menu'],
  setup(props, { emit }) {
    //variables
    const store = useStore();
    const router = useRouter();
    const { logout } = postData();

    //computed

    const swHandle = computed(() => {
      return store.getters['userModule/swHandle'];
    });

    //Methods

    async function logoutEmp() {
      //logout txn call
      const logOut = await logout(swHandle.value);
      if (logOut.output.type === 'success') {
        clearSession();
        clearStoreSession();
        router.go('/user-auth');
      }
    }
    function profileSetting() {
      // the profileInfo box does not close, why ?
      emit('close-menu')
      router.push({
        path: '/profile',
      });
    }
    function appSettings() {
      // the profileInfo box does not close, why ?

      //now emitting . to call parents state change
      console.log('settings set');
      emit('close-menu')
      router.push({
        path: '/settings',
      });
    }

    return {
      swHandle,
      profileSetting,
      appSettings,
      logoutEmp,
    };
  },
};
</script>

<style scoped>
.options {
  border-bottom: 1px solid var(--main-color);
  border-radius: 10px;
  color: var(--main-color);
  border-left: 0px;
  border-right: 0px;
  padding: 8px;
  width: 100%;
  font-size: 1rem;
  font-weight: 600
}

.options:hover {
  color: #e67e22;
}
</style>
