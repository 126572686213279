<template>
  <!-- Open Modal with required form -->
  <WfmModal @close="closeModal">
    <!-- header section modal -->
    <template #header>
      <div class="d-none d-sm-block my-auto mx-auto">
        <h6 class="text-capitalize modal-header-name">
          Please select month
        </h6>
      </div>
    </template>
    <!-- body section modal -->
    <template #body>
      <div class="recal-form-div">
        <WfmControl
          :field-options="recalFromFields"
          :data="null"
          class="m-3"
        />
      </div>
    </template>
    <!-- footer section modal -->
    <template #footer>
      <div class="footer-content d-flex justify-content-between ">
        <div>
          <WfmButton
            button-class="ms-1 save-btn me-2"
            @button-click="saveHandle"
          >
            Save
          </WfmButton>
        </div>
      </div>
    </template>
  </WfmModal>
</template>

<script>
import { ref, provide} from 'vue';
// import { sendMessage } from '../../services/websocket';
import { toast } from 'vue3-toastify';
import WfmModal from '../../common/wfm-modal.vue';
import WfmButton from '../../common/wfm-button.vue';
// import getFatRow from '../../composables/fatRowGenerator';
import WfmControl from '../../common/form-controls/wfm-control.vue';
import { keys } from '../../provideKeys';
import { sendMessage } from '../../services/websocket';
export default {
  name: 'RecalForm',
  components: {
    WfmModal,
    WfmButton,
    WfmControl
  },
  props: {
    currentTask: {
      type: String,
      default: null,
    },
    txnToUse:{
      type:Object,
      default:null
    },
    agGridApi:{
      type:Object,
      default:null
    }
  },
  emits:['close-form'],
  setup(props, context) {
    // Variables
    const notificationId = ref();
    const listIcon = ref('bi-list');
    const formSaveClicked = ref(false);
    const formData = ref({})

    provide(keys.formData, formData)
    const recalFromFields = {
      type:'timestamp',
      name:'recalMonth',
      label:{eng:'Recal Month'},
      filterType:'month',
      path:null,
      value:null
    }
    // Methods
    function closeModal() {
      try {
        formSaveClicked.value = false;
        listIcon.value = 'bi-list';
        //emit to close
        context.emit('close-form')
      } catch (error) {
        console.log(error);
      }
    }


    async function saveHandle() {
      try {
        formSaveClicked.value = true;
        notificationId.value = toast.loading('Working on it...', {
          position: toast.POSITION.TOP_CENTER,
          className: 'toast-body',
        });
        const selected = props.agGridApi.getSelectedNodes();
        console.log('selected', selected)
        const txnToRun = props.txnToUse
        const recalMonth = formData.value['recalMonth']?.value?.month
        const recalYear = formData.value['recalMonth']?.value?.year
        const contractId = selected?.[0]?.data?.id
        const paramsToSend = {
          id:contractId,
          month:recalMonth,
          year:recalYear
        }
        txnToRun.params = paramsToSend
        const res = await sendMessage(txnToRun)
        if (res.output.type === 'success') {
          context.emit('close-form')
          toast.update(notificationId.value, {
            render: 'Attendance Recal started',
            autoClose: 1000,
            closeButton: true,
            type: 'success',
            isLoading: false,
          });
        }
        else {
          toast.update(notificationId.value, {
            render: res.output.message,
            type: 'error',
            isLoading: false,
            closeButton: true,
            position: toast.POSITION.TOP_CENTER,
          });
          context.emit('close-form')
        }

        console.log(txnToRun)
      } catch (err) {
        throw err;
      }
    }

    return {
      closeModal,
      saveHandle,
      listIcon,
      recalFromFields
    };
  },
};
</script>

  <style>
  .upload-status {
    font-size: 1rem;
  }

  .footer-content {
    width: 100%;
  }

  .modal-header-name {
    color: #2e688dff;
    font-weight: 600;
  }

  .upload-form{
    width: fit-content !important;
  }

  .save-btn {
    border: 2px solid #2e688dff;
    background-color: white;
    color: #2e688dff;
    border-radius: 6px;
    font-size: 18px;
    padding-left: 8px;
    font-weight: 600;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding-right: 8px;

    &:hover {
      background-color: #2e688dff;
      color: white;
    }
  }
  </style>
