<template>
  <div class="total-container">
    <div>
      <span>Total Days</span>
    </div>
    <div
      v-if="allowRowAdd"
      class="add-button"
      @click="addEmptyRow"
    >
      <v-icon
        :fill="`#292929`"
        name="md-groupadd-outlined"
        scale="1.0"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup(props) {
    const addEmptyRow = (event) => {
      props.params.addNewRow();
    };
    const store = useStore();

    const allowRowAdd = computed(() => {
      let retval = true;
      const bSettings = store.getters['bSettings'];
      const env = bSettings.env;
      const selectedSWHandle = store.getters['userModule/selectedPartner'];
      const loggedInSWHandle = env.swhandle

      if (loggedInSWHandle != selectedSWHandle) {
        retval = false;
      }
      return retval;

    })

    return {
      addEmptyRow,
      allowRowAdd
    };
  }
}
</script>

<style>

.total-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  overflow: visible;
}

.add-button {
  cursor: pointer;
  margin-left: 10px;
}

</style>
